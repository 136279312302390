<template>
  <div class="container">
    <div v-if="amAdmin" id="admin">
      <h2>Administrator controls</h2>
      <button class="danger" @click="disableAccount">Disable account</button>
      <button v-if="verified" @click="unverifyAccount">Remove Verfication</button>
      <button v-else @click="verifyAccount">Verify Account ✅</button>
    </div>

    <aside class="user-controls" v-if="myUser !== null && userAuthenticated && myUser.id == address">
      <h1>Welcome, {{ myUser.username }}!</h1>
      <div class="user-controls-buttons">
        <router-link to="/settings" class="button button-round-accent">Settings</router-link>
        <button @click="logout()">Log out</button>
      </div>
    </aside>

    <div class="cols-2">
      <aside class="user-pane">
        <div class="username">
          <h1 class="username" v-if="finishedLoading.username">{{ username }}</h1>
          <h1 class="username" v-else>Loading...</h1>
          <VerifiedIcon :title="`${username} is a verified user!`" :alt="`${username} is a verified user!`" class="verified-user" v-if="finishedLoading.username && verified" />
        </div>

        <div class="wallet">
          <div class="addr-container">
            <p class="details-title">WALLET</p>
            <p class="addr">{{ address }}</p>
          </div>
          <CopyIcon class="copy-icon hoverable" @click="copyAddress" />
        </div>

        <div v-if="amAdmin && walletBalance > 0">
          <p class="details-title">Ubiq balance</p>
          <p class="details-data">{{ walletBalance }} UBQ</p>
        </div>

        <div v-if="amAdmin && wallet10GransBalance > 0">
          <p class="details-title">10grans balance</p>
          <p class="details-data">{{ wallet10GransBalance }} GRANS</p>
        </div>
      </aside>

      <section class="right-half">
        <nav>
          <button :class="{ 'active-tab': activeTab == 'owned' }" @click="activeTab = 'owned'">Owned</button>
          <button :class="{ 'active-tab': activeTab == 'for-sale' }" @click="activeTab = 'for-sale'">For Sale</button>
          <button :class="{ 'active-tab': activeTab == 'created' }" @click="activeTab = 'created'">Created</button>
          <!--<button :class="{ 'active-tab': activeTab == 'favorites' }" @click="activeTab = 'favorites'">Favorites</button>-->
          <button :class="{ 'active-tab': activeTab == 'collections' }" @click="activeTab = 'collections'">Collections</button>
        </nav>

        <article class="tab" :class="{ hidden: activeTab !== 'owned' }">
          <div class="sad-message" v-if="!finishedLoading.ownedNfts">
            <h2>Loading...</h2>
          </div>
          <div class="card-grid" v-else-if="this.ownedNfts && this.ownedNfts.length > 0">
            <NftCard v-for="(nft, index) of ownedNfts" :key="index" :nft="nft" />
          </div>
          <div class="sad-message" v-else>
            <h2>{{ username }} doesn't own any NFTs.</h2>
          </div>

          <div class="paging">
            <button class="button-square-accent" @click="currentPage--" :class="{ disabled: currentPage <= 1 }">← Prev Page</button>
            <span class="current-page">{{ currentPage }}</span>
            <button class="button-square-accent" @click="currentPage++" :class="{ disabled: !nextPageAvailable['owned'] }">Next Page →</button>
          </div>
        </article>

        <article class="tab" :class="{ hidden: activeTab !== 'for-sale' }">
          <div class="sad-message" v-if="!finishedLoading.ownedNfts">
            <h2>Loading...</h2>
          </div>
          <div class="card-grid" v-else-if="this.forSaleNfts && this.forSaleNfts.length > 0">
            <NftCard v-for="(nft, index) of forSaleNfts" :key="index" :nft="nft" />
          </div>
          <div class="sad-message" v-else>
            <h2>{{ username }} has no NFTs for sale.</h2>
          </div>

          <div class="paging">
            <button class="button-square-accent" @click="currentPage--" :class="{ disabled: currentPage <= 1 }">← Prev Page</button>
            <span class="current-page">{{ currentPage }}</span>
            <button class="button-square-accent" @click="currentPage++" :class="{ disabled: !nextPageAvailable['for-sale'] }">Next Page →</button>
          </div>
        </article>

        <article class="tab" :class="{ hidden: activeTab !== 'created' }">
          <div class="sad-message" v-if="!finishedLoading.createdNfts || !finishedLoading.collections">
            <h2>Loading...</h2>
          </div>
          <div class="card-grid" v-else-if="this.createdNfts && this.createdNfts.length > 0">
            <NftCard v-for="(nft, index) of createdNfts" :key="index" :nft="nft" />
          </div>
          <div class="sad-message" v-else>
            <h2>{{ username }} has not created any NFTs.</h2>
          </div>

          <div class="paging">
            <button class="button-square-accent" @click="currentPage--" :class="{ disabled: currentPage <= 1 }">← Prev Page</button>
            <span class="current-page">{{ currentPage }}</span>
            <button class="button-square-accent" @click="currentPage++" :class="{ disabled: !nextPageAvailable['created'] }">Next Page →</button>
          </div>
        </article>

        <!--
        <article class="tab" :class="{ hidden: activeTab !== 'favorites' }">
          <div class="sad-message" v-if="!finishedLoading.favoriteNfts">
            <h2>Loading...</h2>
          </div>
          <div class="card-grid" v-else-if="this.favoriteNfts && this.favoriteNfts.length > 0">
            <NftCard v-for="(nft, index) of favoriteNfts" :key="index" :nft="nft" />
          </div>
          <div class="sad-message" v-else>
            <h2>{{ username }} has not favorited any NFTs.</h2>
          </div>
        </article>
-->

        <article class="tab" :class="{ hidden: activeTab !== 'collections' }">
          <div class="sad-message" v-if="!finishedLoading.createdNfts || !finishedLoading.collections">
            <h2>Loading...</h2>
          </div>
          <div class="card-grid" v-else-if="collections.length > 0">
            <div v-for="c in collections" :key="c.address" class="collection">
              <CollectionCard :symbol="c.symbol" :name="c.name" :address="c.address" clickAction="link" />
            </div>
          </div>
          <div class="sad-message" v-else>
            <h2>{{ username }} does not have any collections.</h2>
          </div>
        </article>
      </section>
    </div>
  </div>
</template>

<style scoped>
aside.user-controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  width: 600px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--distance-from-nav);

  padding: 32px;
  border-radius: 16px;
  box-shadow: 0 0 0 1pt var(--white);
  background-color: var(--card-background);
}

aside.user-controls .user-controls-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 32px;
}

aside.user-pane .user-balance {
  color: var(--offwhite);
  margin-bottom: 8px;
}

aside.user-pane .wallet {
  display: flex;
  align-items: center;

  background-color: var(--card-background);
  color: var(--card-foreground);
  margin: 24px 0;
  padding: 16px 24px;
  border-radius: 4px;
}

aside.user-pane .wallet .details-title {
  color: var(--card-foreground);
  margin: 0;
}

aside.user-pane .wallet .addr-container {
  display: flex;
  flex-direction: column;
  max-width: 80%;
}

aside.user-pane .wallet .addr {
  font-weight: 600;
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

aside.user-pane .wallet .addr {
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

aside.user-pane .wallet .copy-icon {
  padding: 8px 20px;
  min-width: 24px;
  cursor: pointer;
}

aside.user-pane .username {
  display: flex;
  align-items: baseline;
}

aside.user-pane .username .verified-user {
  max-height: 24px;
  margin-left: 8px;
  min-width: 24px;
}

#admin {
  background-color: #500000;
  border: 1px solid white;
  padding: 1em;
  margin-bottom: 2em;
}

.sad-message {
  text-align: center;
}

.right-half nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  border: none;
  margin-bottom: 0;
}

.right-half nav button {
  background-color: var(--gray);
  color: var(--white);
  font-weight: 400;
}

.right-half nav button.active-tab {
  background-color: var(--white);
  color: var(--black);
}

.right-half .tab {
  padding-top: 28px;
  padding-bottom: 40px;
}

.right-half .tab.hidden {
  display: none !important;
}

.paging {
  margin-top: 64px;
  text-align: center;
}

.paging p {
  display: inline;
  font-weight: bold;
  margin: 0 16px;
}

.danger {
  background: black;
  color: red;
  border: 2px solid white;
}

.danger:hover {
  background: red;
  color: black;
}

.paging {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 64px;
  text-align: center;
}

.current-page {
  margin: 0 32px;
  font-weight: 700;
  font-size: 38px;
}
</style>

<script>
import shared from "@/shared";
import NftCard from "@/components/NftCard.vue";
import CollectionCard from "@/components/CollectionCard.vue";
import VerifiedIcon from "@/assets/images/verified.svg";
import CopyIcon from "@/assets/images/copy.svg";

export default {
  components: {
    NftCard,
    CollectionCard,
    VerifiedIcon,
    CopyIcon,
  },
  data() {
    return {
      verified: false,
      address: "",
      ethPrice: 0,
      tokenPrice: 0,
      nextPageAvailable: {
        owned: false,
        "for-sale": false,
        created: false,
      },
      finishedLoading: {
        username: false,
        ownedNfts: false,
        createdNfts: true, // assume true... we first load collections, then IF there are collections, we load createdNfts
        collections: false,
        favoriteNfts: false,
      },
    };
  },
  metaInfo() {
    if (this.username == "Unregistered user" && this.address) {
      return {
        meta: [{ description: `nft collector – ${this.address}` }],
      };
    } else if (this.username) {
      return {
        meta: [{ description: `nft collector – ${this.username}` }],
      };
    }

    return {};
  },
  async created() {
    shared.getPrice(this.$apiBase).then((price) => {
      this.ethPrice = price.ubiqUsdRatio;
      this.tokenPrice = price.ubiqUsdRatio / price.ubiqGransRatio;
    });
  },
  computed: {
    myUser() {
      return this.$store.state.user;
    },
    userAuthenticated() {
      return this.$store.getters.isUserAuthenticated;
    },
    amAdmin() {
      return this.myUser !== null && this.$store.getters.isUserAuthenticated && this.myUser.admin;
    },
    activeTab: {
      get() {
        return this.$route.params.tab || "owned";
      },
      set(val) {
        if (!this.address) return;
        this.$router.push(`/address/${this.$route.params.address}/tab/${val}`);
      },
    },
    currentPage: {
      get() {
        if (this.$route.params.page) {
          return Number(this.$route.params.page);
        } else {
          return 1;
        }
      },
      set(val) {
        if (!this.address) return;

        if (val > 1) {
          this.$router.push(`/address/${this.$route.params.address}/tab/${this.activeTab}/page/${val}`);
        } else {
          this.$router.push(`/address/${this.$route.params.address}/tab/${this.activeTab}`);
        }
      },
    },
    page: {
      get() {
        return {
          owned: this.activeTab == "owned" ? this.currentPage : 1,
          "for-sale": this.activeTab == "for-sale" ? this.currentPage : 1,
          created: this.activeTab == "created" ? this.currentPage : 1,
        };
      },
    },
  },
  asyncComputed: {
    ownedNfts: {
      async get() {
        if (this.address) {
          const adultClause = this.$store.state.showAdult ? "adult=true" : "";
          const ownerClause = `owner=${this.address}`;
          const pageClause = `page=${this.page["owned"]}`;

          let res = [];
          try {
            this.finishedLoading.ownedNfts = false;
            res = await shared.fetchJson(`${this.$apiBase}/api/nfts/latest?${adultClause}&${ownerClause}&${pageClause}&showunverified=true`);
          } finally {
            this.finishedLoading.ownedNfts = true;
          }

          // asynchronously fire off a lookahead query to see if there is another page available
          (async () => {
            const nextPageClause = `page=${this.page["owned"] + 1}`;
            const next = await shared.fetchJson(`${this.$apiBase}/api/nfts/latest?${adultClause}&${ownerClause}&${nextPageClause}&showunverified=true`);
            this.nextPageAvailable["owned"] = next.length > 0;
          })();

          return res;
        }
        return [];
      },
      default: [],
    },
    forSaleNfts: {
      async get() {
        if (this.address) {
          const adultClause = this.$store.state.showAdult ? "adult=true" : "";
          const ownerClause = `owner=${this.address}`;
          const latestCurrency = "any"; // TODO provide grans/ubq currency filtering similar to front page
          const forSaleClause = `forsale=${latestCurrency}`;
          const pageClause = `page=${this.page["for-sale"]}`;

          let res = [];
          try {
            this.finishedLoading.ownedNfts = false;
            res = await shared.fetchJson(`${this.$apiBase}/api/nfts/latest?${adultClause}&${ownerClause}&${forSaleClause}&${pageClause}&showunverified=true`);
          } finally {
            this.finishedLoading.ownedNfts = true;
          }

          // asynchronously fire off a lookahead query to see if there is another page available
          (async () => {
            const nextPageClause = `page=${this.page["for-sale"] + 1}`;
            const next = await shared.fetchJson(`${this.$apiBase}/api/nfts/latest?${adultClause}&${ownerClause}&${forSaleClause}&${nextPageClause}&showunverified=true`);
            this.nextPageAvailable["for-sale"] = next.length > 0;
          })();

          return res;
        }
        return [];
      },
      default: [],
    },
    /*
    favoriteNfts: {
      // TODO BROKEN NEEDS TESTING
      async get() {
        if (!this.address) return [];
        const adultClause = this.$store.state.showAdult ? "adult=true" : "";
        const res = await shared.fetchJson(`${this.$apiBase}/api/account/${this.address}/favorites?${adultClause}`);

        return res.nfts.reverse(); // most recently favorited first
      },
      default: [],
    },
    */
    createdNfts: {
      async get() {
        if (this.collections.length > 0) {
          const adultClause = this.$store.state.showAdult ? "adult=true" : "";

          const storeAddresses = this.collections.map((e) => e.address);
          const storeClause = "store=" + storeAddresses.reduce((acc, curr) => `${acc}&store=${curr}`);
          const pageClause = `page=${this.page["created"]}`;

          let res = [];
          try {
            this.finishedLoading.createdNfts = false;
            res = await shared.fetchJson(`${this.$apiBase}/api/nfts/latest?${adultClause}&${storeClause}&${pageClause}&showunverified=true`);
          } finally {
            this.finishedLoading.createdNfts = true;
          }

          // asynchronously fire off a lookahead query to see if there is another page available
          (async () => {
            const nextPageClause = `page=${this.page["created"] + 1}`;
            const next = await shared.fetchJson(`${this.$apiBase}/api/nfts/latest?${adultClause}&${storeClause}&${nextPageClause}&showunverified=true`);
            this.nextPageAvailable["created"] = next.length > 0;
          })();

          return res;
        }
        return [];
      },
      default: [],
    },
    collections: {
      async get() {
        if (!this.address) return [];

        try {
          this.finishedLoading.collections = false;
          return await shared.fetchJson(`${this.$apiBase}/api/stores/${this.address}`);
        } finally {
          this.finishedLoading.collections = true;
        }
      },
      default: [],
    },
    username: {
      // also sets this.address
      async get() {
        try {
          const accountUser = await shared.fetchJson(`${this.$apiBase}/api/user/${this.$route.params.address}`);
          if (accountUser) {
            this.address = accountUser.id;
            this.verified = accountUser.verified;
            document.title = accountUser.username;
            return accountUser.username;
          } else {
            return "Unregistered user";
          }
        } finally {
          this.finishedLoading.username = true;
        }
      },
      default: "User",
    },
    walletBalance: {
      async get() {
        if (!this.address) return 0;

        if (this.$root.web3) {
          const balance = await this.$root.web3.eth.getBalance(this.address);
          return shared.formatPrice(balance);
        }
        return null;
      },
      default: 0,
    },
    wallet10GransBalance: {
      async get() {
        if (!this.address) return 0;

        if (this.$root.web3) {
          const tokenAbi = await this.$tokenAbiPromise;
          this.tokenContract = new this.$root.web3.eth.Contract(tokenAbi, this.$tokenAddress);
          const tenGransWei = await this.tokenContract.methods.balanceOf(this.address).call();
          return shared.formatPrice(tenGransWei);
        }
        return null;
      },
      default: 0,
    },
  },
  methods: {
    copyAddress() {
      navigator.clipboard.writeText(this.address);
    },
    logout() {
      this.$parent.logout();
    },
    async disableAccount() {
      let confirmation = confirm("This will disable the user's account, preventing them logging in and making API calls. Their NFTs will still exist and be visible.\n\nProceed?");
      if (!confirmation) return;

      confirmation = confirm("Are you really sure you want to DISABLE THIS USER?");
      if (!confirmation) return;

      try {
        const url = `${this.$apiBase}/api/admin/account/${this.address}`;
        const form = new FormData();
        form.append("disabled", true);
        await shared.uploadForm(url, form, "POST", "disabled=true", this.myUser.id, this.myUser.token, this.$root.web3.utils);

        alert("Successfully disabled user");
      } catch (error) {
        alert(`disableAccount(); received error while disabling user: ${error.message}`);
        throw Error(`disableAccount(); received error while disabling user: ${error.message}`);
      }
    },
    async verifyAccount() {
      let confirmation = confirm(`Are you sure you want to verify user '${this.username}' at address '${this.address}'?`);
      if (!confirmation) return;

      try {
        const url = `${this.$apiBase}/api/admin/account/${this.address}/verified`;
        const form = new FormData();
        form.append("verified", true);
        await shared.uploadForm(url, form, "POST", "", this.myUser.id, this.myUser.token, this.$root.web3.utils, false);

        alert("Successfully verified user");
      } catch (error) {
        alert(`verifyAccount(); received error while verifying user: ${error.message}`);
        throw Error(`verifyAccount(); received error while verifying user: ${error.message}`);
      }
    },
    async unverifyAccount() {
      let confirmation = confirm(`Are you sure you want to REMOVE verification from ${this.username}?`);
      if (!confirmation) return;

      try {
        const url = `${this.$apiBase}/api/admin/account/${this.address}/verified`;
        const form = new FormData();
        form.append("verified", false);
        await shared.uploadForm(url, form, "POST", "", this.myUser.id, this.myUser.token, this.$root.web3.utils, false);

        alert("Successfully un-verified user");
      } catch (error) {
        alert(`verifyAccount(); received error while un-verifying user: ${error.message}`);
        throw Error(`verifyAccount(); received error while un-verifying user: ${error.message}`);
      }
    },
  },
};
</script>
