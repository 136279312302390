<template>
  <div class="container">
    <div v-if="error" class="error">{{ error }}</div>

    <div class="settings-container" v-if="hasUser && userAuthenticated">
      <h1>Welcome, {{ user.username }}!</h1>

      <div class="youruser">
        View your public user profile:
        <router-link class="profile-link" :to="{ name: 'Address', params: { address: user.username } }">token.gallery/address/{{ user.username }}</router-link>
      </div>

      <div class="settings">
        <h2>Settings</h2>
        <ul>
          <li>Show adult NFTs? <input type="checkbox" v-model="adult" /></li>
          <p id="updated-notification" class="hidden">Settings updated.</p>
        </ul>
      </div>

      <button @click="logout()">Log out</button>
      <router-link to="/" class="button button-round-accent">Back to Homepage</router-link>
    </div>
    <div v-else class="unauthenticated-user">
      <p>You are not logged in. Click 'Connect Wallet' to log in.</p>
      <div class="back-to-home">
        <a href="#" @click="$emit('connect-wallet')" class="button button-round-outline">Connect Wallet</a>
        <router-link to="/" class="button button-round-accent">Back to Homepage</router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container {
  display: flex;
}

.settings-container {
  margin: 0 auto;
}

h1 {
  margin-bottom: 32px;
}

.error {
  margin-bottom: 1em;
}

.metamask-checklist {
}
.metamask-checklist > tr > td {
  padding-right: 5px;
}

.stores {
  color: white;
}

table.stores td,
table.stores th {
  color: white;
  border: 1px solid white;
  padding: 5px;
}

.store-blurb {
  font-style: italic;
  font-size: smaller;
}

#username {
  text-transform: lowercase;
}

.youruser,
.settings,
.balances {
  background-color: var(--dark);
  padding: 1em;
  border-radius: 16px;
  margin-bottom: 1em;
}

#updated-notification {
  font-style: italic;
  margin-left: 1em;
  font-weight: bold;
  color: var(--red);
}

.back-to-home {
  display: flex;
  justify-content: center;
}

.profile-link {
  text-decoration: underline;
}

.unauthenticated-user {
  margin: 0 auto;
}
</style>

<script>
export default {
  data: function() {
    return {
      error: "",
      walletBalance: "0",
      storedBalance: "0",
      wallet10GransBalance: "0",
      email: null,
      emailVerified: false,
      emailError: null,
      nfts: [],
      contracts: {},
      stores: [],
      requestedUsername: "",
      noMetaMask: true,
    };
  },
  components: {},
  computed: {
    user() {
      return this.$store.state.user;
    },
    userId() {
      return this.$store.state.user.id;
    },
    hasUser() {
      return this.$store.state.user != null;
    },
    userAuthenticated() {
      return this.$store.getters.isUserAuthenticated;
    },
    wrongChain() {
      return window.ethereum && window.ethereum.chainId !== "0x" + process.env.VUE_APP_CHAIN_ID;
    },
    adult: {
      get() {
        return this.$store.state.showAdult;
      },
      set(value) {
        this.$store.commit("updateShowAdult", value);
        this.showUpdateMessage();
      },
    },
    hasMetaMask() {
      return typeof window.ethereum !== "undefined";
    },
  },
  methods: {
    showUpdateMessage() {
      document.getElementById("updated-notification").classList.add("is-active");
      setTimeout(() => {
        document.getElementById("updated-notification").classList.remove("is-active");
      }, 3000);
    },
    logout() {
      this.$parent.logout();
    },
  },
};
</script>
