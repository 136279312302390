import Vue from "vue";
import VueMeta from "vue-meta";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import shared from "./shared";
import AsyncComputed from "vue-async-computed";
import "./../node_modules/basiclightbox/dist/basicLightbox.min.css";
import "./assets/reset.css";
import "./assets/global.css";
import "./assets/minimodal.css";

import VueGtag from "vue-gtag";

import localForage from "localforage";
import VueLocalForage from "vue-localforage";
Vue.use(VueLocalForage);
Vue.prototype.$storageConfig({
  driver: localForage.INDEXEDDB,
  name: "nft-store-web",
});

Vue.use(AsyncComputed);
Vue.use(VueMeta);

Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GTAG_ID,
    params: {
      send_page_view: true,
    },
  },
});

export const bus = new Vue();

Vue.config.productionTip = false;

Vue.prototype.$appLoaded = false;

Vue.prototype.$factoryAddress = process.env.VUE_APP_FACTORY;
Vue.prototype.$tokenAddress = process.env.VUE_APP_TOKEN;
Vue.prototype.$tokenDecimals = 18;
Vue.prototype.$apiBase = process.env.VUE_APP_API_BASE;
Vue.prototype.$ipfsPrefix = process.env.VUE_APP_IPFS_PREFIX;

// load contract ABIs to memory
Vue.prototype.$storeAbiPromise = shared.fetchJson("/store.abi.json");
Vue.prototype.$factoryAbiPromise = shared.fetchJson("/factory.abi.json");
Vue.prototype.$tokenAbiPromise = shared.fetchJson("/token.abi.json");

Vue.prototype.$userSetupLoaded = false;

window.vm = new Vue({
  router,
  store,
  data: {
    web3: null,
  },
  render: (h) => h(App),
}).$mount("#app");
