<template>
  <div class="container">
    <CreateNftHeader h1="Nice, let’s mint this thing." h2="Double check that all of the information is correct — then click the mint button." done="true" />

    <div class="confirm-container">
      <aside class="preview">
        <h2 class="subheading">Preview</h2>
        <div class="nft-card-wrapper" @click="showImage">
          <NftCard :nft="nft" :creator-name-override="username" :favorites-override="'1'" :quantity-override="count" />
        </div>
      </aside>

      <section class="details">
        <h2 class="subheading">Your NFT's info</h2>

        <h3>Title</h3>
        <p class="title" v-if="nft.metadata.name">{{ nft.metadata.name }}</p>
        <p class="title none" v-else>no title</p>

        <h3>Description</h3>
        <p class="description" v-if="nft.metadata.description">{{ nft.metadata.description }}</p>
        <p class="description none" v-else>no description</p>

        <h3>Tags</h3>
        <p v-if="nft.metadata.tags">{{ tags }}</p>
        <p v-else class="none">no tags</p>

        <h3>Optional data</h3>
        <div v-if="Object.keys(properties).length >= 1">
          <p v-for="prop in properties" :key="prop.name">{{ prop.name }}: {{ prop.value }}</p>
        </div>
        <p v-else class="none">no optional properties set</p>

        <h3>Currency</h3>
        <p v-if="currency">{{ currency }}</p>
        <p v-else class="none">n/a</p>

        <h3>Price</h3>
        <p v-if="priceFormatted">{{ priceFormatted }}</p>
        <p v-else class="none">not for sale</p>

        <h3>Number of Copies</h3>
        <p>{{ count || 1 }}</p>

        <h3>Collection</h3>
        <p>{{ collection.name }} ({{ collection.symbol }})</p>
      </section>

      <section class="confirm">
        <h2 class="subheading">Ready to mint?</h2>

        <div class="confirm-box">
          <h3>Everything looks good? Let’s go.</h3>
          <router-link to="/createnft/mint" class="button button-round-accent" id="mint" @click="mint">Mint NFT</router-link>
        </div>
      </section>
    </div>
  </div>
</template>

<style scoped>
.confirm-container {
  display: flex;
  column-gap: 24px;
}

.confirm-container > * {
  flex-basis: 0;
}

.preview {
  flex-grow: 4;
}

.details {
  flex-grow: 5;
}

.confirm {
  flex-grow: 3;
}

.nft-card-wrapper {
  cursor: pointer;
}

.nft-card {
  pointer-events: none;
}

.details h3 {
  font-size: 16px;
  line-height: 16px;
  font-weight: normal;
  text-transform: uppercase;
  margin-top: 32px;
  margin-bottom: 8px;
  color: var(--gray-label);
}

.details p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 8px;
  color: var(--card-foreground);
}

.details p.none {
  font-style: italic;
  color: var(--offwhite);
}

.details p.description {
  white-space: pre-wrap;
}

.confirm-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-height: 238px;
  background-color: var(--card-background);
  padding: 32px;
}

.confirm-box h3 {
  font-size: 32px;
  line-height: 38px;
  font-weight: bold;
}

.confirm-box button {
  width: max-content;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  padding: 16px 40px;
}
</style>

<script>
import shared from "@/shared";
import * as basicLightbox from "basiclightbox";
import CreateNftHeader from "@/components/CreateNftHeader.vue";
import NftCard from "@/components/NftCard.vue";

export default {
  components: {
    CreateNftHeader,
    NftCard,
  },
  created() {
    // Perform some cleanup.

    // remove empty properties
    let invalidProps = [];
    for (const idx in this.properties) {
      if (this.properties[idx].name == "" || this.properties[idx].value == "") {
        invalidProps.push(idx);
      }
    }
    for (const i in invalidProps) {
      this.properties.splice(invalidProps[i]);
    }

    // trim strings
    this.name = this.name.trim();
    this.description = this.description.trim();

    for (const idx in this.properties) {
      this.properties[idx].name = this.properties[idx].name.trim();
      this.properties[idx].value = this.properties[idx].value.trim();
    }
  },
  computed: {
    form() {
      return this.$store.state.simpleSetForms[0];
    },
    nft() {
      return {
        ethForSale: this.form.prices.ethForSale,
        ethPrice: this.form.prices.ethPrice * 1e18,
        tokenForSale: this.form.prices.tokenForSale,
        tokenPrice: this.form.prices.tokenPrice * 1e18,
        metadata: {
          name: this.form.name,
          description: this.form.description,
          image: this.form.blobUrl,
          tags: this.form.tags,
        },
      };
    },
    username() {
      if (this.$store.state.user) return this.$store.state.user.username;
      return "";
    },
    name: {
      get() {
        return this.form.name;
      },
      set(value) {
        this.$store.commit("updateSimpleSetFormField", { index: 0, field: "name", value });
      },
    },
    description: {
      get() {
        return this.form.description;
      },
      set(value) {
        this.$store.commit("updateSimpleSetFormField", { index: 0, field: "description", value });
      },
    },
    tags() {
      let tags = "";
      for (let i = 0; i < this.form.tags.length; i++) {
        tags += this.form.tags[i];
        if (i != this.form.tags.length - 1) tags += ", ";
      }

      return tags;
    },
    properties: {
      get() {
        return this.$store.state.simpleSetForms[0].extra;
      },
      set(value) {
        this.$store.commit("updateSimpleSetFormField", { index: 0, field: "extra", value });
      },
    },
    currency() {
      if (this.nft.ethForSale) {
        return "Ubiq";
      } else if (this.nft.tokenForSale) {
        return "10grans";
      } else {
        return null;
      }
    },
    priceFormatted() {
      if (this.nft.ethForSale) {
        const formattedPrice = shared.formatPrice(this.nft.ethPrice);
        return formattedPrice + " UBQ";
      } else if (this.nft.tokenForSale) {
        const formattedPrice = shared.formatPrice(this.nft.tokenPrice);
        return formattedPrice + " GRANS";
      } else {
        return null;
      }
    },
    count() {
      return this.form.count;
    },
    collection() {
      return this.form.collection;
    },
  },
  methods: {
    mint() {
      console.log("Performing NFT mint");
    },
    showImage() {
      this.lightbox = basicLightbox.create(`<img id="full-image" src="${this.form.blobUrl}"/>`);
      this.lightbox.show();
    },
  },
};
</script>
