<template>
  <div class="create-nft-header">
    <header>
      <div class="header-left">
        <h1>{{ this.h1 }}</h1>
        <h2>{{ this.h2 }}</h2>
      </div>
      <div class="header-right">
        <div class="header-right-inner">
          <h4 v-if="this.done !== 'true'">Step {{ this.stepCurrent }} of {{ this.stepMax }}</h4>
          <h4 v-else>Ready to mint!</h4>

          <div class="progress-bar">
            <div class="progress" :style="progressBarWidth" />
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<style scoped>
header {
  display: grid;
  grid-template-columns: 75% 25%;
  margin-bottom: 72px;
}

header h2 {
  font-size: 24px;
  line-height: 34px;
  font-weight: normal;
  color: var(--offwhite);
}

h4 {
  font-size: 20px;
  line-height: 30px;
  font-weight: normal;
  white-space: nowrap;
  margin-right: 16px;
}

.header-right-inner {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.progress-bar {
  height: 9px;
  width: 100%;
  border-radius: 40px;
  background-color: var(--gray);
}

.progress {
  height: 100%;
  width: var(--progress-bar-width);
  border-radius: 40px;
  background-color: var(--green-success);
}
</style>

<script>
export default {
  props: ["h1", "h2", "stepCurrent", "stepMax", "done"],
  computed: {
    progressBarWidth() {
      let progress = 0;
      if (this.done) progress = 100;
      else progress = (this.stepCurrent / this.stepMax) * 100;

      return {
        "--progress-bar-width": progress + "%",
      };
    },
  },
};
</script>
