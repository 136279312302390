<template>
  <div v-bind:class="['nft-card', burned ? 'burned' : '']">
    <router-link :to="link">
      <img class="image" v-bind:src="image" />
    </router-link>
    <div class="top-half">
      <router-link :to="link">
        <div class="nft-name" v-if="nft.metadata && nft.metadata.name" :title="nft.metadata.name">{{ nft.metadata.name }}</div>
        <div class="nft-name" v-else>Unknown</div>
      </router-link>

      <div class="creator-wrapper">
        <UserLink :name="creatorName" :address="creatorAddress" :verified="verified" />
        <div v-if="qty > 1" class="quantity-wrapper">
          <LayersIcon alt="Quantity" />
          <span class="qty">x{{ qty }}</span>
        </div>
      </div>
    </div>

    <hr />

    <div class="bottom-half">
      <div class="nft-price">
        <p>Price</p>
        <div class="nft-price-value" v-if="nft.ethForSale">
          <Price :price="nft.ethPrice" symbol="UBQ" />
        </div>
        <div class="nft-price-value" v-else-if="nft.tokenForSale">
          <Price :price="nft.tokenPrice" symbol="GRANS" />
        </div>
        <div class="nft-price-value" v-else>
          <span>Not for sale</span>
        </div>
      </div>

      <div class="icons-container">
        <div class="favorites">
          <Heart alt="Favorites" id="favorite" />
          <span class="num">{{ favoritesCount || " " }}</span>
        </div>

        <div class="comments">
          <Comment alt="Comments" id="comment" />
          <span class="num">{{ commentsCount || " " }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.tooltip > img {
  cursor: help;
}

.nft-card {
  text-shadow: none;
  max-width: 8em;
}

.nft-card .price-icons img {
  width: 14px;
  height: 14px;
  padding: 0;
  margin: none;
  position: relative;
}

.nft-card.burned {
  background-color: lightpink;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.price-icons {
  height: 18px;
}

.nft-card {
  display: inline-block;
  border-radius: 8px;
  min-width: 361px;
  max-width: 361px;
  height: 648px;
}

.nft-card .image {
  display: flex;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.nft-card .top-half {
  height: 152px;
  background-color: var(--card-background);
}

.nft-card .nft-name {
  display: block;
  color: var(--card-foreground);
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  padding: 24px 40px;
  overflow-wrap: break-word;
  overflow: hidden;
  height: 48px;
}

.nft-card .nft-user .user-name {
  font-size: 14px;
}

.nft-card .creator-wrapper {
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nft-card .quantity-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nft-card .quantity-wrapper svg {
  margin: 0 8px;
}

.nft-card .quantity-wrapper .qty {
  color: var(--gray-label);
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
}

.nft-card hr {
  border: 1px solid var(--gray);
  margin: 0;
}

.nft-card .bottom-half {
  display: flex;
  justify-content: space-between;
  padding: 24px 40px;
  color: var(--card-foreground);
  background-color: var(--card-background);
}

.nft-card .nft-price {
  display: flex;
  padding-right: 24px;
  flex-direction: column;
  text-transform: uppercase;
}

.nft-card .nft-price .nft-price-value {
  font-weight: bold;
}

.nft-card .icons-container {
  display: flex;
  align-items: center;
}

.nft-card .favorites {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.nft-card .comments {
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.num {
  color: var(--gray-label);
  margin-left: 8px;
}

#app .nft-card a {
  font-weight: normal;
  margin: 0;
  padding: 0;
  border-radius: none;
}
</style>

<script>
import shared from "../shared";
import UserLink from "./UserLink.vue";
import Price from "./Price.vue";
import LayersIcon from "@/assets/images/layers.svg";
import Heart from "@/assets/images/heart.svg";
import Comment from "@/assets/images/comment.svg";

export default {
  components: {
    UserLink,
    Price,
    LayersIcon,
    Heart,
    Comment,
  },
  props: ["nft", "quantityOverride", "creatorNameOverride", "favoritesOverride"],
  async created() {
    if (this.nft.metadata == null) {
      console.debug("No metadata :-/");
      if (this.nft.metadataUri == null) {
        console.warn("Encountered broken NFT (no metadata URI)");
      } else {
        console.debug("But we can have an URI so we can query it :-)");
        this.nft.metadata = await this.fetchJson(this.nft.metadataUri);
      }
    }
  },
  data() {
    return {
      verified: false,
    };
  },
  asyncComputed: {
    store: {
      async get() {
        if (this.nft.store) {
          return await shared.fetchJson(`${this.$apiBase}/api/store/${this.nft.store}`);
        } else {
          // return default
          return {
            address: "0x0000000000000000000000000000000000000000",
            name: "Nothing",
            symbol: "NTHNG",
            creator: "0x0000000000000000000000000000000000000000",
          };
        }
      },
      default: {
        address: "0x0000000000000000000000000000000000000000",
        name: "Nothing",
        symbol: "NTHNG",
        creator: "0x0000000000000000000000000000000000000000",
      },
    },
    creatorName: {
      async get() {
        if (this.creatorNameOverride) return this.creatorNameOverride;
        if (this.store.creator == "0x0000000000000000000000000000000000000000") return null;

        try {
          const u = await shared.fetchJson(`${this.$apiBase}/api/user/${this.store.creator}`);
          this.verified = u.verified;
          return u ? u.username : null;
        } catch (e) {
          console.warn(`Error loading user by address ${this.store.creator}`, e);
          return null;
        }
      },
      default: "Loading...",
    },
    creatorAddress() {
      if (this.store) return this.store.creator;
      else return "0x0000000000000000000000000000000000000000"; // FIXME
    },
    burned() {
      return this.nft.owner == "0x0000000000000000000000000000000000000000";
    },
    adult() {
      const val = this?.nft?.metadata?.properties?.adult;
      return typeof val === "undefined" ? false : val;
    },
    set() {
      return this.nft !== null && this.nft.cnt > 1;
    },
    qty() {
      if (this.quantityOverride) return this.quantityOverride;
      else if (this.nft.cnt) return this.nft.cnt;
      else return 1;
    },
    link: {
      async get() {
        if (this.nft && this.nft.store) {
          if (this.nft.metaId) {
            return { name: "NFT", params: { collection: this.nft.store, nft: this.nft.metaId } };
          } else {
            return { name: "NFT", params: { collection: this.nft.store, nft: this.nft.nftId } };
          }
        }

        return "#";
      },
      default: "#",
    },
    image() {
      let src;

      if (this.nft.metadata) {
        if (this.nft.metadata.preview) {
          src = this.nft.metadata.preview;
        } else {
          src = this.nft.metadata.image;
        }
      } else {
        src = "/question.png";
      }

      if (src.startsWith("ipfs") || src.startsWith("Qm")) {
        src = shared.formatIpfsLink(this.$ipfsPrefix, src);
      }

      return src;
    },
    favoritesCount: {
      async get() {
        if (this.favoritesOverride) return this.favoritesOverride;

        if (this.nft.store && this.nft.metaId) {
          const response = await shared.fetchJson(`${this.$apiBase}/api/store/${this.nft.store}/meta/${this.nft.metaId}/favorites/count`);

          return response.count || "0";
        }
        return null;
      },
      default: null,
    },
    commentsCount: {
      async get() {
        if (this.commentsOverride) return this.commentsOverride;

        if (this.nft.store && this.nft.metaId) {
          const response = await shared.fetchJson(`${this.$apiBase}/api/store/${this.nft.store}/meta/${this.nft.metaId}/comments/count`);

          return response.count || "0";
        }
        return null;
      },
      default: null,
    },
  },
  async mounted() {},
  methods: {
    async fetchJson(url) {
      return await (await fetch(url)).json();
    },
  },
};
</script>
