<template>
  <div class="action-modal-container">

    <div v-if="action == 'share'" class="right-half">
        <section class="share">
          <h1>Share this NFT</h1>
          <h2>Send your friends a link to view this NFT.</h2>
          <h3 class="share-text">{{ shareUrl }}</h3>
          <button @click="handleShareButton" :class="{ 'green-button not-hoverable': copyState == 'done' }">
            {{ copyState == "done" ? "Copied to clipboard" : "Copy share link" }}
          </button>
        </section>
    </div>

    <div v-else-if="!userAuthenticated || !metaMaskConnected" class="prompt">
      <h1>Log in to continue.</h1>
      <button @click="$emit('connect-wallet')">Connect Wallet</button>
      <button @click="cancelCallbackFn" class="cancel">Cancel</button>
    </div>

    <div v-else-if="transactionState == 'ask-for-signature'" class="prompt ask-for-signature">
      <h1>Sign to confirm</h1>
      <h2>Click the sign button below to confirm the action you've requested.</h2>
      <!-- button depends on action -->
      <button v-if="action == 'buy'" @click="executeBuy">Sign</button>
      <button v-else-if="action == 'transfer'" @click="executeTransfer">Sign</button>
      <button v-else-if="action == 'burn'" @click="executeBurn">Sign</button>
      <button v-else-if="action == 'setPrice'" @click="executeSetPrice">Sign</button>
      <button v-else-if="action == 'removeFromSale'" @click="executeRemoveFromSale">Sign</button>
      <button v-else>Unimplemented</button>

      <button @click="cancelCallbackFn" class="cancel">Cancel</button>
    </div>

    <div v-else-if="transactionState == 'waiting-for-signature'" class="prompt waiting-for-signature">
      <h1>Sign to confirm</h1>
      <h2>Your browser extension or wallet app will prompt you to sign for this transaction.</h2>
      <button class="disabled">Waiting for signature...</button>
      <button @click="cancelCallbackFn" class="cancel">Cancel</button>
    </div>

    <div v-else-if="transactionState == 'waiting-for-confirmation'" class="prompt waiting-for-confirmation">
      <h1 v-if="action == 'buy'">Buying NFT</h1>
      <h1 v-else-if="action == 'setPrice'">Setting price</h1>
      <h1 v-else-if="action == 'removeFromSale'">Removing from sale</h1>
      <h1 v-else-if="action == 'transfer'">Transferring NFT</h1>
      <h1 v-else-if="action == 'burn'">Burning NFT</h1>
      <h1 v-else>Waiting for transaction</h1>
      <h2 v-if="confirmedOnce"><span class="success">Received one blockchain confirmation.</span> <br />Waiting for one more...</h2>
      <h2 v-else><span class="error">Do not close this window.</span> <br />This should take between 10-120 seconds.</h2>
      <img class="loading" src="/hourglass.gif" />
    </div>

    <div v-else-if="transactionState == 'error'" class="prompt transation-error">
      <h1 class="error">{{ transactionError || "Unspecified error" }}</h1>
      <h1 class="sub-error">{{ transactionError_secondary }}</h1>
      <h2>Please contact support, or retry your transaction</h2>
      <button @click="transactionState = 'ask-for-signature'">Retry</button>
      <button @click="cancelCallbackFn" class="cancel">Cancel</button>
    </div>

    <div v-else-if="transactionState == 'transaction-done'" class="prompt transation-done">
      <h1 class="success">Success!</h1>
    </div>

    <div v-else class="action-modal">
      <WhiteXIcon @click="cancelCallbackFn" class="close-modal" alt="Close modal" />

      <aside v-if="action == 'buy'">
        <NftCard v-if="selectedNft" :nft="selectedNft" />
      </aside>

      <div v-if="action == 'buy'" class="right-half">
        <section v-if="actionMode == 'single'" class="action-single">
          <h1>Buy NFT</h1>
          <h2 v-if="selectedNftPrice">
            You're purchasing this 1 of {{ nftSet.cnt }} from
            <UserLink :name="selectedNftPrice.ownerName" :address="selectedNftPrice.ownerAddress" :verified="selectedNftPrice.ownerVerified" />
          </h2>

          <article v-if="selectedNftPrice" class="border-top">
            <h2 class="green">Price you're paying</h2>
            <h3>
              <Price :price="selectedNftPrice.priceCrypto" :symbol="selectedNftPrice.symbol" />
            </h3>
            <h4 v-if="selectedNftPrice">${{ (selectedNftPrice.priceUsd / 1e18).toFixed(2) }}</h4>
          </article>

          <article v-if="selectedNftPrice" class="your-balance">
            <p class="allcaps">Your balance</p>
            <p class="balance" v-if="selectedNftPrice.symbol == 'UBQ'">{{ walletBalance }} UBQ</p>
            <p class="balance" v-else-if="selectedNftPrice.symbol == 'GRANS'">{{ wallet10GransBalance }} GRANS</p>
          </article>

          <p>Confirming can't be undone.</p>

          <div v-if="!sufficientBalance" class="confirm-purchase">
            <button class="disabled">Confirm Purchase</button>
            <p class="insufficient">Insufficient balance</p>
          </div>
          <div v-else-if="selectedNftPrice.ownerAddress == userAddress" class="confirm-purchase">
            <button class="disabled">Confirm Purchase</button>
            <p class="insufficient">You already own this</p>
          </div>
          <div v-else class="confirm-purchase">
            <button @click="transactionState = 'ask-for-signature'">Confirm Purchase</button>
          </div>

          <button @click="cancelCallbackFn" class="cancel">Cancel</button>
        </section>

        <section v-else-if="actionMode == 'multiple'" class="action-multiple">
          <h1>Buy NFT</h1>
          <h2 class="green">Choose which copy you want to buy.</h2>

          <div class="scrollable-container">
            <article v-for="nft in nftsByPrice" :key="nft.number">
              <div class="details-buy">
                <div class="details">
                  <p class="allcaps"># {{ nft.number }} of {{ nftSet.cnt }}</p>
                  <Price :price="nft.priceCrypto || 0" :symbol="nft.symbol" :custom-free-message="nft.priceUsd == -1 ? 'Not for sale' : 'Free!'" />
                </div>
                <button :class="{ 'cannot-select': nft.priceUsd == -1 || nft.ownerAddress == userAddress }" @click="selectNft(nft.number)">Buy</button>
              </div>

              <p class="seller" v-if="nft.ownerName">
                <span class="allcaps">Owner</span> <UserLink :name="nft.ownerName" :address="nft.ownerAddress" :verified="nft.ownerVerified" />
              </p>
              <p class="seller" v-else />
            </article>
          </div>
        </section>

        <section v-else>
          <h1>Loading...</h1>
        </section>
      </div>

      <div v-else-if="action == 'setPrice'" class="right-half">
        <section v-if="actionMode == 'single'" class="action-single">
          <h1>Put NFT on sale</h1>
          <h2>
            This will list your NFT for sale on the public marketplace.
          </h2>

          <article class="border-top">
            <h2 class="green">Currency</h2>
            <select id="input-currency" name="currency" v-model="currency">
              <option value="Ubiq" selected>Ubiq</option>
              <option value="10grans">10grans</option>
            </select>
          </article>

          <article>
            <h2 class="green">Price</h2>
            <input type="number" min="0" step="any" id="input-price" name="price" v-model="price" placeholder="How much?" />
          </article>

          <p class="price-conversion">{{ priceConversion }}</p>

          <div v-if="displayPriceFormError && priceFormError" class="confirm-purchase">
            <button class="disabled">Set Price</button>
            <p class="insufficient">{{ priceFormError }}</p>
          </div>
          <div v-else class="confirm-purchase">
            <button @click="startPriceFlow">Set Price</button>
          </div>

          <button @click="cancelCallbackFn" class="cancel">Cancel</button>
        </section>

        <section v-else-if="actionMode == 'multiple'" class="action-multiple">
          <h1>Transfer NFT</h1>
          <h2 class="green">Choose which copy you want to list for sale.</h2>

          <div class="scrollable-container">
            <article v-for="nft in nftsByPrice_userFirst" :key="nft.number">
              <div class="details-buy">
                <div class="details">
                  <p class="allcaps"># {{ nft.number }} of {{ nftSet.cnt }}</p>
                  <Price :price="nft.priceCrypto || 0" :symbol="nft.symbol" :custom-free-message="nft.priceUsd == -1 ? 'Not for sale' : 'Free!'" />
                </div>
                <button :class="{ 'cannot-select': nft.ownerAddress != userAddress }" @click="selectNft(nft.number)">Set Price</button>
              </div>

              <p class="seller" v-if="nft.ownerName">
                <span class="allcaps">Owner</span> <UserLink :name="nft.ownerName" :address="nft.ownerAddress" :verified="nft.ownerVerified" />
              </p>
              <p class="seller" v-else />
            </article>
          </div>
        </section>

        <section v-else>
          <h1>Loading...</h1>
        </section>
      </div>

      <div v-else-if="action == 'removeFromSale'" class="right-half">
        <section v-if="actionMode == 'single'" class="action-single">
          <h1>Remove from sale</h1>
          <h2>
            This will remove your NFT from the public marketplace. It will still be listed in your collection until you relist it.
          </h2>

          <article class="border-top"></article>

          <p>Confirming can't be undone.</p>

          <div class="confirm-purchase">
            <button @click="transactionState = 'ask-for-signature'">Confirm removal</button>
          </div>

          <button @click="cancelCallbackFn" class="cancel">Cancel</button>
        </section>

        <section v-else-if="actionMode == 'multiple'" class="action-multiple">
          <h1>Remove from sale</h1>
          <h2 class="green">Choose which copy you want to remove for sale.</h2>

          <div class="scrollable-container">
            <article v-for="nft in nftsByPrice_userFirst" :key="nft.number">
              <div class="details-buy">
                <div class="details">
                  <p class="allcaps"># {{ nft.number }} of {{ nftSet.cnt }}</p>
                  <Price :price="nft.priceCrypto || 0" :symbol="nft.symbol" :custom-free-message="nft.priceUsd == -1 ? 'Not for sale' : 'Free!'" />
                </div>
                <button :class="{ 'cannot-select': nft.ownerAddress != userAddress || nft.priceUsd == -1 }" @click="selectNft(nft.number)">Remove</button>
              </div>

              <p class="seller" v-if="nft.ownerName">
                <span class="allcaps">Owner</span> <UserLink :name="nft.ownerName" :address="nft.ownerAddress" :verified="nft.ownerVerified" />
              </p>
              <p class="seller" v-else />
            </article>
          </div>
        </section>

        <section v-else>
          <h1>Loading...</h1>
        </section>
      </div>

      <div v-else-if="action == 'transfer'" class="right-half">
        <section v-if="actionMode == 'single'" class="action-single">
          <h1>Transfer NFT</h1>
          <h2>
            This will send this NFT from your address to a different address. They will own it.
          </h2>

          <article class="border-top">
            <h2 class="green">Edition</h2>
            <h3 v-if="selectedNft && selectedNft.metadata.properties.set">{{ selectedNft.metadata.properties.set.number }} / {{ nftSet.cnt }}</h3>
            <h3 v-else>1 / {{ nftSet.cnt }}</h3>
          </article>

          <article>
            <h2 class="green">Recipient</h2>
            <input type="text" id="input-recipient" name="recipient" v-model="recipient" placeholder="0x1234..." maxlength="42" />
          </article>

          <p>Confirming can't be undone.</p>

          <div v-if="displayTransferFormError && transferFormError" class="confirm-purchase">
            <button class="disabled">Transfer</button>
            <p class="insufficient">{{ transferFormError }}</p>
          </div>
          <div v-else class="confirm-purchase">
            <button @click="startTransferFlow">Transfer</button>
          </div>

          <button @click="cancelCallbackFn" class="cancel">Cancel</button>
        </section>

        <section v-else-if="actionMode == 'multiple'" class="action-multiple">
          <h1>Transfer NFT</h1>
          <h2 class="green">Choose which copy you want to transfer.</h2>

          <div class="scrollable-container">
            <article v-for="nft in nftsByPrice_userFirst" :key="nft.number">
              <div class="details-buy">
                <div class="details">
                  <p class="allcaps"># {{ nft.number }} of {{ nftSet.cnt }}</p>
                </div>
                <button :class="{ 'cannot-select': nft.ownerAddress != userAddress }" @click="selectNft(nft.number)">Transfer</button>
              </div>

              <p class="seller" v-if="nft.ownerName">
                <span class="allcaps">Owner</span> <UserLink :name="nft.ownerName" :address="nft.ownerAddress" :verified="nft.ownerVerified" />
              </p>
              <p class="seller" v-else />
            </article>
          </div>
        </section>

        <section v-else>
          <h1>Loading...</h1>
        </section>
      </div>

      <div v-else-if="action == 'burn'" class="right-half">
        <section v-if="actionMode == 'single'" class="action-single">
          <h1>Burn NFT</h1>
          <h2>
            Burning is a permanent action. You can't bring it back. Be absolutely sure you want to destroy it before confirming below.
          </h2>

          <article class="border-top">
            <h2 class="red">Edition</h2>
            <h3 v-if="selectedNft && selectedNft.metadata.properties.set">{{ selectedNft.metadata.properties.set.number }} / {{ nftSet.cnt }}</h3>
            <h3 v-else>1 / {{ nftSet.cnt }}</h3>
          </article>

          <p>Confirming can't be undone.</p>

          <div class="confirm-purchase">
            <button @click="transactionState = 'ask-for-signature'" class="danger-button">Burn</button>
          </div>

          <button @click="cancelCallbackFn" class="cancel">Cancel</button>
        </section>

        <section v-else-if="actionMode == 'multiple'" class="action-multiple">
          <h1>Burn NFT</h1>
          <h2 class="red">Choose which copy you want to burn.</h2>

          <div class="scrollable-container">
            <article v-for="nft in nftsByPrice_userFirst" :key="nft.number">
              <div class="details-buy">
                <div class="details">
                  <p class="allcaps"># {{ nft.number }} of {{ nftSet.cnt }}</p>
                </div>
                <button class="danger-button" :class="{ 'cannot-select': nft.ownerAddress != userAddress }" @click="selectNft(nft.number)">Burn</button>
              </div>

              <p class="seller" v-if="nft.ownerName">
                <span class="allcaps">Owner</span> <UserLink :name="nft.ownerName" :address="nft.ownerAddress" :verified="nft.ownerVerified" />
              </p>
              <p class="seller" v-else />
            </article>
          </div>
        </section>

        <section v-else>
          <h1>Loading...</h1>
        </section>
      </div>

      <div v-else>
        <h1>Loading...</h1>
      </div>
    </div>
  </div>
</template>

<style scoped>
div .action-modal-container {
  width: 100%;
  padding: 0;
}

div.action-modal {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;

  min-width: min-content;
  text-align: left;
  background-color: var(--black);
  border-radius: var(--modal-border-radius);
}

aside {
  margin: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 1180px) {
  aside {
    display: none;
  }
}

.right-half {
  background: var(--alt-background);
  border-radius: var(--modal-border-radius);
  display: flex;
}

section {
  display: flex;
  flex-direction: column;
  justify-content: center;

  color: var(--white);
  width: 400px;
  padding: 40px 50px;
}

section h1 {
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 8px;
}

section h2 {
  font-size: 16px;
  line-height: 22.4px;
  font-weight: 400;
  margin-top: 24px;
  margin-bottom: 0;
}

section h2.green,
section h2.red {
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 16px;
}

.green {
  color: var(--green);
}

.red {
  color: var(--red);
}

.scrollable-container {
  max-height: 60vh;
  margin-top: 32px;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
}

section.action-multiple article {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-bottom: 16px;
  padding: 16px;
  border-radius: 4px;
  background-color: var(--card-background);
}

section.action-multiple article .details-buy {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}

section.action-multiple button {
  margin: 0;
  padding: 2px 27.5px;
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  background-color: var(--green);
}

section.action-multiple button.cannot-select,
section.action-multiple button.cannot-select.danger-button {
  background-color: var(--offwhite);
  cursor: default !important;
  pointer-events: none !important;
}

section.action-multiple button.danger-button {
  background-color: var(--red);
}

section.action-single article {
  margin-top: 16px;
  margin-bottom: 32px;
}

section.action-single article.border-top {
  border-top: var(--border-soft);
  padding-top: 32px;
  margin-top: 32px;
  margin-bottom: 0;
}

section.action-single h3 {
  font-size: 48px;
  line-height: 48px;
  font-weight: 700;
  margin-bottom: 16px;
}

section.action-single h4 {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}

section.action-single button {
  background-color: var(--green);
}

section.action-single button.danger-button {
  background-color: var(--red);
}

button {
  background-color: var(--accent);
  color: var(--white);
  margin: 0;
  margin-top: 32px;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
}

button.cancel,
section.action-single button.cancel {
  background-color: var(--card-foreground);
  color: var(--black);
  margin-top: 16px;
}

section.share {
  text-align: center;
}

section.share .share-text {
  margin-top: 32px;
  font-size: 24px;
  line-height: 33.6px;
  font-weight: 700;
  color: var(--green-success);
}

.green-button {
  background-color: var(--green);
}

.allcaps {
  text-transform: uppercase;
}

.your-balance {
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 16px 24px;
  background-color: var(--card-background);
  border-radius: 4px;
}

.your-balance p {
  margin: 0;
}

.your-balance p.balance {
  margin-top: 8px;
  font-weight: 600;
}

p.seller {
  min-height: 16.8px;
}

.confirm-purchase button {
  width: 100%;
}

.confirm-purchase .insufficient {
  color: var(--red);
  text-align: center;
  margin-top: 8px;
}

.prompt {
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: var(--alt-background);
  border-radius: var(--modal-border-radius);
  color: var(--white);
  width: 424px;
  padding: 48px;
}

.prompt h1 {
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
}

.prompt h2 {
  font-size: 16px;
  line-height: 22.4px;
  font-weight: 400;
}

.success {
  color: var(--green-success);
  font-weight: bold;
  line-height: 1.25em;
}

h1.sub-error {
  color: var(--red);
  font-size: 24px;
  line-height: 34px;
  font-weight: bold;
}

.close-modal {
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 24px;
}

input {
  max-width: 100%;
}

.price-conversion {
  font-weight: bold;
  text-align: center;
}
</style>

<script>
import shared from "@/shared";
const BigNumber = require("bignumber.js");
import NftCard from "@/components/NftCard.vue";
import Price from "@/components/Price.vue";
import UserLink from "@/components/UserLink.vue";
import WhiteXIcon from "@/assets/images/white-x.svg";

export default {
  components: {
    NftCard,
    Price,
    UserLink,
    WhiteXIcon,
  },
  data() {
    return {
      transactionState: "not-started",
      transactionError: "",
      transactionError_secondary: "",
      confirmedOnce: false,
      selectedNftId: null,
      displayTransferFormError: false,
      displayPriceFormError: false,
      recipient: null,
      currency: "Ubiq",
      price: null,
      ethPrice: 0,
      tokenPrice: 0,
      copyState: "not-copied",
    };
  },
  props: ["action", "collectionId", "nftSet", "nftsByPrice", "cancelCallbackFn", "successCallbackFn"],
  async created() {
    const price = await shared.getPrice(this.$apiBase);
    this.ethPrice = price.ubiqUsdRatio;
    this.tokenPrice = price.ubiqUsdRatio / price.ubiqGransRatio;
  },
  computed: {
    actionMode() {
      // if an NFT has been selected, we're in single-nft mode
      if (this.selectedNftId) return "single";

      // else derive it from the # of nfts in this set
      if (this.nftSet) {
        if (this.nftSet.nfts.length > 1) return "multiple";
        if (this.nftSet.nfts.length == 1) return "single";
      }

      return null;
    },
    userAuthenticated() {
      return this.$store.getters.isUserAuthenticated;
    },
    metaMaskConnected() {
      return this.$store.state.accounts.length > 0;
    },
    userAddress() {
      return this.$store.state.user.id;
    },
    sufficientBalance() {
      if (!this.selectedNftPrice) return null;

      let comparison = false;
      if (this.selectedNftPrice.symbol == "GRANS") {
        comparison = BigNumber(this.wallet10GransBalanceUnformatted).comparedTo(BigNumber(this.selectedNftPrice.priceCrypto));
      } else {
        comparison = BigNumber(this.walletBalanceUnformatted).comparedTo(BigNumber(this.selectedNftPrice.priceCrypto));
      }

      return comparison != -1;
    },
    transferFormError() {
      if (!this.recipient) {
        return "Enter a Ubiq address";
      }
      if (!this.recipient.startsWith("0x")) {
        return "Address must start with 0x";
      }
      if (!this.recipient.match(/^0x[a-fA-F0-9]{40}$/)) {
        return "Improper address format";
      }

      return null;
    },
    priceFormError() {
      if (!this.currency) {
        return "You must select a currency";
      }
      if (this.price === null || this.price === "") {
        return "Price must be a number";
      }
      if (this.price < 0) {
        return "Price cannot be negative";
      }
      if (isNaN(this.price)) {
        return "Price must be a valid number";
      }

      return null;
    },
    priceConversion() {
      if (this.currency == "Ubiq") {
        if (this.price) {
          const calc = (this.price * this.ethPrice).toFixed(2);
          return `${this.price} UBQ = $${calc}`;
        } else {
          const calc = this.ethPrice.toFixed(2);
          return `1 UBQ = $${calc}`;
        }
      } else if (this.currency == "10grans") {
        if (this.price) {
          const calc = (this.price * this.tokenPrice).toFixed(2);
          return `${this.price} GRANS = $${calc}`;
        } else {
          const calc = this.tokenPrice.toFixed(2);
          return `1 GRANS = $${calc}`;
        }
      }

      return null;
    },
    nftsByPrice_userFirst() {
      let sorted = [];

      if (this.nftSet && this.nftSet.nfts && this.nftsByPrice) {
        sorted = [...this.nftsByPrice]; // copy array

        const comparator = (a, b) => {
          // put nfts owned by this user before all others
          if (a.ownerAddress == this.userAddress && b.ownerAddress != this.userAddress) {
            return -1;
          } else if (a.ownerAddress != this.userAddress && b.ownerAddress == this.userAddress) {
            return 1;
          } else {
            // fallback to sorting by the number within set
            return a.number - b.number;
          }
        };
        sorted.sort(comparator);
      }

      return sorted;
    },
    shareUrl() {
      const response = this.tokenDatabaseId;
      if (response) {
        return `https://token.gallery/${response.id}`;
      }
      return null;
    },
  },
  asyncComputed: {
    storeContract: {
      async get() {
        if (this.$root.web3 && this.collectionId) {
          const storeAbi = await this.$storeAbiPromise;
          return new this.$root.web3.eth.Contract(storeAbi, this.collectionId);
        }
        return null;
      },
    },
    tokenContract: {
      async get() {
        if (this.$root.web3) {
          const tokenAbi = await this.$tokenAbiPromise;
          return new this.$root.web3.eth.Contract(tokenAbi, this.$tokenAddress);
        }
        return null;
      },
    },
    selectedNft: {
      // Selected NFT represents the NFT that will be affected by the action (buy, transfer, etc)
      async get() {
        //console.log("recomputing selectedNft. this.selectedNftId: " + this.selectedNftId)
        //console.log("nftSet: " + JSON.stringify(this.nftSet, null, 2));
        //console.log("nftsByPrice: " + JSON.stringify(this.nftsByPrice, null, 2));
        if (this.selectedNftId) {
          return this.getNftById(this.selectedNftId);
        } else {
          // If nothing has been selected, fallback mechanism is dependent on the action.

          // For buys, default to the best-priced one, or nothing
          if (this.action == "buy") {
            if (this.nftSet && this.nftsByPrice) {
              const bestId = this.nftSet.nfts.find((x) => x.number == this.nftsByPrice[0].number).id;
              return this.getNftById(bestId);
            }
            return null;
          } else {
            // for transfer/set price/etc, pick the first NFT you own, or nothing
            if (this.nftSet) {
              const bestId = this.nftSet.nfts.find((x) => x.owner == this.userAddress).id;
              return this.getNftById(bestId);
            }
            return null;
          }
        }
      },
      default: null,
    },
    selectedNftPrice() {
      if (this.selectedNft) {
        let number = 1;
        if (this.selectedNft.metadata.properties.set) {
          number = this.selectedNft.metadata.properties.set.number;
        }

        return this.nftsByPrice.find((x) => x.number == number);
      }
      return null;
    },
    walletBalance: {
      async get() {
        const balance = this.walletBalanceUnformatted;
        return shared.formatPrice(balance);
      },
      default: 0,
    },
    walletBalanceUnformatted: {
      async get() {
        if (this.$root.web3) {
          return await this.$root.web3.eth.getBalance(this.userAddress);
        }
        return null;
      },
      default: 0,
    },
    wallet10GransBalance: {
      async get() {
        const balance = await this.wallet10GransBalanceUnformatted;
        return shared.formatPrice(balance);
      },
      default: 0,
    },
    wallet10GransBalanceUnformatted: {
      async get() {
        if (this.$root.web3) {
          const tokenAbi = await this.$tokenAbiPromise;
          this.tokenContract = new this.$root.web3.eth.Contract(tokenAbi, this.$tokenAddress);
          return await this.tokenContract.methods.balanceOf(this.userAddress).call();
        }
        return null;
      },
      default: 0,
    },
    tokenDatabaseId: {
      async get() {
        if (this.nftSet) {
          return await shared.fetchJson(`${this.$apiBase}/api/id/${this.nftSet.nfts[0].id}`);
        }
        return null;
      },
      default: null,
    },
  },
  methods: {
    // Note- there are two kinds of IDs used here:
    //   1. number (identifies which edition within a set)
    //   2. nftId (70 character globally-unique string identifying the nft)
    selectNft(number) {
      //console.log("clicked nft #" + number)
      this.selectedNftId = this.nftSet.nfts.find((x) => x.number == number).id;
      //console.log("setting this.selectedNftId to " + this.selectedNftId);
    },
    async getNftById(nftId) {
      //console.log("fetching by nft id " + nftId);
      return await shared.fetchJson(`${this.$apiBase}/api/store/${this.collectionId}/nft/${nftId}`);
    },
    async executeBuy() {
      this.transactionState = "waiting-for-signature";

      const address = this.userAddress;
      const amount = this.selectedNftPrice.priceCrypto;
      const nftId = this.selectedNftPrice.nftId;

      // Execute transaction
      if (this.selectedNftPrice.symbol == "UBQ") {
        this.$gtag.event("buy", { currency: "ubiq" });

        await this.storeContract.methods
          .buyNft(nftId)
          .send({ from: address, value: amount })
          .on("transactionHash", this.handleTransactionHash)
          .on("confirmation", this.handleConfirmation)
          .catch(this.handleError);
      } else if (this.selectedNftPrice.symbol == "GRANS") {
        const mangledNftId = this.$root.web3.eth.abi.encodeParameter("uint256", nftId);
        this.$gtag.event("buy", { currency: "10grans" });

        await this.tokenContract.methods
          .approveAndCall(this.collectionId, amount, mangledNftId)
          .send({ from: address })
          .on("transactionHash", this.handleTransactionHash)
          .on("confirmation", this.handleConfirmation)
          .catch(this.handleError);
      } else {
        console.error(`Unexpected currency: '${this.selectedNftPrice.symbol}'`);
      }
    },
    startTransferFlow() {
      if (this.transferFormError) {
        this.displayTransferFormError = true;
        return;
      }

      this.transactionState = "ask-for-signature";
    },
    startPriceFlow() {
      if (this.priceFormError) {
        this.displayPriceFormError = true;
        return;
      }

      this.transactionState = "ask-for-signature";
    },
    async executeTransfer() {
      const senderAddress = this.userAddress;
      const recipientAddress = this.recipient;
      const nftId = this.selectedNftPrice.nftId;

      //console.log("sender: " + senderAddress);
      //console.log("recipient: " + recipientAddress);
      //console.log("nftId: " + nftId);

      this.$gtag.event("transfer", {});

      await this.storeContract.methods
        .safeTransferFrom(senderAddress, recipientAddress, nftId, "1", [])
        .send({ from: senderAddress })
        .on("transactionHash", this.handleTransactionHash)
        .on("confirmation", this.handleConfirmation)
        .catch(this.handleError);
    },
    async executeBurn() {
      const senderAddress = this.userAddress;
      const nftId = this.selectedNftPrice.nftId;

      this.$gtag.event("burn", {});

      await this.storeContract.methods
        .burn(senderAddress, nftId, "1")
        .send({ from: senderAddress })
        .on("transactionHash", this.handleTransactionHash)
        .on("confirmation", this.handleConfirmation)
        .catch(this.handleError);
    },
    async executeSetPrice() {
      const senderAddress = this.userAddress;
      const nftId = this.selectedNftPrice.nftId;

      let ethPrice = 0,
        ethForSale = false,
        tokenPrice = 0,
        tokenForSale = false;
      if (this.currency == "Ubiq") {
        ethForSale = true;
        ethPrice = new BigNumber(this.price).times("1e18").toString(10);
      } else if (this.currency == "10grans") {
        tokenForSale = true;
        tokenPrice = new BigNumber(this.price).times("1e18").toString(10);
      } else {
        throw "Currency not set";
      }

      //console.debug("ethPrice, ethForSale, tokenPrice, tokenForSale: ");
      //console.debug(ethPrice);
      //console.debug(ethForSale);
      //console.debug(tokenPrice);
      //console.debug(tokenForSale);

      this.$gtag.event("setprice", { count: 1 });

      await this.storeContract.methods
        .setNftPrice(nftId, ethPrice, ethForSale, tokenPrice, tokenForSale)
        .send({ from: senderAddress })
        .on("transactionHash", this.handleTransactionHash)
        .on("confirmation", this.handleConfirmation)
        .catch(this.handleError);
    },
    async executeRemoveFromSale() {
      const senderAddress = this.userAddress;
      const nftId = this.selectedNftPrice.nftId;

      let ethPrice = 0,
        ethForSale = false,
        tokenPrice = 0,
        tokenForSale = false;

      this.$gtag.event("setprice", { count: 1 });

      await this.storeContract.methods
        .setNftPrice(nftId, ethPrice, ethForSale, tokenPrice, tokenForSale)
        .send({ from: senderAddress })
        .on("transactionHash", this.handleTransactionHash)
        .on("confirmation", this.handleConfirmation)
        .catch(this.handleError);
    },
    handleTransactionHash() {
      this.transactionState = "waiting-for-confirmation";
      this.confirmedOnce = false;
    },
    handleConfirmation(confirmationNumber) {
      if (confirmationNumber == 0) {
        console.debug(`Received ${confirmationNumber} blockchain confirmations`);
        this.transactionState = "waiting-for-confirmation";
        this.confirmedOnce = true;
      } else if (confirmationNumber == 1) {
        console.debug(`Received ${confirmationNumber} blockchain confirmations`);
        // Done!
        this.handleComplete();
      }
    },
    handleError(error) {
      this.transactionState = "error";
      const msg = shared.handleTransactionError(error);
      this.transactionError = msg.errorMessage;
      this.transactionError_secondary = msg.errorMessageSecondary;
    },
    handleComplete() {
      this.transactionState = "transaction-done";
      this.successCallbackFn();
    },
    handleShareButton() {
      if (this.copyState == "not-copied") {
        navigator.clipboard.writeText(this.shareUrl);
        this.copyState = "done";

        setTimeout(() => {
          this.copyState == "not-copied";
          this.cancelCallbackFn();
        }, 1500);
      }
    },
  },
};
</script>
