<template>
  <div class="collection-container">
    <router-link v-if="clickAction == 'link'" class="collection" :to="{ name: 'Collection', params: { collection: address } }">
      <div class="collection-top">
        <NewCollection v-if="symbol == '_new_'" />
        <h1 v-else>{{ symbol }}</h1>
      </div>
      <div class="collection-bottom">
        <p class="collection-name">{{ name }}</p>
        <p class="collection-type">ERC-1155</p>
      </div>
    </router-link>

    <div v-else class="collection" @click="clickAction(address)">
      <div class="collection-top">
        <NewCollection v-if="symbol == '_new_'" />
        <h1 v-else>{{ symbol }}</h1>
      </div>
      <div class="collection-bottom" :class="{ selected: selectedCollection == address && address != '_new_' }">
        <p class="collection-name">{{ name }}</p>
        <p class="collection-type">ERC-1155</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.collection-container {
  width: 361px;
  cursor: pointer;
}

.collection-top {
  height: 361px; /* consider reducing this? making it non-square */
  border-radius: 8px 8px 0px 0px;
  background-color: var(--accent);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.collection-top h1 {
  overflow-wrap: anywhere;
  padding: 0 20px;
}

.collection-bottom {
  height: 120px;
  border-radius: 0px 0px 8px 8px;
  padding: 0 20px;
  background-color: var(--card-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.collection-bottom.selected {
  background-color: var(--accent);
}

.collection-name {
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
}

.collection-type {
  font-size: 16px;
  line-height: 24px;
}
</style>

<script>
import NewCollection from "@/assets/images/new-collection.svg";

export default {
  // clickAction can either be the string 'link' (link to collection) or a callback function
  props: ["symbol", "name", "address", "clickAction", "selectedCollection"],
  components: {
    NewCollection,
  },
};
</script>
