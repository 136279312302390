<template>
  <div class="container">
    <CreateNftHeader h1="Write the lore for your NFT." h2="After minting you won’t be able to edit this information. Make sure it’s right." :step-current="3" :step-max="5" />

    <div class="details-container">
      <form class="details-form">
        <h2 class="subheading">Title, description, tags, and extras</h2>

        <input type="text" id="input-title" name="title" v-model="name" placeholder="Title" maxlength="40" @focus="displayNameError = true" />
        <div class="field-info">
          <span v-if="displayNameError" class="field-error">{{ nameError }}</span>
          <span class="field-maxlen">{{ this.name.length }}/40</span>
        </div>

        <textarea
          id="input-desc"
          name="description"
          v-model="description"
          placeholder="Description - Ex. “One time I knew this duck named gary and let me tell you something. Gary knew a thing or two about working a beak...”"
          maxlength="1000"
          @focus="displayDescError = true"
        />
        <div class="field-info">
          <span v-if="displayDescError" class="field-error">{{ descError }}</span>
          <span class="field-maxlen">{{ this.description.length }}/1000</span>
        </div>
        <input type="text" id="input-tags" name="tags" v-model="tags" placeholder="Tags - Ex. “anime, sketch, 3d, yellow”" maxlength="1000" @focus="displayTagsError = true" />
        <div class="field-info">
          <span v-if="displayTagsError" class="field-error">{{ tagsError }}</span>
          <span class="field-maxlen">limit of 15</span>
        </div>

        <section class="extra-properties">
          <h3><strong>Optional</strong> stats, properties, or data</h3>

          <div v-for="(prop, idx) in properties" :key="idx" class="key-value-pair">
            <div class="key">
              <input
                type="text"
                :id="'extra-prop-' + idx + '-key'"
                :name="'extra-prop-' + idx + '-key'"
                :placeholder="'Stat ' + (idx + 1) + ' name'"
                v-model="prop.name"
                maxlength="40"
              />
              <div class="field-info">
                <span class="field-error">{{ propNameError[idx] }}</span>
                <span class="field-maxlen">{{ prop.name.length }}/40</span>
              </div>
            </div>
            <div class="value">
              <input
                type="text"
                :id="'extra-prop-' + idx + '-value'"
                :name="'extra-prop-' + idx + '-value'"
                :placeholder="'Stat ' + (idx + 1) + ' value'"
                v-model="prop.value"
                maxlength="80"
              />
              <div class="field-info">
                <span class="field-maxlen">{{ prop.value.length }}/80</span>
              </div>
            </div>
            <div class="x-icon hoverable" @click="removeProperty(idx)">
              <WhiteXIcon :alt="'Remove property ' + (idx + 1)" />
            </div>
          </div>

          <div @click="appendProperty" class="add-another-stat hoverable">
            <PlusIcon class="plus-icon" alt="+" />
            <span class="add-another-stat-text">Add another stat</span>
          </div>
        </section>

        <div class="checkbox-container">
          <input type="checkbox" id="adult" name="adult" v-model="adult" />
          <label for="adult">Does this NFT contain adult content?</label>
        </div>

        <button type="button" class="button button-round-accent" :class="{ disabled: formError }" id="continue-button" @click="continueButton">Continue</button>
        <span v-if="displayFormError" class="field-error center">{{ formError }}</span>
      </form>

      <aside class="details-preview">
        <h2 class="subheading">Preview</h2>
        <div class="nft-card-wrapper" @click="showImage">
          <NftCard :nft="nft" :creator-name-override="username" :favorites-override="'1'" />
        </div>
      </aside>
    </div>
  </div>
</template>

<style scoped>
.details-container {
  display: flex;
}

.details-form {
  width: 42%;
  display: flex;
  flex-direction: column;
}

.details-preview {
  width: 25%;
  margin: 0 auto;
}

.field-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 24px;
}

.field-error {
  color: var(--red);
  font-size: 14px;
}

.field-maxlen {
  flex-grow: 1;
  margin-left: 16px;
  text-align: right;
  color: var(--card-foreground);
  white-space: nowrap;
}

.nft-card-wrapper {
  cursor: pointer;
}

.nft-card {
  pointer-events: none;
}

.extra-properties h3 {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 24px;
}

.key-value-pair {
  display: flex;
}

.key-value-pair .key {
  display: flex;
  flex-direction: column;
  width: 48%;
  margin-right: 2%;
}

.key-value-pair .value {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.key-value-pair .x-icon {
  cursor: pointer;
  margin-left: 8px;
  /*margin-top: 21.5px;*/
}

.add-another-stat {
  display: flex;
  cursor: pointer;
  padding-bottom: 16px;
}

.plus-icon {
  margin-right: 8px;
}

.add-another-stat-text {
  color: var(--purple-text);
  font-size: 16px;
  line-height: 24px;
}

#continue-button {
  margin-top: 56px;
  text-align: center;
  font-size: 24px;
  line-height: 34px;
}

.checkbox-container {
  margin-top: 42px;
}
</style>

<script>
import shared from "@/shared";
import * as basicLightbox from "basiclightbox";
import CreateNftHeader from "@/components/CreateNftHeader.vue";
import NftCard from "@/components/NftCard.vue";
import PlusIcon from "@/assets/images/plus.svg";
import WhiteXIcon from "@/assets/images/white-x.svg";

export default {
  components: {
    CreateNftHeader,
    NftCard,
    PlusIcon,
    WhiteXIcon,
  },
  data() {
    return {
      tagsRawString: "",
      displayNameError: false,
      displayDescError: false,
      displayTagsError: false,
    };
  },
  computed: {
    form() {
      return this.$store.state.simpleSetForms[0];
    },
    nft() {
      let name = "Title";
      if (this.form.name) {
        name = this.form.name;
      }

      return {
        metadata: {
          name: name,
          image: this.form.blobUrl,
        },
        ethForSale: true,
        ethPrice: 1e18,
      };
    },
    username() {
      if (this.$store.state.user) return this.$store.state.user.username;
      return "";
    },
    displayFormError() {
      const extraPropertiesError = this.propNameError.filter((x) => x).length > 0;
      return extraPropertiesError || this.displayNameError || this.displayDescError || this.displayTagsError;
    },
    formError() {
      const extraPropertiesError = this.propNameError.filter((x) => x).length > 0;

      if ((this.displayNameError && this.nameError) || (this.displayDescError && this.descError) || (this.displayTagsError && this.tagsError) || extraPropertiesError) {
        return "Fix errors in the form before continuing.";
      }

      return null;
    },
    nameError() {
      if (this.name == "") return "Name cannot be empty";

      return null;
    },
    descError() {
      if (this.description == "") return "Description cannot be empty";

      return null;
    },
    tagsError() {
      if (this.tagsArray) {
        const tagsWithSpaces = this.tagsArray.filter((t) => t.includes(" "));

        if (tagsWithSpaces.length > 0) return "Tags cannot have spaces";
        if (this.tagsArray.length > 15) return "Too many tags";
      }

      return null;
    },
    propNameError() {
      let propNameError = new Array(this.properties.length);
      const names = this.properties.map((x) => x.name);

      for (let i = 0; i < this.properties.length; i++) {
        if (shared.propertyNameBlacklist.includes(this.properties[i].name)) {
          propNameError[i] = `Name '${this.properties[i].name}' not permitted`;
        }

        if (names.filter((x) => x == this.properties[i].name).length > 1) {
          if (this.properties[i].name) propNameError[i] = `Name already exists`;
        }

        if (this.properties.length > 100) {
          propNameError[i] = `Too many properties! (max 100)`;
        }
      }

      return propNameError;
    },
    name: {
      get() {
        return this.form.name;
      },
      set(value) {
        this.$store.commit("updateSimpleSetFormField", { index: 0, field: "name", value });
      },
    },
    description: {
      get() {
        return this.form.description;
      },
      set(value) {
        this.$store.commit("updateSimpleSetFormField", { index: 0, field: "description", value });
      },
    },
    tags: {
      get() {
        return this.tagsRawString;
      },
      set(value) {
        // first set tagsRawString
        this.tagsRawString = value;

        // then clean and set tagsArray
        let _tagsArray = this.tags.split(",");
        _tagsArray = _tagsArray.map((t) => t.trim());
        _tagsArray = _tagsArray.filter((t) => t); // remove empty

        this.tagsArray = _tagsArray;
      },
    },
    tagsArray: {
      get() {
        return this.form.tags;
      },
      set(value) {
        this.$store.commit("updateSimpleSetFormField", { index: 0, field: "tags", value });
      },
    },
    properties: {
      get() {
        return this.$store.state.simpleSetForms[0].extra;
      },
      set(value) {
        //console.log("comitting updated value: " + JSON.stringify(value));
        this.$store.commit("updateSimpleSetFormField", { index: 0, field: "extra", value });
      },
    },
    adult: {
      get() {
        return this.form.adult;
      },
      set(value) {
        this.$store.commit("updateSimpleSetFormField", { index: 0, field: "adult", value });
      },
    },
  },
  methods: {
    appendProperty() {
      this.$store.commit("addSimpleSetFormProperty", { index: 0, name: "", value: "" });
    },
    removeProperty(idx) {
      this.properties.splice(idx, 1);
    },
    continueButton() {
      const notValid = this.nameError || this.descError || this.tagsError;

      if (notValid) {
        // they tried to click continue but the form isn't ready- show all the errors
        this.displayNameError = true;
        this.displayDescError = true;
        this.displayTagsError = true;
      } else {
        this.$router.push("/createnft/setprice");
      }
    },
    showImage() {
      this.lightbox = basicLightbox.create(`<img id="full-image" src="${this.form.blobUrl}"/>`);
      this.lightbox.show();
    },
  },
};
</script>
