<template>
  <section class="featured">
    <router-link :to="link" class="image-link">
      <img class="image" v-bind:src="image" />
    </router-link>
    <div class="featured-content">
      <div class="featured-content-inner">
        <UserLink :name="creatorName" :address="store.creator" :verified="verified" />

        <router-link :to="link"
          ><h1>{{ title }}</h1></router-link
        >

        <p class="price-title">Current price</p>

        <Price class="price-crypto" v-if="nft.ethForSale" :price="nft.ethPrice" symbol="UBQ" />
        <Price class="price-crypto" v-else-if="nft.tokenForSale" :price="nft.tokenPrice" symbol="GRANS" />
        <span class="price-crypto" v-else>Not for sale</span>

        <p v-if="priceUsd" class="price-usd">${{ (priceUsd / 1e18).toFixed(2) }}</p>

        <div class="button-container">
          <router-link class="buy-button button-square-accent" :to="link" v-if="nft.ethForSale || nft.tokenForSale">Buy Now</router-link>
          <router-link class="view-button button-square-white" :to="link">View Artwork</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.featured {
  display: flex;
  max-width: 1000px;
  margin: auto;
  justify-content: center;
}

.featured .image-link {
  margin-right: 40px;
}

.featured a.image-link {
  display: flex;
  align-items: center;
}

.featured img {
  border-radius: 16px;
  max-height: 500px;
  max-width: 500px;
}

.featured .featured-content {
  margin-top: 28px;
  margin-left: 40px;
  display: flex;
  align-items: center;
}

.featured .featured-content-inner {
  max-width: 100%;
}

.featured h1 {
  margin-top: 20px;
  margin-bottom: 40px;
  overflow-wrap: break-word;
}

.featured .price-title {
  margin-bottom: 8px;
  font-size: 16px;
  text-transform: uppercase;
}

.featured .price-crypto {
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
}

.featured .price-usd {
  margin-top: 14px;
  color: var(--offwhite);
}

.featured .button-container {
  margin-top: 40px;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  font-weight: 600;
}

.featured .buy-button {
  margin-left: 0;
  margin-right: 24px;
}
</style>

<script>
import shared from "../shared";
import UserLink from "./UserLink.vue";
import Price from "./Price.vue";

export default {
  props: ["nft"],
  components: {
    UserLink,
    Price,
  },
  data() {
    return {
      ethPrice: 0,
      tokenPrice: 0,
      verified: false,
    };
  },
  async created() {
    const price = await shared.getPrice(this.$apiBase);
    this.ethPrice = price.ubiqUsdRatio;
    this.tokenPrice = price.ubiqUsdRatio / price.ubiqGransRatio;
  },
  computed: {
    title() {
      if (this.nft.metadata) return this.nft.metadata.name;
      else return "untitled";
    },
    set() {
      return this.nft !== null && this.nft.cnt > 1;
    },
    link() {
      if (this.nft && this.nft.store) {
        if (this.set) {
          return { name: "NFT", params: { collection: this.nft.store, nft: this.nft.metaId } };
        } else {
          return { name: "NFT", params: { collection: this.nft.store, nft: this.nft.nftId } };
        }
      }

      return null;
    },
    image() {
      let src;

      if (this.nft.metadata) {
        if (this.nft.metadata.preview) {
          src = this.nft.metadata.preview;
        } else {
          src = this.nft.metadata.image;
        }
      } else {
        src = "/question.png";
      }

      if (src.startsWith("ipfs") || src.startsWith("Qm")) {
        src = shared.formatIpfsLink(this.$ipfsPrefix, src);
      }

      return src;
    },
    priceUsd() {
      if (this.nft.ethForSale) {
        return this.ethPrice * this.nft.ethPrice;
      } else if (this.nft.tokenForSale) {
        return this.tokenPrice * this.nft.tokenPrice;
      }

      return null;
    },
  },
  asyncComputed: {
    store: {
      async get() {
        return await shared.fetchJson(`${this.$apiBase}/api/store/${this.nft.store}`);
      },
      default: {
        address: "0x0000000000000000000000000000000000000000",
        name: "Nothing",
        symbol: "NTHNG",
        creator: "0x0000000000000000000000000000000000000000",
      },
    },
    creatorName: {
      async get() {
        if (this.store.creator == "0x0000000000000000000000000000000000000000") return null;

        try {
          const u = await shared.fetchJson(`${this.$apiBase}/api/user/${this.store.creator}`);
          this.verified = u.verified;
          return u ? u.username : null;
        } catch {
          return null;
        }
      },
      default: "Loading...",
    },
  },
};
</script>
