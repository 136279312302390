<template>
  <div class="container">
    <CreateNftHeader h1="Set a price for your NFT." h2="Choose which currency you prefer — then set a price that collectors will find enticing." :step-current="4" :step-max="5" />

    <div class="details-container">
      <div class="details-form">
        <label for="input-currency"><h2 class="subheading">What currency are you accepting?</h2></label>
        <select id="input-currency" name="currency" v-model="currency">
          <option value="Ubiq" selected>Ubiq</option>
          <option value="10grans">10grans</option>
        </select>

        <label for="input-price"><h2 class="subheading">Price</h2></label>
        <input type="number" step="any" id="input-price" name="price" v-model="price" placeholder="How much will one copy sell for?" />
        <div class="current-rate">
          <p class="current-rate" v-if="currency == '10grans'">Current exchange rate: 1 GRANS = ${{ roundTo2Decimals(tokenPrice) }}</p>
          <p class="current-rate" v-else>Current exchange rate: 1 UBQ = ${{ roundTo2Decimals(ethPrice) }}</p>
          <p>
            When one copy sells you'll receive <strong> {{ priceFormatted }} {{ currencySymbol }} (${{ priceUSD }}).</strong>
          </p>
          <p class="current-rate-royalty">You will also earn an <strong>8% royalty</strong> on all resales.</p>
        </div>

        <div v-if="showCount">
          <label for="input-count"><h2 class="subheading">Number of copies</h2></label>
          <input
            type="number"
            min="1"
            max="50"
            step="1"
            id="input-count"
            name="count"
            v-model="count"
            placeholder="How many will be available to buy?"
            @focus="displayFormError = true"
          />
          <p class="count-description">Up to 50 copies</p>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="for-sale" name="for-sale" v-model="forSale" />
          <label for="for-sale">Put this NFT on sale immediately?</label>
        </div>

        <button type="button" class="button button-round-accent" :class="{ disabled: displayFormError && countError }" id="continue-button" @click="continueButton">
          Continue
        </button>
        <span v-if="displayFormError && countError" class="field-error">Set the number of copies before continuing.</span>
      </div>

      <aside class="details-preview">
        <h2 class="subheading">Preview</h2>
        <div class="nft-card-wrapper" @click="showImage">
          <NftCard :nft="nft" :creator-name-override="username" :favorites-override="'1'" :quantity-override="count" />
        </div>
      </aside>
    </div>
  </div>
</template>

<style scoped>
.details-container {
  display: flex;
}

.details-form {
  width: 42%;
  display: flex;
  flex-direction: column;
}

.details-preview {
  width: 25%;
  margin: 0 auto;
}

select {
  margin-bottom: 40px;
}

input {
  width: 100%;
}

.current-rate {
  margin-top: 16px;
  margin-bottom: 30px;
}

.current-rate-royalty {
  margin-top: 12px;
}

.count-description {
  margin-top: 16px;
}

.checkbox-container {
  margin-top: 58px;
}

#continue-button {
  margin-top: 56px;
  text-align: center;
  font-size: 24px;
  line-height: 34px;
}

.nft-card-wrapper {
  cursor: pointer;
}

.nft-card {
  pointer-events: none;
}

.field-error {
  color: var(--red);
  text-align: center;
}
</style>

<script>
import shared from "@/shared";
import * as basicLightbox from "basiclightbox";
import CreateNftHeader from "@/components/CreateNftHeader.vue";
import NftCard from "@/components/NftCard.vue";

export default {
  components: {
    CreateNftHeader,
    NftCard,
  },
  data: function() {
    return {
      displayFormError: false,
      selectedCurrency: "Ubiq",
      ethPrice: 0,
      tokenPrice: 0,
      rawPriceStr: "",
    };
  },
  async created() {
    const price = await shared.getPrice(this.$apiBase);
    this.ethPrice = price.ubiqUsdRatio;
    this.tokenPrice = price.ubiqUsdRatio / price.ubiqGransRatio;

    if (!this.showCount) {
      this.count = 1;
    }
  },
  computed: {
    form() {
      return this.$store.state.simpleSetForms[0];
    },
    nft() {
      let nft = {
        metadata: {
          name: this.form.name,
          image: this.form.blobUrl,
        },
      };

      if (this.currency == "Ubiq") {
        nft.ethPrice = this.price * 10e17;
        nft.ethForSale = this.forSale;
        nft.tokenPrice = 0;
        nft.tokenForSale = false;
      } else if (this.currency == "10grans") {
        nft.ethPrice = 0;
        nft.ethForSale = false;
        nft.tokenPrice = this.price * 10e17;
        nft.tokenForSale = this.forSale;
      }

      return nft;
    },
    username() {
      if (this.$store.state.user) return this.$store.state.user.username;
      return "";
    },
    currency: {
      get() {
        return this.selectedCurrency;
      },
      set(value) {
        // swap price values when changing currency
        this.swapPrices();

        // swap state of checkbox
        if (this.forSale) {
          let payload = this.form.prices;
          payload.ethForSale = false;
          payload.tokenForSale = false;

          if (value == "Ubiq") {
            payload.ethForSale = true;
          } else if (value == "10grans") {
            payload.tokenForSale = true;
          }
          this.$store.commit("updateSimpleSetFormField", { index: 0, field: "prices", value: payload });
        }

        this.selectedCurrency = value;
      },
    },
    currencySymbol: {
      get() {
        if (this.currency == "10grans") return "GRANS";
        return "UBQ";
      },
    },
    priceFormatted: {
      get() {
        if (!this.price) {
          return 0;
        }
        return shared.formatPrice(this.price * 1e18);
      },
    },
    priceUSD: {
      get() {
        if (this.currency == "10grans") {
          return this.roundTo2Decimals(this.price * this.tokenPrice);
        }
        return this.roundTo2Decimals(this.price * this.ethPrice);
      },
    },
    price: {
      get() {
        return this.rawPriceStr;
      },
      set(value) {
        this.rawPriceStr = value;
        this.forSale = true; // set forSale=true when setting the price

        let payload = this.form.prices;
        if (this.currency == "Ubiq") payload.ethPrice = Number(value);
        else if (this.currency == "10grans") payload.tokenPrice = Number(value);
        this.$store.commit("updateSimpleSetFormField", { index: 0, field: "prices", value: payload });
      },
    },
    forSale: {
      get() {
        if (this.currency == "Ubiq") return this.form.prices.ethForSale;
        else if (this.currency == "10grans") return this.form.prices.tokenForSale;
        return null;
      },
      set(value) {
        let payload = this.form.prices;
        payload.ethForSale = false;
        payload.tokenForSale = false;

        if (this.currency == "Ubiq") {
          payload.ethForSale = value;
        } else if (this.currency == "10grans") {
          payload.tokenForSale = value;
        }
        this.$store.commit("updateSimpleSetFormField", { index: 0, field: "prices", value: payload });
      },
    },
    showCount: {
      get() {
        return !!this.$store.state.showCount;
      },
    },
    countError() {
      return !Number.isInteger(this.count) || this.count < 1 || this.count > 50;
    },
    count: {
      get() {
        return this.form.count;
      },
      set(value) {
        this.$store.commit("updateSimpleSetFormField", { index: 0, field: "count", value: Number(value) });
      },
    },
  },
  methods: {
    swapPrices() {
      let payload = this.form.prices;
      const tmp = payload.tokenPrice;
      payload.tokenPrice = payload.ethPrice;
      payload.ethPrice = tmp;
      this.$store.commit("updateSimpleSetFormField", { index: 0, field: "prices", value: payload });
    },
    roundTo2Decimals(value) {
      return (Math.round(value * 100) / 100).toFixed(2);
    },
    continueButton() {
      const notValid = this.countError;

      if (notValid) {
        // they tried to click continue but the form isn't ready- show all the errors
        this.displayFormError = true;
      } else {
        this.$router.push("/createnft/selectcollection");
      }
    },
    showImage() {
      this.lightbox = basicLightbox.create(`<img id="full-image" src="${this.form.blobUrl}"/>`);
      this.lightbox.show();
    },
  },
};
</script>
