<template>
  <div class="container notifications-page">
    <h1>All Notifications</h1>
    <Notifications :page="page" />
  </div>
</template>

<style scoped>
.notifications-page {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

h1 {
  font-size: 48px;
  line-height: 58px;
  font-weight: 800;
}
</style>

<script>
import Notifications from "@/components/Notifications.vue";

export default {
  components: {
    Notifications,
  },
  computed: {
    page() {
      if (this.$route.params.page) {
        return Number(this.$route.params.page);
      } else {
        return 1;
      }
    },
  },
};
</script>
