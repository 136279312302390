import { render, staticRenderFns } from "./FeaturedCard.vue?vue&type=template&id=79df0344&scoped=true&"
import script from "./FeaturedCard.vue?vue&type=script&lang=js&"
export * from "./FeaturedCard.vue?vue&type=script&lang=js&"
import style0 from "./FeaturedCard.vue?vue&type=style&index=0&id=79df0344&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79df0344",
  null
  
)

export default component.exports