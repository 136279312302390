<template>
  <div>
    <!--<h2>August 2021</h2>-->

    <h3 v-if="altMessage" class="alt-message">{{ altMessage }}</h3>

    <article v-for="(entry, index) of entries" :key="index" class="notifications-page-entry">
      {{ /*entry*/ }}

      <div v-if="entry.type == 'Comment'" class="content-container">
        <abbr class="time" :title="absoluteTime(+new Date(entry.date))">{{ relativeTime(+new Date(entry.date)) }}</abbr>
        <h3>
          <UserLink :name="userData[entry.event.author].name" :address="entry.event.author" :verified="userData[entry.event.author].verified" :accent="true" /> said "<Ellipsis
            :maxLength="40"
            :content="entry.event.content"
          />"
        </h3>
      </div>

      <div v-else-if="entry.type == 'BuySingleNft' || entry.type == 'TokenBuySingleNft'" class="content-container">
        <abbr class="time" :title="absoluteTime(+new Date(entry.date))">{{ relativeTime(+new Date(entry.date)) }}</abbr>
        <h3>{{ entry.metadata.name }}</h3>
        <p class="entry-description">Sold for <Price :price="entry.event.price" :symbol="entry.type == 'BuySingleNft' ? 'UBQ' : 'GRANS'" customFreeMessage="0 (free!)" /></p>
      </div>

      <div v-else-if="entry.type == 'Resale' || entry.type == 'TokenResale'" class="content-container">
        <abbr class="time" :title="absoluteTime(+new Date(entry.date))">{{ relativeTime(+new Date(entry.date)) }}</abbr>
        <h3>{{ entry.metadata.name }}</h3>
        <p class="entry-description">
          Resold by <UserLink :name="userData[entry.event.from].name" :address="entry.event.from" :verified="userData[entry.event.from].verified" :accent="true" /> for
          <Price :price="entry.event.price" :symbol="entry.type == 'Resale' ? 'UBQ' : 'GRANS'" customFreeMessage="0 (free!)" />
        </p>
      </div>

      <div class="button-container">
        <button v-if="entry.event.id" @click="goToNft({ collection: entry.store, nft: entry.event.id })" class="button button-round-outline view-nft">View</button>
        <button v-else @click="goToNft({ collection: entry.store, nft: entry.event.meta_id })" class="button button-round-outline view-nft">View</button>
      </div>
    </article>

    <div v-if="!page">
      <button @click="goToNotificationsPage" class="button button-round-accent view-more">View all notifications</button>
    </div>
    <div class="pagination" v-else>
      <button :class="{ disabled: page <= 1 }" @click="decrementPage" class="button button-round-accent view-more">← Prev Page</button>
      <button :class="{ disabled: !nextPageAvailable }" @click="incrementPage" class="button button-round-accent view-more">Next Page →</button>
    </div>
  </div>
</template>

<style scoped>
h2 {
  color: var(--offwhite);
  font-size: 24px;
  line-height: 34px;
  font-weight: 400;
  padding-bottom: 16px;
  border-bottom: var(--border-soft);
  margin-bottom: 24px;
}

h3.alt-message {
  font-weight: 600;
  color: var(--white);
}

.light h3.alt-message {
  color: var(--black);
}

.notifications-page-entry {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eff0f7;
}

.notifications-page-entry:first-child {
  padding-top: 0;
}

.notifications-page-entry:last-child {
  padding-bottom: 0;
}

.content-container {
  display: flex;
  flex-direction: column;
}

h3 {
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: 700;
}

.time {
  text-transform: uppercase;
  color: var(--gray-label);
}

.entry-description {
  text-transform: uppercase;
  margin: 0;
}

.view-more {
  margin-top: 24px;
  margin-bottom: 0;
}

.pagination {
  display: flex;
  justify-content: space-between;
}

.light .view-nft:hover {
  background-color: #ffffff;
}

.view-nft:hover {
  background-color: #303030;
}

.light {
  color: var(--black);
}

.light .entry-description {
  color: var(--card-background);
}

.light .button.button-round-outline {
  color: var(--black);
  box-shadow: 0 0 0 1pt var(--black);
}
</style>

<script>
import shared from "@/shared";
import Price from "@/components/Price.vue";
import Ellipsis from "@/components/Ellipsis.vue";
import UserLink from "@/components/UserLink.vue";

export default {
  components: {
    Price,
    Ellipsis,
    UserLink,
  },
  props: ["page"],
  data() {
    return {
      altMessage: "",
      nextPageAvailable: false,
      userCache: {},
      userData: {},
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  asyncComputed: {
    entries: {
      async get() {
        this.altMessage = "Loading...";
        const resultsPerPage = 20;

        let pageClause = `page=1`;
        if (this.page) {
          pageClause = `page=${this.page}&resultsPerPage=${resultsPerPage}`;
        }

        let res;
        try {
          res = await shared.fetchJson(`${this.$apiBase}/api/notifications/${this.user.id}?${pageClause}`);
        } catch (err) {
          console.error("Unable to fetch notifications", err);
          this.altMessage = "Error: Unable to fetch notifications.";
          return [];
        }

        // for comments and resales, look up author's name, populate this.userData
        for (let i = 0; i < res.length; i++) {
          let addr;

          if (res[i].type == "Comment") {
            addr = res[i].event.author;
          } else if (res[i].type == "Resale" || res[i].type == "TokenResale") {
            addr = res[i].event.from;
          }

          if (addr) {
            const user = await this.fetchUser(addr);
            this.userData[addr] = {
              name: user.username,
              verified: user.verified,
            };
          }
        }

        // asynchronously fire off a lookahead query to see if there is another page available
        if (this.page) {
          (async () => {
            const nextPageClause = `page=${this.page + 1}&resultsPerPage=${resultsPerPage}`;
            const next = await shared.fetchJson(`${this.$apiBase}/api/notifications/${this.user.id}?${nextPageClause}`);
            this.nextPageAvailable = next.length > 0;
          })();
        }

        //console.log("fetched notifications: " + JSON.stringify(res, null, 2));
        if (res.length == 0) {
          this.altMessage = "No notifications to show.";
        } else {
          this.altMessage = "";
        }
        return res;
      },
      default: [],
    },
  },
  methods: {
    goToNft(params) {
      if (this.$parent.hideNotifications) this.$parent.hideNotifications();
      this.$router.push({ name: "NFT", params: params });
    },

    goToNotificationsPage() {
      if (this.$parent.hideNotifications) this.$parent.hideNotifications();
      this.$router.push("/notifications");
    },
    decrementPage() {
      this.$router.push(`/notifications/page/${this.page - 1}`);
    },
    incrementPage() {
      this.$router.push(`/notifications/page/${this.page + 1}`);
    },
    relativeTime(timestamp) {
      return shared.relativeTime(timestamp);
    },
    absoluteTime(timestamp) {
      return shared.absoluteTime(timestamp);
    },
    async fetchUser(address) {
      if (!(address in this.userCache)) {
        const userFetched = await shared.fetchJson(`${this.$apiBase}/api/user/${address}`);
        this.userCache[address] = userFetched;
      }

      return this.userCache[address];
    },
  },
};
</script>
