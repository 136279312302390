import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Terms from "../views/Terms.vue";
import PrivacyPolicy from "../views/Privacy.vue";
import Collection from "../views/Collection.vue";
import Nft from "../views/NftV3.vue";
import User from "../views/Settings.vue";
import CreateNftGettingStarted from "../views/CreateNft/GettingStarted.vue";
import CreateNftUpload from "../views/CreateNft/Upload.vue";
import CreateNftDetails from "../views/CreateNft/Details.vue";
import CreateNftSetPrice from "../views/CreateNft/SetPrice.vue";
import CreateNftSelectCollection from "../views/CreateNft/SelectCollection.vue";
import CreateNftConfirm from "../views/CreateNft/Confirm.vue";
import CreateNftMint from "../views/CreateNft/Mint.vue";
import Address from "../views/Address.vue";
import NotificationsView from "../views/NotificationsView.vue";
import PageNotFound from "../views/PageNotFound";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    component: PageNotFound,
    meta: {
      title: "404 page not found",
    },
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "token gallery",
    },
  },
  {
    path: "/page/:page",
    name: "Home",
    component: Home,
    meta: {
      title: "token gallery",
    },
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      title: "token gallery",
    },
  },
  {
    path: "/privacy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: {
      title: "token gallery – privacy policy",
    },
  },
  {
    path: "/terms",
    name: "TermsOfService",
    component: Terms,
    meta: {
      title: "token gallery – terms of service",
    },
  },
  {
    name: "Collection",
    path: "/collection/:collection",
    component: Collection,
    meta: {
      title: "token gallery – collection",
    },
  },
  {
    name: "Collection",
    path: "/collection/:collection/page/:page",
    component: Collection,
    meta: {
      title: "token gallery - collection",
    },
  },
  {
    name: "NFT",
    path: "/collection/:collection/nft/:nft",
    component: Nft,
    meta: {
      title: "token gallery – nft",
    },
  },
  {
    name: "Settings",
    path: "/settings",
    component: User,
    meta: {
      title: "token gallery – user settings",
    },
  },
  {
    name: "Create NFT",
    path: "/createnft",
    component: CreateNftGettingStarted,
    meta: {
      title: "token gallery – create nft",
    },
  },
  {
    path: "/createnft/upload",
    component: CreateNftUpload,
    meta: {
      title: "token gallery – create nft – Upload",
    },
  },
  {
    path: "/createnft/details",
    component: CreateNftDetails,
    meta: {
      title: "token gallery – create nft – Enter Details",
    },
  },
  {
    path: "/createnft/setprice",
    component: CreateNftSetPrice,
    meta: {
      title: "token gallery – create nft – Set Price",
    },
  },
  {
    path: "/createnft/selectcollection",
    component: CreateNftSelectCollection,
    meta: {
      title: "token gallery – create nft – Select a Collection",
    },
  },
  {
    path: "/createnft/confirm",
    component: CreateNftConfirm,
    meta: {
      title: "token gallery – create nft – Confirm Creation",
    },
  },
  {
    path: "/createnft/mint",
    component: CreateNftMint,
    meta: {
      title: "token gallery – create nft – Minting NFT...",
    },
  },
  {
    name: "Address",
    path: "/address/:address",
    component: Address,
    meta: {
      title: "token gallery - user",
    },
  },
  {
    name: "Address",
    path: "/address/:address/tab/:tab",
    component: Address,
    meta: {
      title: "token gallery - user",
    },
  },
  {
    name: "Address",
    path: "/address/:address/tab/:tab/page/:page",
    component: Address,
    meta: {
      title: "token gallery - user",
    },
  },
  {
    name: "Notifications",
    path: "/notifications",
    component: NotificationsView,
    meta: {
      title: "token gallery - notifications",
    },
  },

  {
    name: "Notifications",
    path: "/notifications/page/:page",
    component: NotificationsView,
    meta: {
      title: "token gallery - notifications",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  next();
});

export default router;
