<template>
  <div class="nft-user">
    <router-link v-if="addressComputed !== burned" :to="{ name: 'Address', params: { address: nameComputed } }" class="user-name" :class="{ accent: accent }">
      <span>@{{ nameComputed }}</span>
      <VerifiedIcon class="verified-user" v-if="verified" />
    </router-link>
    <span v-else class="user-name">
      {{ nameComputed }}
    </span>
  </div>
</template>

<style scoped>
.nft-user {
  display: inline-flex;
  max-width: 100%;
  white-space: nowrap;
  vertical-align: middle;
}

.nft-user img {
  border-radius: 100%;
  width: 26px;
  margin-right: 10px;
}

.nft-user .user-name {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--white);
  max-width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  line-height: 23px;
}

.nft-user .user-name.accent {
  color: var(--accent);
  font-weight: 600;
}

.nft-user .user-name .verified-user {
  max-height: 16px;
  margin-left: 4px;
  min-width: 16px;
}
</style>

<script>
import VerifiedIcon from "@/assets/images/verified.svg";

export default {
  components: {
    VerifiedIcon,
  },
  props: ["name", "address", "accent", "verified"],
  data() {
    return {
      burned: "0x0000000000000000000000000000000000000000",
    };
  },
  computed: {
    addressComputed() {
      if (!this.address) {
        return "0x0000000000000000000000000000000000000000";
      }
      return this.address;
    },
    nameComputed() {
      if (!this.name) {
        // if empty name provided, the user is probably not registered. show address instead
        return this.addressComputed;
      }
      return this.name;
    },
  },
};
</script>
