import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { getField, updateField } from "vuex-map-fields";
import { v4 as uuidv4 } from "uuid";

Vue.use(Vuex);

const clone = (obj) => {
  if (null == obj || "object" != typeof obj) {
    console.log(`I can't clone this: ${typeof obj}`);
    return obj;
  }
  const isArray = Array.isArray(obj);
  const copy = isArray ? [] : {}; // does this even matter
  const attrs = Object.getOwnPropertyNames(obj);
  console.log(`Object has attributes: ${attrs}`);
  for (let attr of attrs) {
    if (attr === "__ob__") {
      console.log("Ignoring circular reference.");
    } else if (isArray && attr === "length") {
      console.log("Ignoring array length");
    } else if (Object.prototype.hasOwnProperty.call(obj, attr)) {
      if (obj[attr] instanceof Object) {
        copy[attr] = clone(obj[attr]);
      } else {
        console.log(`copying ${attr}`);
        copy[attr] = obj[attr];
      }
    }
  }
  return copy;
};

export default new Vuex.Store({
  state: {
    user: null,
    accounts: [],
    showUnverified: false,
    showAdult: false,
    batchCreateNftForm: [],
    batchCreateNftImages: {},
    simpleSetForms: [],
    simpleSetIsCollection: "false",
    simpleSetName: "",
    latestForSale: false,
    showSiteMessage: true,
    latestCurrency: "any",
    ethPrice: 0,
    ethForSale: false,
    tokenPrice: 0,
    tokenForSale: false,
    showCount: null,
    lastNotificationCheckDate: "",
  },
  mutations: {
    clearPrices(state) {
      state.ethPrice = null;
      state.ethForSale = false;
      state.tokenPrice = null;
      state.tokenForSale = false;
    },
    updateUbiqPrice(state, price) {
      state.ethPrice = price;
    },
    updateUbiqForSale(state, forSale) {
      state.ethForSale = forSale;
    },
    updateTokenPrice(state, price) {
      state.tokenPrice = price;
    },
    updateTokenForSale(state, forSale) {
      state.tokenForSale = forSale;
    },
    updateUser(state, user) {
      state.user = user;
    },
    clearUser(state) {
      state.user = null;
    },
    updateAccounts(state, accounts) {
      state.accounts = accounts;
    },
    updateLatestForSale(state, showOnlyForSale) {
      state.latestForSale = showOnlyForSale;
    },
    updateLastNotificationCheckDate(state, date) {
      state.lastNotificationCheckDate = date;
    },
    updateShowSiteMessage(state, showSiteMessage) {
      state.showSiteMessage = showSiteMessage;
    },
    updateLatestCurrency(state, currency) {
      state.latestCurrency = currency;
    },
    updateShowUnverified(state, showUnverified) {
      state.showUnverified = showUnverified;
    },
    updateShowAdult(state, showAdult) {
      state.showAdult = showAdult;
    },
    updateField,
    updateSimpleSetName(state, name) {
      state.simpleSetName = name;
    },
    updateSimpleSetIsCollection(state, isCollection) {
      state.simpleSetIsCollection = isCollection;
    },
    swapSimpleSetForms(state, p) {
      const form1 = state.simpleSetForms[p.index1];
      const id1 = form1.id;
      const newId1 = uuidv4(); // temporary new id so no clash on insert

      const form2 = state.simpleSetForms[p.index2];
      const id2 = form2.id;
      const newId2 = uuidv4(); // temporary new id so no clash on insert

      console.log(`Swapping ${p.index1} and ${p.index2}.`);
      const clone1 = clone(form1);
      clone1.id = newId1;
      const clone2 = clone(form2);
      clone2.id = newId2;

      p.newId1 = newId1;
      p.newId2 = newId2;

      Vue.set(state.simpleSetForms, p.index2, clone1);
      Vue.set(state.simpleSetForms, p.index1, clone2);

      // put the old ids back.
      clone1.id = id1;
      clone2.id = id2;
    },
    updateSimpleSetFormBlobUrl(state, p) {
      Vue.set(state.simpleSetForms[p.index], "blobUrl", p.value);
    },
    updateSimpleSetFormField(state, payload) {
      Vue.set(state.simpleSetForms[payload.index], payload.field, payload.value);
    },
    addSimpleSetForm(state, p) {
      p.newId = uuidv4();
      state.simpleSetForms.push({
        id: p.newId,
        name: "",
        description: "",
        adult: false,
        count: "",
        collection: null,
        extra: [], // this is merged into the regular form's properties before upload
        prices: {
          ethPrice: "",
          ethForSale: false,
          tokenPrice: "",
          tokenForSale: false,
        },
      });
    },
    removeSimpleSetForm(state, index) {
      const imageId = `image/${state.simpleSetForms[index].id}`;
      Vue.prototype.$removeItem(imageId).catch();
      Vue.delete(state.simpleSetForms, index);
    },
    clearSimpleSetForms(state) {
      for (let i = state.simpleSetForms.length - 1; i >= 0; --i) {
        const imageId = `image/${state.simpleSetForms[i].id}`;
        Vue.prototype.$removeItem(imageId).catch();
        Vue.delete(state.simpleSetForms, i);
      }

      state.simpleSetIsCollection = false;
      state.simpleSetName = "";
    },
    addSimpleSetFormProperty(state, p) {
      const index = p.index; // index of the form not the item
      const name = p.name;
      const value = p.value;
      //const extraLength = state.simpleSetForms[index].extra.length;
      // FIXME: reject if already exists
      //Vue.set(state.simpleSetForms[index].extra, extraLength, { name, value });
      state.simpleSetForms[index].extra.push({ name, value });
    },
    removeSimpleSetFormProperty(state, p) {
      const index = p.index; // index of form, not item in form
      const subscript = p.subscript; // index of property in form
      Vue.delete(state.simpleSetForms[index].extra, subscript);
    },
    /*
    addBatchNft(state) {
      state.batchCreateNftForm.push({
        id: uuidv4(),
        name: "",
        description: "",
        adult: false,
      });
    },
    */
    clearBatchCreateNftForm(state) {
      state.batchCreateNftForm = [];
    },
    clearBatchImages(state) {
      state.batchCreateNftImages = {};
    },
    deleteBatchNft(state, index) {
      state.batchCreateNftForm.splice(index, 1);
    },
    addBatchImage(state, obj) {
      Vue.set(state.batchCreateNftImages, obj.id, { name: obj.name, base64: obj.base64 });
    },
    deleteBatchImage(state, id) {
      delete state.batchCreateNftImages[id];
    },
    setShowCount(state, value) {
      state.showCount = !!value;
    },
  },
  getters: {
    getUser: (state) => {
      return state.user;
    },
    getAccounts: (state) => {
      return state.accounts;
    },
    isUserAuthenticated: (state) => {
      return state.user != null && state.user.token != null;
    },
    getField,
    batchFormEmpty: (state) => {
      return state.batchCreateNftForm.length == 0;
    },
    lastSimpleSetForm: (state) => {
      return state.simpleSetForms.length == 0 ? null : state.simpleSetForms[state.simpleSetForms.length - 1];
    },
    firstSimpleSetForm: (state) => {
      return state.simpleSetForms.length == 0 ? null : state.simpleSetForms[0];
    },
  },
  plugins: [createPersistedState()],
});
