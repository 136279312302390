<template>
  <div class="container about">
    <header>
      <!--<h1>
        <img class="token-image" src="/about/header.gif" alt="TOKEN" />
      </h1>-->
      <h1>welcome to token.gallery — take a look around.</h1>
      <div class="icons-container">
        <div class="icon">
          <img src="/about/create.png" />
          <p>create</p>
        </div>
        <div class="icon">
          <img src="/about/sell.png" />
          <p>sell</p>
        </div>
        <div class="icon">
          <img src="/about/collect.png" />
          <p>collect</p>
        </div>
      </div>

      <ArrowDown class="arrow-down" alt="⬇" />
    </header>

    <div id="about-main">
      <section>
        <h3 class="title">What</h3>
        <p class="what">
          Token.gallery is an nft marketplace that supports and showcases creative work. You can create ("mint"), sell, buy, and collect NFTs that live on the blockchain.
        </p>
        <PageBreak class="page-break" />
      </section>

      <aside>
        <h3 class="title">Who</h3>
        <p>
          We are developers, artists, and collectors working together to make cool s#!t that explores what blockchain can do.
        </p>
      </aside>

      <section class="main-text">
        <h2>Things to know</h2>

        <ul class="faq">
          <li id="faq-1">
            <div class="li-header" @click="toggleItem">
              <h3 class="hoverable">How do I create an NFT?</h3>
              <EyeClosed class="open-close-icon hoverable" alt="Hide answer" v-if="showFaq['faq-1']" />
              <EyeOpen class="open-close-icon hoverable" alt="Show answer" v-else />
            </div>
            <div class="li-content" v-if="showFaq['faq-1']">
              <p>
                Each NFT is a unique, ownable, and collectable piece of the digital world — just like real meat-world physical collectibles such as Pokémon cards, Magic the
                Gathering, or sports memorabilia.
              </p>
              <h4>Follow this 4-step guide, friend:</h4>
              <ol>
                <li>
                  Connect your wallet. (Either <a class="link-accent" href="https://metamask.io/">Metamask</a> or
                  <a class="link-accent" href="https://ubiqsmart.com/sparrow">Sparrow</a> wallet)
                </li>
                <li>Click the create button at the top.</li>
                <li>Go through our cool ass 5-step minting process.</li>
                <li>Boom! Your NFT will be minted onto the Blockchain.</li>
                <li>From there you can sell it, share it, or whatever.</li>
              </ol>
            </div>
          </li>

          <li id="faq-2">
            <div class="li-header" @click="toggleItem">
              <h3 class="hoverable">Buying and collecting NFTs</h3>
              <EyeClosed class="open-close-icon hoverable" alt="Hide answer" v-if="showFaq['faq-2']" />
              <EyeOpen class="open-close-icon hoverable" alt="Show answer" v-else />
            </div>
            <div class="li-content" v-if="showFaq['faq-2']">
              <p>
                There are all kinds of people buying NFTs today. Some people buy to support their friends, others are art enthusiasts who simply love supporting artists, and then
                there's collectors. Collectors are those who find talent and bring it forward for the world to see.
              </p>

              <h4>Follow these steps to buy and collect NFTs:</h4>
              <ol>
                <li>
                  Connect your wallet. (Either <a class="link-accent" href="https://metamask.io/">Metamask</a> or
                  <a class="link-accent" href="https://ubiqsmart.com/sparrow">Sparrow</a> wallet)
                </li>
                <li>Find an NFT you like by searching, filtering, sorting, etc.</li>
                <li>Click the buy button.</li>
                <li>Make sure you have some UBQ or GRANS in your wallet.</li>
              </ol>
            </div>
          </li>

          <li id="faq-3">
            <div class="li-header" @click="toggleItem">
              <h3 class="hoverable">Fees and Royalties</h3>
              <EyeClosed class="open-close-icon hoverable" alt="Hide answer" v-if="showFaq['faq-3']" />
              <EyeOpen class="open-close-icon hoverable" alt="Show answer" v-else />
            </div>
            <div class="li-content" v-if="showFaq['faq-3']">
              <p>
                There are two types of fees on token.gallery:
              </p>
              <ul>
                <li>Artist royalty fee: <strong>8%</strong></li>
                <li>Marketplace fee: <strong>2%</strong></li>
              </ul>
              <p>
                Fees are automatically deducted from each purchase, <strong>including resales</strong>.
                <br />
                The 8% royalty fee will automatically show up in the creator's wallet.
              </p>
            </div>
          </li>

          <li id="faq-4">
            <div class="li-header" @click="toggleItem">
              <h3 class="hoverable">Token.gallery's unique technical implementation</h3>
              <EyeClosed class="open-close-icon hoverable" alt="Hide answer" v-if="showFaq['faq-4']" />
              <EyeOpen class="open-close-icon hoverable" alt="Show answer" v-else />
            </div>
            <div class="li-content" v-if="showFaq['faq-4']">
              <p>
                Token.gallery is different from other NFT stores. When you mint on a typical ethereum-based NFT service like
                <a class="link-accent" href="https://foundation.app/">Foundation</a> or <a class="link-accent" href="https://rarible.com/">Rarible</a>, you're creating a new entry
                in their ERC-1155 NFT contract.
              </p>
              <p>
                Token.gallery instead introduces the concept of a <strong>collection-</strong> each collection is an independent
                <a class="link-accent" href="https://eips.ethereum.org/EIPS/eip-1155">ERC-1155 contract</a>. Users can create multiple collections, and then mint NFTs independently
                within those collections.
              </p>

              <h4>Advantages</h4>
              <p>
                Having an independent contract per collection gives users greater control over their NFT creations. Users can transfer entire contract ownership, giving another
                person the royalty income from future sales.
              </p>

              <p>
                We've also made it easier to create NFT <strong>sets</strong>. A set is comprised of multiple <strong>editions</strong> of the same artwork. Each edition is
                nonfungible, marked with a number (1/10, 2/10, etc), and can be priced/sold/transferred independently.
              </p>
              <p>
                This allows artists to easily create multiple editions of the same artwork at once, instead of tediously creating each NFT in a separate transaction.
              </p>

              <h4>Why don't other services do this?</h4>
              <p>
                Creating contracts is a gas-expensive operation. This is cheap on the Ubiq blockchain, but would be prohibitively expensive on Ethereum. Ethereum services instead
                opt for one single ERC-1155 contract, or a less versatile ERC-721 contract.
              </p>
            </div>
          </li>
          <li id="faq-5">
            <div class="li-header" @click="toggleItem">
              <h3 class="hoverable">What is verification?</h3>
              <EyeClosed class="open-close-icon hoverable" alt="Hide answer" v-if="showFaq['faq-5']" />
              <EyeOpen class="open-close-icon hoverable" alt="Show answer" v-else />
            </div>
            <div class="li-content" v-if="showFaq['faq-5']">
              <p>
                Verification helps the community build trust between artists and collectors. Token.Gallery is an open platform that allows anyone to mint, buy, and trade NFTs on
                the Ubiq Network. However, because of the open nature of blockchain, it can be hard to validate the authenticity of new participants. Verification helps shine light
                on artists creating their own work and gives new users a sense of security. There are many content licenses for remixes, reworks, and adaptations and as
                Token.Gallery operates out of the United States - the platform must take these licenses seriously. All of these considerations combined is what goes into
                verification.
              </p>
              <p>Simply put, verification is proof that a person has engaged with the community, built trust, and is a known contributor to the platform.</p>
            </div>
          </li>
          <li id="faq-6">
            <div class="li-header" @click="toggleItem">
              <h3 class="hoverable">How do I get verified on token.gallery?</h3>
              <EyeClosed class="open-close-icon hoverable" alt="Hide answer" v-if="showFaq['faq-6']" />
              <EyeOpen class="open-close-icon hoverable" alt="Show answer" v-else />
            </div>
            <div class="li-content" v-if="showFaq['faq-6']">
              <p>
                Both artists and collectors can be verified. The new way to get verified is to
                <a class="link-accent" href="https://rwtb18kz14y.typeform.com/to/HkYq4Xt1">fill out this form</a>.
              </p>
              <br />
              <ol>
                <li>
                  <strong>We’re looking for multiple minted (or purchased - for collectors) pieces on token.gallery under your profile.</strong>
                  <br />
                  Minting allows us to see what you’re planning on showcasing or collecting on token.gallery and helps us verify that the artwork is original and owned by you.
                  Minting copyrighted work that you do not own or have rights to sell will disqualify you from being verified. If you are using secondary assets in your work please
                  provide the licenses in your application.
                </li>
                <li>
                  <strong>Link us 1 or 2 profiles you have online (other marketplaces, portfolios, or social media)</strong>
                  <br />
                  If you have anywhere you showcase your artwork, share it. If you’re a collector, show us your collection on other platforms. If you’ve never collected before,
                  check out the frontpage!
                </li>
                <li>
                  <strong>Share a short artist / collector statement.</strong>
                  <br />
                  Write a 2-5 sentence statement of your intent as an artist or collector. What are you exploring? What got you into NFTs? What do you want to share with the
                  community? Tell us about your art and why you make it. This can be anything you’d like, so long as you’re helping us understand your intentions.
                </li>
                <li>
                  <strong>Get involved in the token.gallery community.</strong>
                  <br />
                  Join our Discord or talk with us on Twitter. This helps a ton. The community behind token.gallery is half the fun of NFTs. Getting to know you is key to getting
                  verified.
                </li>
                <li>
                  <strong>Don’t message the devs/mods directly about your verification.</strong>
                  <br />
                  If you’ve filled out the form linked above, you can trust that we are reviewing your profile. Messaging us directly about your verification status will not speed
                  up the process. If you have not been verified within 2 weeks, your verification was denied and you should re-read the rules and requirements and re-apply.
                  Spamming multiple applications will automatically disqualify you from being verified and potentially lead to account suspension.
                </li>
              </ol>
            </div>
          </li>
          <li id="faq-7">
            <div class="li-header" @click="toggleItem">
              <h3 class="hoverable">Why wasn’t my profile verified?</h3>
              <EyeClosed class="open-close-icon hoverable" alt="Hide answer" v-if="showFaq['faq-7']" />
              <EyeOpen class="open-close-icon hoverable" alt="Show answer" v-else />
            </div>
            <div class="li-content" v-if="showFaq['faq-7']">
              <p>
                There are multiple reasons why your profile might not be verified. We’ll list the major ones below.
              </p>
              <br />
              <ol>
                <li><strong>You haven’t joined our Discord, and haven’t said anything, so we don’t know anything about you.</strong></li>
                <li><strong>You didn’t fill out the verification form completely.</strong></li>
                <li><strong>You’ve just recently joined and don’t have enough of a history with token.gallery for us to review and verify you with.</strong></li>
                <li><strong>You’ve minted copyrighted (without rights to resell) images in the past.</strong></li>
                <li>
                  <strong
                    >You’ve minted low effort “spammy” NFTs onto the gallery in a short amount of time. (This includes low effort meme images and low effort images with cheap
                    filters from image editing apps). The key phrase is “low effort”.</strong
                  >
                </li>
                <li>
                  <strong
                    >You’ve intentionally tried to deceive, trick, or manipulate collectors by minting copycat NFTs from other users on token.gallery or other marketplaces.</strong
                  >
                </li>
                <li>
                  <strong
                    >We may not feel that your profile meets our standards of verification based on quality, history, and other factors at the moment. You can always apply again
                    later.</strong
                  >
                </li>
                <li>
                  <strong
                    >You’ve messaged the mods directly about your verification. This actually slows us down. As more people ask, the more time we spend chatting instead of
                    reviewing and verifying.</strong
                  >
                </li>
              </ol>
            </div>
          </li>
          <li id="faq-8">
            <div class="li-header" @click="toggleItem">
              <h3 class="hoverable">How do I prevent my NFT from being delisted from token.gallery?</h3>
              <EyeClosed class="open-close-icon hoverable" alt="Hide answer" v-if="showFaq['faq-8']" />
              <EyeOpen class="open-close-icon hoverable" alt="Show answer" v-else />
            </div>
            <div class="li-content" v-if="showFaq['faq-8']">
              <p>This section is not legal advice. These are general tips to help you learn about posting on token.gallery successfully.</p>
              <br />
              <ol>
                <li>
                  <strong
                    >“Free” images and assets from the web are not automatically okay to sell. You must read the associated Terms of Use for any asset you find online or in the
                    meat-world. Free assets are often attached to licensing that does not allow resale.</strong
                  >
                </li>
                <li><strong>If you’re using purchased stock assets, always read the license related to resales.</strong></li>
                <li>
                  <strong
                    >If you’ve commissioned art from an artist for resale as an NFT, get their permission upfront. We also encourage you to credit the artist in the description of
                    your NFT when minting.</strong
                  >
                </li>
                <li>
                  <strong
                    >Never download images from blogs, websites, or other marketplaces and repost them on token.gallery if you don’t own the rights to resell them. You may face
                    legal repercussions from the owners for minting NFTs with copyrighted materials or IP.</strong
                  >
                </li>
                <li>
                  <strong
                    >As a collector, perform your due diligence and look into the artist, the description of the NFT, and do a quick reverse image search on your preferred search
                    engine.</strong
                  >
                </li>
              </ol>
              <br />
              <p>Any NFTs minted on token.gallery are subject to immediate removal from our website if legal claims are made against them by external parties.</p>
              <br />
              <p>Even if you follow these tips, you never know if someone will make a claim. Play it safe.</p>
              <br />
              <p>
                <strong>Learn more about copyrights.</strong>
                <a class="link-accent" href="https://edu.gcfglobal.org/en/useinformationcorrectly/copyright-and-fair-use/1/">
                  https://edu.gcfglobal.org/en/useinformationcorrectly/copyright-and-fair-use/1/
                </a>
              </p>
            </div>
          </li>
        </ul>
      </section>

      <aside class="main-text">
        <a href="https://discord.gg/2vq4WcD" class="button button-square-accent join-our-discord">
          <DiscordIcon class="discord-icon" />
          <span>Join our discord</span>
        </a>

        <h3 class="title">Quicklinks</h3>

        <div class="quicklinks">
          <p><a href="https://ubiqsmart.com/en/what-is-ubiq">What is the Ubiq Blockchain? →</a></p>
          <p><a href="https://10grans.cash">What is 10grans? →</a></p>
          <p><a href="https://metamask.io/">Install Metamask →</a></p>
          <p><a href="https://ubiqsmart.com/sparrow">Install Sparrow →</a></p>
        </div>

        <h3 class="title">Who made this?</h3>
        <div class="creators">
          <p>
            <a target="_blank" href="https://twitter.com/TheMoonOfficia2"><span class="blue">Moon / </span>backend, frontend, solidity dev →</a>
          </p>
          <p>
            <a target="_blank" href="https://🆒🆕🎨.y.at/"><span class="red">robek.world / </span>research, ideas, leader →</a>
          </p>
          <p>
            <a target="_blank" href="https://github.com/fafrd"><span class="green">Kian / </span>frontend, backend dev →</a>
          </p>
          <p>
            <a target="_blank" href="https://twitter.com/Wesker44803474"><span class="purple">Wǝsker / </span>design, product →</a>
          </p>
        </div>
      </aside>
    </div>
  </div>
</template>

<style scoped>
.about.container {
  margin-top: 0;
}

header {
  text-align: center;
  background-image: url("/about/header.gif");
  background-position: top;
  background-repeat: no-repeat;
}

h1 {
  padding-top: 640px;
  margin-bottom: 64px;
  font-size: 32px;
  line-height: 38.4px;
  font-weight: 700;
}

#about-main {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 100px;
}

#about-main section {
  width: calc(60% - 160px);
  margin-right: 160px;
}

#about-main aside {
  width: 30%;
}

.icons-container {
  display: flex;
  justify-content: center;
}

.icons-container .icon {
  margin: 80px;
}

.icons-container p {
  margin-top: 32px;
  color: var(--offwhite);
  font-size: 24px;
  line-height: 28.8px;
}

h3.title {
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  color: var(--accent);
}

h3 {
  font-size: 24px;
  line-height: 28.8px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 32px;
}

h4 {
  font-weight: 700;
  font-size: 20px;
  margin-top: 24px;
  margin-bottom: 24px;
}

p.what {
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
}

ul.faq > li {
  padding-bottom: 32px;
  border-bottom: var(--border-soft);
  list-style-type: none;

  font-size: revert;
  line-height: revert;
  font-weight: revert;
}

ul.faq > li:last-child {
  padding-bottom: 0;
  border-bottom: initial;
}

p,
ol > li,
ul > li {
  font-size: 21px;
  line-height: 31.5px;
  font-weight: 400;
}

ol {
  list-style-type: decimal;
  margin-left: 32px;
}

ul {
  list-style-type: disc;
  margin-left: 32px;
}

.li-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 24px;
  margin-bottom: 8px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.li-header h3 {
  margin: 0;
  margin-right: 32px;
}

.page-break {
  margin-top: 178px;
  margin-bottom: 178px;
}

section.main-text h2 {
  font-size: 56px;
  line-height: 67px;
  font-weight: 700;
  color: var(--accent);
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 32px;
}

aside.main-text h3.title {
  margin-top: 64px;
  margin-bottom: 24px;
}

aside.main-text p {
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 27px;
}

.join-our-discord .discord-icon {
  margin-right: 16px;
}

.blue {
  color: #1cc8ee;
}

.red {
  color: var(--red);
}

.green {
  color: var(--green);
}

.purple {
  color: var(--purple-text);
}

.join-our-discord {
  display: flex;
  align-items: center;
  max-width: min-content;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 64px;
}

.link-accent {
  color: var(--accent);
}

.open-close-icon {
  overflow: visible;
}

li::marker {
  font-weight: 700;
}

ol > li {
  margin-bottom: 16px;
}
</style>

<script>
import Vue from "vue";
import ArrowDown from "@/assets/images/arrow-down.svg";
import PageBreak from "@/assets/images/page-break.svg";
import DiscordIcon from "@/assets/images/discord.svg";
import EyeClosed from "@/assets/images/eye-closed.svg";
import EyeOpen from "@/assets/images/eye-open.svg";

export default {
  components: {
    ArrowDown,
    PageBreak,
    DiscordIcon,
    EyeClosed,
    EyeOpen,
  },
  data() {
    return {
      showFaq: {}, // faq-1: true, faq-2: false...
    };
  },
  metaInfo() {
    return {
      meta: [{ description: "token.gallery is an NFT marketplace that supports and showcases creative work. create and collect artwork that lives on the blockchain." }],
    };
  },
  methods: {
    toggleItem(element) {
      // find parent <li>
      const parent = element.target.closest("ul.faq > li");
      if (this.showFaq[parent.id]) {
        Vue.set(this.showFaq, parent.id, false);
      } else {
        Vue.set(this.showFaq, parent.id, true);
      }
    },
  },
};
</script>
