<template>
  <div id="app" class="app">
    <div v-if="showModal" id="overlay" />
    <nav>
      <p class="title button">
        <router-link to="/">
          <Logo alt="token.gallery" />
          <Beta alt="beta" class="beta" />
        </router-link>
      </p>
      <div class="nav-links">
        <router-link to="/about" class="button button-round-plain">About</router-link>
        <template v-if="userAuthenticated && metaMaskConnected && username">
          <router-link to="/createnft" class="button button-round-accent">Create</router-link>
          <button @click="toggleNotifications" class="notification-icon">
            <BellIcon class="bell" alt="Show/Hide Notifications" />
            <div v-if="newNotificationAvailable" class="red-circle" />
          </button>
          <div v-if="showNotifications" class="notifications-modal-container">
            <aside class="notifications-modal">
              <h1>Notifications</h1>
              <Notifications class="light" />
            </aside>
          </div>

          <router-link :to="{ name: 'Address', params: { address: username } }" class="button user-button">
            <span class="user-letter">{{ username[0].toUpperCase() }}</span>
          </router-link>
        </template>
        <span v-else>
          <a href="#" @click="openModal" class="button button-round-outline">Connect Wallet</a>
        </span>
      </div>
    </nav>
    <main>
      <div v-if="showModal" id="modal-login">
        <div class="close-icon-container">
          <BlackXIcon class="close-icon" alt="Close wallet connect modal" @click="closeModal(0)" />
        </div>

        <section class="modal-inner">
          <h1>Connect your wallet</h1>
          <div v-if="error">
            <p class="error">{{ this.error }}</p>
            <br />
          </div>

          <div v-if="!metaMaskPresent" class="flex">
            <div class="message">
              <p>Token Gallery uses the <strong>Ubiq</strong> blockchain.</p>
              <p>
                Install <a href="https://metamask.io/" target="_blank"><strong>Metamask</strong></a> or
                <a href="https://ubiqsmart.com/sparrow" target="_blank"><strong>Sparrow</strong></a> wallet to log in.
              </p>
              <p class="metamask-warning">(Do not install Metamask and Sparrow in the same browser.)</p>
            </div>
          </div>
          <div v-else-if="!chainIdCorrect" class="flex">
            <p>Token Gallery uses the <strong>Ubiq</strong> blockchain.</p>
            <div class="message">
              <p>Click here to switch to the Ubiq chain:</p>
            </div>
            <button class="button-round-accent" @click="switchChain">Switch Network</button>
          </div>
          <div v-else-if="!metaMaskConnected" class="flex">
            <div class="message">
              <p>Click here to connect your wallet</p>
            </div>
            <button class="button-round-accent" @click="connect">Connect</button>
          </div>
          <div v-else-if="hasUser">
            <div v-if="userAuthenticated" class="flex">
              <div class="message">
                <p>Logged in!</p>
              </div>
            </div>
            <div v-else class="flex">
              <div class="message">
                <p>Welcome back!</p>
                <p>Sign a message to log in.</p>
              </div>
              <button class="button-round-accent" id="login" @click="login()">Sign</button>
              <button class="button-round-gray" id="login" @click="closeModal(0)">Cancel</button>
            </div>
          </div>
          <div v-else class="user-register flex">
            <div class="message">
              <p>Welcome! Register your address {{ this.accounts[0].substring(0, 5) + "..." + this.accounts[0].substring(39) }}.</p>
              <p>Enter a username:</p>
            </div>
            <form>
              <input type="text" maxlength="40" id="username" v-model="requestedUsername" />
              <button type="button" class="button-round-accent" @click="register">Register</button>
            </form>
          </div>

          <div class="help">
            <p>By registering, you agree to our <br /><router-link to="/privacy">Privacy Policy</router-link> and <router-link to="/terms">Terms of Service</router-link>.</p>
            <p>Confused? Check out our <router-link @click.native="closeModal(0)" to="/about">about page</router-link></p>
          </div>
        </section>
      </div>

      <transition name="fade">
        <router-view @connect-wallet="openModal" />
      </transition>
    </main>
    <footer>
      <div class="footer-left">
        <p class="title"><router-link to="/">token.gallery</router-link></p>
        <p><a href="https://twitter.com/tokengallery">Twitter</a></p>
        <p><a href="https://discord.gg/2vq4WcD">Discord</a></p>
        <p><a href="mailto:nfttokengallery@gmail.com">Support</a></p>
      </div>
      <div class="footer-right">
        <p><router-link to="/terms">Terms of Service</router-link></p>
        <p><router-link to="/privacy">Privacy</router-link></p>
        <p>
          <a href="https://github.com/robekworld/nft-license/" target="_blank">NFT License <ExternalLinkIcon class="external-link-icon"/></a>
        </p>
        <p><router-link to="/about">About</router-link></p>
      </div>
    </footer>
  </div>
</template>

<style>
:root {
  background-color: #000808;
}

/*
Optionally transition on every page?

.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .125s;
}

.fade-enter-active {
  transition-delay: .125s;
}

.fade-enter, .fade-leave-active {
  opacity: 0
}
*/

.app {
  color: var(--white);
  font-family: Poppins, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app a {
  color: var(--white);
}

.app a:active {
  color: var(--white);
}

.modal {
  text-shadow: none;
}

.app nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
  padding: 0 24px;
}

.app nav .title a {
  display: inline-flex;
  text-decoration: none;
}

.beta {
  margin-left: 16px;
}

.app nav .notification-icon {
  background-color: #292f2f;
  font-weight: initial;
  border-radius: 100%;
  margin-right: 0;
}

.app nav .notification-icon .bell {
  position: fixed;
  transform: translate(-50%, -50%);
}

.app nav .notification-icon .red-circle {
  background-color: var(--red);
  width: 12px;
  height: 12px;
  border-radius: 100%;
  position: fixed;
  transform: translate(90%, -190%);
}

.app nav a.user-button {
  background-color: #292f2f;
  font-weight: initial;
  border-radius: 100%;
  margin-right: 0;
}

.app nav .nav-links {
  display: flex;
}

.app nav .nav-links button,
.app nav .nav-links .button {
  max-height: 48px;
}

.app nav .nav-links > * {
  transform: scale(1); /* hack to get childrens position:fixed to treat this element as the parent */
  z-index: 2;
}

.app nav .nav-links .user-button {
  display: flex;
  align-items: center;
  padding: 20px;
  height: 8px;
  width: 8px;
}

.app nav .nav-links .user-letter {
  position: relative;
  left: -1px;
  color: var(--white);
}

.app main {
  width: 100%;
  flex: 1;
}

#modal-login {
  z-index: 20;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 530px;
  max-width: 80%;
  border-radius: 8px;

  background: var(--white);
  color: var(--card-background);

  text-align: center;
}

#modal-login .flex {
  display: flex;
  flex-direction: column;
}

#modal-login .close-icon-container {
  text-align: right;
  margin-top: 32px;
  margin-bottom: 40px;
  margin-right: 32px;
}

#modal-login .close-icon {
  cursor: pointer;
}

#modal-login section {
  margin-bottom: 48px;
  margin-left: 24px;
  margin-right: 24px;
}

#modal-login .help {
  margin-top: 32px;
  font-size: 13px;
  line-height: 18px;
}

#modal-login .message {
  margin-bottom: 32px;
}

#modal-login .metamask-warning {
  font-size: 13px;
  font-style: italic;
}

#modal-login button {
  margin: 0 auto;
  width: 200px;
  max-width: 100%;
}

#modal-login button:first-of-type {
  margin-bottom: 16px;
}

#modal-login form {
  margin-top: -16px;
  display: flex;
  flex-direction: column;
}

#modal-login input {
  margin-bottom: 16px;
  max-width: 100%;
  background-color: revert;
  color: revert;
}

#modal-login input:focus {
  color: revert;
}

#modal-login a {
  color: var(--accent);
  text-decoration: none;
}

#modal-login h1 {
  margin-bottom: 32px;

  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
  color: var(--accent);
}

.app footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: var(--border-soft);
  height: 80px;
  margin: 60px 40px 0px 40px;
}

.app footer .title {
  font-weight: bold;
}

.app footer .footer-left {
  display: flex;
}

.app footer .footer-right {
  display: flex;
}

.app footer p {
  margin: 0 16px;
}

.app footer a {
  text-decoration: none;
}

.external-link-icon {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.notifications-modal {
  position: fixed;
  right: 0;
  top: 75px;
  overflow-y: scroll;

  border-radius: 8px;
  background-color: var(--white);
  padding: 32px;
  width: 420px;
  max-height: 720px;
}

.notifications-modal h1 {
  text-transform: uppercase;
  color: var(--offwhite);
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}
</style>

<script>
import Notifications from "@/components/Notifications.vue";
import Logo from "@/assets/images/logo.svg";
import Beta from "@/assets/images/beta.svg";
import BlackXIcon from "@/assets/images/black-x.svg";
import ExternalLinkIcon from "@/assets/images/external-ltr.svg";
import BellIcon from "@/assets/images/bell.svg";
import shared from "./shared";
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";

const providerOptions = {
  reconnect: {
    auto: true,
    delay: 5000,
    maxAttempts: 5,
    onTimeout: false,
  },
};

const web3Options = {
  transactionConfirmationBlocks: 1,
};

const rinkebyParams = {
  chainId: "0x4",
  chainName: "Ethereum Testnet Rinkeby",
  nativeCurrency: {
    name: "Rinkeby Ether",
    symbol: "RIN",
    decimals: 18,
  },
  rpcUrls: ["https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161", "wss://rinkeby.infura.io/ws/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
  blockExplorerUrls: ["https://rinkeby.etherscan.io"],
};

const ubiqParams = {
  chainId: "0x8",
  chainName: "Ubiq Network Mainnet",
  nativeCurrency: {
    name: "Ubiq Ether",
    symbol: "UBQ",
    decimals: 18,
  },
  rpcUrls: ["https://rpc.octano.dev"],
  blockExplorerUrls: ["https://ubiqscan.io"],
};

const selectedChainParameters = process.env.VUE_APP_CHAIN_ID == 8 ? ubiqParams : rinkebyParams;
console.debug("selected chain: " + selectedChainParameters.chainName);

export default {
  components: {
    Notifications,
    Logo,
    Beta,
    BlackXIcon,
    ExternalLinkIcon,
    BellIcon,
  },
  data() {
    return {
      expectedChainId: "0x" + process.env.VUE_APP_CHAIN_ID,
      actualChainId: "",
      errorStr: "",
      requestedUsername: "",
      showModal: false,
      showNotifications: false,
      setLoginStateMutex: false, // true if setting login state
    };
  },
  async created() {
    detectEthereumProvider(providerOptions).then(async (provider) => {
      if (!provider) {
        this.$root.web3 = new Web3(); // TODO set this to use https://rpc.token.gallery/
      } else {
        this.$root.web3 = new Web3(provider, web3Options);

        // set up callback for network change
        window.ethereum.on("chainChanged", (newChainId) => {
          this.$data.actualChainId = newChainId;

          // if not on the right chain, force a logout
          if (!this.chainIdCorrect) {
            // clear local session user
            console.debug("clearing user from local state");
            this.$store.commit("updateUser", null);
            return;
          } else {
            this.setLoginState();
          }
        });

        // set up callback for account change
        window.ethereum.on("accountsChanged", this.setLoginState);

        // determine if user is logged in
        this.setLoginState();

        // Poll the chainId for .7 second. In my metamask it takes some time for this value to become correct, strangely...
        for (let count = 1; count < 8; count++) {
          setTimeout(() => {
            this.$data.actualChainId = window.ethereum.chainId;
          }, 100 * count);
        }

        // after polling complete, force a logout if on the wrong chain
        setTimeout(() => {
          if (!this.chainIdCorrect) {
            // clear local session user
            console.debug("clearing user from local state");
            this.$store.commit("updateUser", null);
            return;
          } else {
            this.setLoginState();
          }
        }, 800);
      }
    });

    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.closeModal();
      }
    });
  },
  computed: {
    error() {
      if (!this.metaMaskPresent) return "Error: No wallet detected";
      if (!this.chainIdCorrect) return "Error: Incorrect network";
      if (this.$data.errorStr !== "") return this.$data.errorStr;
      return "";
    },
    metaMaskPresent() {
      return typeof window.ethereum !== "undefined";
    },
    metaMaskConnected() {
      return this.accounts.length > 0;
    },
    accounts() {
      return this.$store.state.accounts;
    },
    chainIdCorrect() {
      if (this.$data.expectedChainId !== this.$data.actualChainId) {
        console.warn(`Chain ID incorrect- expected ${this.$data.expectedChainId}, but found ${this.$data.actualChainId}`);
        return false;
      }

      return true;
    },
    hasUser() {
      return this.$store.state.user != null;
    },
    userAuthenticated() {
      return this.$store.getters.isUserAuthenticated;
    },
    userAddress() {
      if (this.$store.state.user) return this.$store.state.user.id;
      return "";
    },
    username() {
      if (this.$store.state.user) return this.$store.state.user.username;
      return "";
    },
    lastNotificationDateLocal: {
      get() {
        return this.$store.state.lastNotificationCheckDate;
      },
      set(val) {
        this.$store.commit("updateLastNotificationCheckDate", val);
      },
    },
    newNotificationAvailable() {
      if (!this.userAddress) return false;
      if (!this.lastNotificationDateRemote) return false;

      return this.lastNotificationDateRemote > this.lastNotificationDateLocal;
    },
  },
  asyncComputed: {
    lastNotificationDateRemote: {
      async get() {
        if (!this.userAddress) return "";

        try {
          const res = await shared.fetchJson(`${this.$apiBase}/api/notifications/${this.userAddress}/last`);
          return res.last;
        } catch (e) {
          console.error("Unable to check last notification date", e);
          return "";
        }
      },
    },
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal(delay) {
      setTimeout(() => {
        this.showModal = false;
      }, delay);
    },
    toggleNotifications() {
      if (this.showNotifications) {
        this.showNotifications = false;
      } else {
        this.showNotifications = true;
        this.lastNotificationDateLocal = new Date().toISOString();
        // debug:
        //let d = new Date(); d.setMonth(d.getMonth() - 3); this.lastNotificationDateLocal = d.toISOString();
        //this.lastNotificationDateLocal = "";
      }
    },
    hideNotifications() {
      this.showNotifications = false;
    },
    async connect() {
      await this.$root.web3.eth.requestAccounts();

      // update login state now that eth address is available
      this.setLoginState();
    },
    async switchChain() {
      // Try to change the chain ID to ubiq
      try {
        console.debug("chain id: " + JSON.stringify(selectedChainParameters.chainId));
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: selectedChainParameters.chainId }],
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          console.error("received 4902");

          try {
            console.debug("chain params: " + JSON.stringify(selectedChainParameters));
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [selectedChainParameters],
            });
          } catch (addError) {
            console.error("Failed to add chain in metamask");
            throw addError;
          }
        } else {
          console.error("Failed to switch chain in metamask");
          throw switchError;
        }
      }
    },
    logout() {
      // clear user from local state, then force a refresh
      this.$store.commit("updateUser", null);
      this.$router.push("/");

      // update login state
      this.setLoginState();
    },
    async setLoginState() {
      if (this.setLoginStateMutex) return; // already in progress

      try {
        console.debug("Setting login state");
        this.setLoginStateMutex = true;

        const accounts = await this.$root.web3.eth.getAccounts();
        this.$store.commit("updateAccounts", accounts);

        if (this.accounts.length > 0) {
          const walletAddress = this.$root.web3.utils.toChecksumAddress(this.accounts[0]);
          console.debug("metamask selected account: " + walletAddress);

          const localUser = this.$store.state.user;
          console.debug("local user: " + JSON.stringify(localUser));

          if (localUser == null) {
            await this.setLocalUser(walletAddress);
          } else {
            // local session user found; does it match what metamask reports?
            const sessionUserMatchesWallet = localUser.id == walletAddress;
            console.debug(`user's id matches MetaMask address? ${sessionUserMatchesWallet}`);

            if (!sessionUserMatchesWallet) {
              // clear local session user
              console.debug("clearing user from local state");
              this.$store.commit("updateUser", null);

              await this.setLocalUser(walletAddress);
            } else {
              // local session user matches metamask acct! great
              // But do they exist on remote database?
              const remoteUser = await shared.fetchJson(`${this.$apiBase}/api/user/${walletAddress}`);
              console.debug("remote user: " + JSON.stringify(remoteUser));
              if (!remoteUser) {
                // no remote user found for local user- clearing local user.
                this.$store.commit("updateUser", null);
              } else {
                // found remote user matching local- done.
                console.debug("user already logged in.");
                this.closeModal(1000);
              }
            }
          }
        }
      } finally {
        this.setLoginStateMutex = false;
      }
    },
    async setLocalUser(walletAddress) {
      // no local session user! check if the current address has been registered remotely
      const remoteUser = await shared.fetchJson(`${this.$apiBase}/api/user/${walletAddress}`);
      console.debug("remote user: " + JSON.stringify(remoteUser));
      if (remoteUser) {
        // same user exists remotely. Set this as local state
        console.debug("found local user matching remote. setting local");
        this.$store.commit("updateUser", remoteUser);
      }
    },
    async register() {
      let usernameError = this.validateUsername(this.requestedUsername);
      this.$data.errorStr = usernameError;
      if (usernameError != "") return false;

      try {
        const usernameAvailable = await this.usernameAvailable(this.requestedUsername);
        if (!usernameAvailable) {
          this.$data.errorStr = "Username already taken";
          return false;
        }
      } catch (error) {
        console.error(error);
        this.$data.errorStr = "Error checking username availability; please contact support";
        return false;
      }

      const registerSuccess = await this.registerUsername();
      if (!registerSuccess) return false;

      this.closeModal(1000);
      return true;
    },
    async login() {
      const now = new Date().toISOString();
      const message = `${now} ${this.$store.state.user.id} login`;
      const signature = await this.$root.web3.eth.personal.sign(this.$root.web3.utils.fromUtf8(message), this.$store.state.user.id);

      const formData = new FormData();
      formData.append("now", now);
      formData.append("account", this.$store.state.user.id);
      formData.append("signature", signature);

      let data;
      let loginError;
      try {
        const response = await fetch(`${this.$apiBase}/api/user/login`, { method: "POST", body: formData, dataType: "json", contentType: "application/json" });
        data = await response.json();
        if (data.error) {
          loginError = data.error;
        }
      } catch (e) {
        console.error("failed to submit form", e);
        loginError = e.toString();
      }
      if (loginError) {
        console.warn("something went wrong remotely");
        this.$data.errorStr = loginError;
        return false;
      }

      this.$store.commit("updateUser", data);

      //await this.populateAmounts();
      //await this.populateNfts();
      //await this.populateStores();
      this.$forceUpdate(); // TODO is this necessary?

      this.closeModal(1000);
      return true;
    },
    async registerUsername() {
      // push a signed request and get back a UUID secret key
      // ISO date, checksummed account, and requested username
      const account = this.$root.web3.utils.toChecksumAddress(this.accounts[0]);

      const now = new Date().toISOString();
      const message = `${now} ${account} ${this.requestedUsername}`;
      const signature = await this.$root.web3.eth.personal.sign(this.$root.web3.utils.fromUtf8(message), account);

      const formData = new FormData();
      formData.append("now", now);
      formData.append("account", account);
      formData.append("username", this.requestedUsername);
      formData.append("signature", signature);

      let data, registrationError;
      try {
        const response = await fetch(`${this.$apiBase}/api/user/register`, { method: "POST", body: formData, dataType: "json", contentType: "application/json" });
        data = await response.json();
        if (data.error) {
          registrationError = data.error;
        }
      } catch (e) {
        console.error("failed to submit form", e);
        registrationError = e.toString();
      }

      if (registrationError) {
        this.$data.errorStr = registrationError;
        return false;
      }

      this.$store.commit("updateUser", data);
      this.$forceUpdate();

      return true;
    },
    async usernameAvailable(username) {
      const availability = await shared.fetchJson(`${this.$apiBase}/api/user/check/${username}`);
      if (availability.error) {
        throw `Error fetching /api/user/check/${username}: ${availability.error}`;
      }

      return !availability.taken;
    },
    validateUsername(username) {
      if (username == null || username.length == 0) {
        return "Username required";
      } else if (username.length < 3) {
        return "Username must be at least three characters";
      } else if (username.length > 20) {
        return "Username may only be 20 characters";
      } else if (!username.match(/^[a-z0-9_]+$/i)) {
        return "Username must be Latin alphanumeric/underscore";
      } else if (username[0].match(/^[0-9_]+$/)) {
        return "Username must not start with a number or underscore";
      } else {
        return "";
      }
    },
  },
};
</script>
