<template>
  <div class="container">
    <CreateNftHeader h1="What's it look like?" h2="We support JPG, PNG, and GIF formats — for now." :step-current="2" :step-max="5" />

    <form>
      <h2 class="subheading">First, upload that beautiful artwork.</h2>

      <label for="file-picker">
        <section class="upload-area hoverable" @drop="drop" @dragover.prevent @dragenter.prevent>
          <div class="upload-state" id="upload-state-default">
            <UploadIcon class="upload-icon" alt="Click here to upload an image" />
            <h2>Drag and drop your file here or <span class="accent">browse your files</span></h2>
            <p>{{ this.sizeLimit }}MB size limit</p>
          </div>
          <div class="upload-state hidden" id="upload-state-error">
            <UploadErrorIcon class="upload-icon" alt="Error" />
            <h2 id="error-message" class="error">That file is too big or it's the wrong type.</h2>
            <p>Make sure your file is smaller than {{ this.sizeLimit }}MB and either JPG, PNG, or GIF.</p>
          </div>
          <div class="upload-state hidden" id="upload-state-pending">
            <h2>Processing image...</h2>
          </div>
          <div class="upload-state hidden" id="upload-state-complete">
            <img src="" alt="Previewed NFT image" id="nft-preview-image" />
          </div>
        </section>
      </label>

      <input id="file-picker" accept="image/png,image/gif,image/jpeg" type="file" @change="drop" />
      <!-- TODO- if multiple upload, add 'multiple' attribute -->
    </form>

    <section class="upload-buttons">
      <router-link to="/createnft/details" class="button button-round-accent disabled" id="upload-button">Upload</router-link>
      <button @click="reset" class="button button-round-reset disabled" id="reset-button">Reset</button>
    </section>
  </div>
</template>

<style scoped>
.upload-area {
  background-color: var(--card-background);
  border: 1px solid var(--accent);
  border-radius: 8px;
  box-sizing: border-box;
  height: 500px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;
}

.upload-state {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload-state.hidden {
  display: none;
}

.upload-area .upload-icon {
  display: block;
  margin: 0 auto 26px auto;
}

.upload-area h2 {
  text-align: center;
  font-weight: normal;
}

.upload-area h2 .accent {
  color: var(--accent);
  font-weight: bold;
}

.upload-area p {
  text-align: center;
}

.upload-buttons {
  display: flex;
  justify-content: center;
}

.upload-buttons .button {
  font-size: 24px;
  line-height: 34px;
  padding: 16px 40px;
  margin-top: 24px;
}

#file-picker {
  display: none;
}

#nft-preview-image {
  display: block;
  margin: 0 auto;
  max-height: 80%;
  max-width: 80%;
}
</style>

<script>
import CreateNftHeader from "@/components/CreateNftHeader.vue";
import UploadIcon from "@/assets/images/upload.svg";
import UploadErrorIcon from "@/assets/images/upload-error.svg";

export default {
  components: {
    CreateNftHeader,
    UploadIcon,
    UploadErrorIcon,
  },
  computed: {
    sizeLimit() {
      return 200; // filesize limit in megabytes
    },
    forms() {
      return this.$store.state.simpleSetForms;
    },
  },
  methods: {
    async drop(e) {
      console.debug("handling image drop...");
      e.preventDefault(); // prevent default browser drag behavior

      const errorMessages = {
        "no-file": "Unexpected error during file upload; could not parse file.",
        "too-big": "That file is too big.",
        "wrong-type": "That file is the wrong type.",
      };

      try {
        this.setFormState("pending");

        try {
          let files;
          if (e.dataTransfer) {
            files = [...e.dataTransfer.files];
          } else {
            const fileElem = document.getElementById("file-picker");
            if (fileElem.files) {
              files = [...fileElem.files];
            } else {
              throw errorMessages["no-file"];
            }
          }

          // check type
          const originalCount = files.length;
          files = files.filter((file) => ["image/png", "image/gif", "image/jpeg"].includes(file.type));
          if (originalCount != files.length) {
            throw errorMessages["wrong-type"];
          }
          // check size
          files = files.filter((file) => file.size < this.sizeLimit * 1024 * 1024);
          if (originalCount != files.length) {
            throw errorMessages["too-big"];
          }

          // clear all previous batch form entries
          this.$store.commit("clearSimpleSetForms");

          // handle multiple? todo?
          //for (const file of files) {
          //  const blobUrl = URL.createObjectURL(file);
          //  await this.$setItem(`image/${ret.newId}`, file);
          //  todo- set form with image
          //}

          // handle single
          const ret = {};
          this.$store.commit("addSimpleSetForm", ret);
          const blobUrl = URL.createObjectURL(files[0]);
          await this.$setItem(`image/${ret.newId}`, files[0]);
          this.$store.commit("updateSimpleSetFormBlobUrl", { index: 0, value: blobUrl });
          // prepopulate extra props with an empty value
          this.$store.commit("addSimpleSetFormProperty", { index: 0, name: "", value: "" });

          // Set image with ID
          document.getElementById("nft-preview-image").src = blobUrl;

          console.debug("Received files: ");
          console.debug(files);
          console.debug("blob:");
          let res = await this.$getItem(`image/${this.$store.state.simpleSetForms[0].id}`);
          console.debug(res);
        } finally {
          // We don't actually need to wait to show the 'Uploading image...' dialogue. Let's fake it with a half-second timeout
          await new Promise((resolve) => setTimeout(resolve, 500));
        }
      } catch (e) {
        if (e instanceof Error) {
          // unexpected error- hide real message from user
          console.error(e);
          this.setError("Unexpected error while processing file! Please contact support.");
          this.setFormState("error");
        } else {
          // anticipated error- show message to user
          console.error(e);
          this.setError(e);
          this.setFormState("error");
        }

        return;
      }

      // done
      this.setFormState("complete");
    },
    setFormState(state) {
      document.getElementById("upload-state-default").classList.add("hidden");
      document.getElementById("upload-state-error").classList.add("hidden");
      document.getElementById("upload-state-pending").classList.add("hidden");
      document.getElementById("upload-state-complete").classList.add("hidden");

      document.getElementById("upload-button").classList.add("disabled");
      document.getElementById("reset-button").classList.add("disabled");

      // state should be one of "default", "error", "pending", "complete"
      switch (state) {
        case "default":
          document.getElementById("upload-state-default").classList.remove("hidden");
          break;
        case "error":
          document.getElementById("upload-state-error").classList.remove("hidden");
          document.getElementById("reset-button").classList.remove("disabled");
          break;
        case "pending":
          document.getElementById("upload-state-pending").classList.remove("hidden");
          break;
        case "complete":
          document.getElementById("upload-state-complete").classList.remove("hidden");
          document.getElementById("upload-button").classList.remove("disabled");
          document.getElementById("reset-button").classList.remove("disabled");
          break;
        default:
          document.getElementById("upload-state-error").classList.remove("hidden");
          document.getElementById("reset-button").classList.remove("disabled");
          this.setError("Unexpected form state! Please contact support.");
      }
    },
    setError(err) {
      document.getElementById("error-message").innerText = err;
    },
    reset() {
      this.setFormState("default");
    },
  },
};
</script>
