import { bus } from "./main";

const BigNumber = require("bignumber.js");
BigNumber.set({ DECIMAL_PLACES: 18, ROUNDING_MODE: BigNumber.ROUND_FLOOR });

export default {
  propertyNameBlacklist: ["id", "set", "number", "of", "adult", "name", "description", "extra", "blobUrl", "tags"],
  async fetchJson(url) {
    return await (await fetch(url)).json();
  },
  formatIpfsLink(prefix, ipfsStr) {
    if (ipfsStr.startsWith("ipfs://")) {
      ipfsStr = ipfsStr.substring(7);
    }

    return prefix + ipfsStr;
  },
  validateNftForm() {
    if (this.nftName == null || this.nftName.length == 0) {
      this.error = "Name is required";
      return false;
    } else if (this.nftName.length > 40) {
      this.error = "Name may only be 40 characters";
      return false;
    } else if (this.nftDescription == null || this.nftDescription.length == 0) {
      this.error = "Description is required";
      return false;
    } else if (this.nftDescription.length > 1000) {
      this.error = "Description must only be 1000 characters";
      return false;
    } else {
      return true;
    }
  },
  // Eventually will replace the other one.
  newValidateNftForm(obj) {
    const ARBITRARY_FIELD_MAX = 1000;
    const NAME_MAX = 40;
    const SET_MAX = 100;

    const errors = [];
    if (obj.name == null || obj.name.length == 0) {
      errors.push("Name is required");
    }
    if (obj.name && obj.name.length > NAME_MAX) {
      errors.push(`Name may only be ${NAME_MAX} characters`);
    }
    if (obj.description && obj.description.length > ARBITRARY_FIELD_MAX) {
      errors.push(`Description may only be ${ARBITRARY_FIELD_MAX} characters`);
    }
    if (typeof obj.set !== "undefined" && obj.set.length > SET_MAX) {
      errors.push(`Set may only be ${SET_MAX} characters`);
    }

    return errors;
  },
  blobToDataUrl: (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  },
  dataUrlToBlob: (data) => {
    return fetch(data).then((res) => res.blob());
  },
  async getBaseNft(storeAddress) {
    const bases = await (await fetch(`${this.$apiBase}/api/store/${storeAddress}/base`)).json();
    return bases.length == 0 ? null : bases[0].nftId;
  },
  async uploadForm(url, form, method, hashString_suffix, account, token, web3Utils, expectResponse = true) {
    const path = new URL(url).pathname;
    const now = new Date().toISOString();

    account = web3Utils.toChecksumAddress(account);

    let hashString = `${now} ${account} ${token} ${path}`;
    if (hashString_suffix) {
      hashString = hashString + ` ${hashString_suffix}`;
    }
    console.debug(`Sending hash of message '${hashString}'`);
    const hash = web3Utils.keccak256(hashString); // keccak doesn't need HMAC

    const responsePromise = await fetch(url, {
      method: method,
      body: form,
      headers: {
        Authorization: `Bearer ${hash}`,
        "X-NftStore-Account": account,
        "X-NftStore-Now": now,
      },
    }).then(async (response) => {
      let json;
      try {
        json = await response.json();
      } catch (error) {
        // If we weren't expecting a response, just return empty
        // If we were, then something went wrong during json parsing- throw
        if (!expectResponse) {
          return;
        } else {
          throw error;
        }
      }

      if (!response.ok) {
        if (json.error) {
          throw new Error(`Backend error ${response.status}: ${json.error}`);
        } else {
          throw new Error(`Backend returned unspecified error (status ${response.status}`);
        }
      }

      // no error- return response as normal
      return json;
    });

    return await responsePromise;
  },
  handleTransactionError(error) {
    // this function should be used in the .catch handler for transactions
    // returns an object like { errorMessage: "Stuff went wrong", errorMessageSecondary: "Stuff went wrong for X reason" }

    console.error("error executing transaction; ", error);

    let retval = {
      errorMessage: "",
      errorMessageSecondary: "",
    };

    if (error.code == 4001) {
      retval.errorMessage = "User canceled the request";
    } else if (typeof error.code !== "undefined") {
      retval.errorMessage = "Error " + error.code;
      retval.errorMessageSecondary = error.message;
    } else if (error.message.startsWith("Transaction has been reverted by the EVM")) {
      retval.errorMessage = "Transaction was reverted";
      retval.errorMessageSecondary = "An error was thrown from the smart contract";
    } else {
      retval.errorMessage = "Unspecified error";
      retval.errorMessageSecondary = error.message;
    }

    return retval;
  },
  apiPing() {
    return new Promise((resolve) => {
      const controller = new AbortController();
      const id = setTimeout(() => controller.abort(), 1000);
      try {
        fetch(`${this.$apiBase}/api/ping`, { signal: controller.signal })
          .then((response) => {
            clearTimeout(id);
            resolve(response.status == 200);
          })
          .catch((e) => {
            console.error("ping failed 1", e);
            resolve(false);
          });
      } catch (e) {
        console.error("ping failed 2", e);
        resolve(false);
      }
    });
  },
  getExtraProperties(id) {
    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        bus.$off("ExtraPropertiesReceive", handle);
        reject("No properties by that id");
      }, 1000);
      function handle(p) {
        if (p.id == id) {
          clearTimeout(timeoutId);
          bus.$off("ExtraPropertiesReceive", handle);
          resolve(p.properties);
        }
      }
      bus.$on("ExtraPropertiesReceive", handle);
      bus.$emit("ExtraPropertiesGet");
    });
  },
  clone(obj) {
    if (null == obj || "object" != typeof obj) {
      console.debug(`I can't clone this: ${typeof obj}`);
      return obj;
    }
    const isArray = Array.isArray(obj);
    const copy = isArray ? [] : {}; // does this even matter
    const attrs = Object.getOwnPropertyNames(obj);
    for (let attr of attrs) {
      if (attr === "__ob__") {
        console.debug("Ignoring circular reference.");
      } else if (isArray && attr === "length") {
        console.debug("Ignoring array length");
      } else if (Object.prototype.hasOwnProperty.call(obj, attr)) {
        if (obj[attr] instanceof Object) {
          copy[attr] = this.clone(obj[attr]);
        } else {
          copy[attr] = obj[attr];
        }
      }
    }
    return copy;
  },
  formatPrice(price) {
    if (price === null) return null;

    // This is a terrible hack, and i feel bad about it
    // but it's too much work to figure out the proper way

    let rv;
    rv = BigNumber(price)
      .dividedBy(1e18)
      .toString();

    return rv;
  },
  async getPrice(apiBase) {
    try {
      const price = await this.fetchJson(`${apiBase}/api/money`);
      if (!price.ubiqUsdRatio) {
        console.error("Received empty ubiq price response");
        return { ubiqUsdRatio: 0, ubiqGransRatio: 1 };
      }
      return price;
    } catch (e) {
      console.error("Error fetching ubiq/grans prices", e);
      return { ubiqUsdRatio: 0, ubiqGransRatio: 1 };
    }
  },
  relativeTime(timestamp, millis = false) {
    // taken from https://stackoverflow.com/a/53800501/5924962
    var units = {
      year: 24 * 60 * 60 * 1000 * 365,
      month: (24 * 60 * 60 * 1000 * 365) / 12,
      day: 24 * 60 * 60 * 1000,
      hour: 60 * 60 * 1000,
      minute: 60 * 1000,
      second: 1000,
    };

    var rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

    var getRelativeTime = (date) => {
      var elapsed = date - new Date();

      // "Math.abs" accounts for both "past" & "future" scenarios
      for (var u in units) if (Math.abs(elapsed) > units[u] || u == "second") return rtf.format(Math.round(elapsed / units[u]), u);
    };

    let multiplier = 1;
    if (millis) multiplier = 1000;

    const date = new Date(timestamp * multiplier);
    return getRelativeTime(date);
  },
  absoluteTime(timestamp, millis = false) {
    let multiplier = 1;
    if (millis) multiplier = 1000;

    const date = new Date(timestamp * multiplier);
    return date.toString();
  },
};
