<template>
  <div class="container">
    <h1>{{ name ? name : "Loading..." }}</h1>
    <div class="cols-2">
      <aside>
        <div class="creator-block">
          <div class="details-title">Creator</div>
          <div class="details-data">
            <router-link :to="{ name: 'Address', params: { address: storeCreatorName } }">{{ storeCreatorName }}</router-link>
          </div>
        </div>
        <div>
          <div class="desc-symbol details-title">Symbol</div>
          <div class="details-data">{{ symbol }}</div>
        </div>
        <div class="contract-balance">
          <div class="desc-balance details-title">Contract Balance</div>
          <div class="details-data">{{ contractBalance }} UBQ</div>
        </div>
        <div v-if="myBalance != '0'">
          <div class="desc-stored details-title">Stored Balance</div>
          <div class="details-data">{{ myBalance }} UBQ</div>
        </div>
        <div v-if="my10GransBalance != '0'">
          <div class="desc-10grans details-title">Stored 10grans Balance</div>
          <div class="details-data">{{ my10GransBalance }} GRANS</div>
        </div>

        <button v-if="hasBalance" @click="withdraw()">Withdraw</button>

        <div v-if="isStoreCreator" class="create-new">
          <router-link :to="{ name: 'Create NFT', params: { store: $route.params.collection } }" class="button button-round-accent">Create New NFTs</router-link>
        </div>

        <div id="withdraw-notification" class="notification is-success is-hidden">
          <button class="delete" @click="deleteNotif()"></button>
          You will have your funds when the transaction clears.
        </div>
      </aside>

      <section>
        <div v-if="nfts.length > 0" class="card-grid">
          <NftCard v-for="(nft, index) of nfts" :key="index" :nft="nft" />
        </div>
        <div v-else class="nfts">
          <h2 class="no-nfts-yet">{{ finishedLoading ? "No NFTs in this store yet." : "Loading..." }}</h2>
        </div>

        <div class="paging">
          <button class="button-square-accent" @click="currentPage--" :class="{ disabled: currentPage <= 1 }">← Prev Page</button>
          <span class="current-page">{{ currentPage }}</span>
          <button class="button-square-accent" @click="currentPage++" :class="{ disabled: !nextPageAvailable }">Next Page →</button>
        </div>
      </section>
    </div>
  </div>
</template>

<style scoped>
.create-new,
.no-nfts-yet {
  margin-top: 3em;
  text-align: center;
}

.notification {
  width: 30%;
}

.contract-balance {
  display: none;
}

.is-hidden {
  display: none;
}

.paging {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 64px;
  text-align: center;
}

.current-page {
  margin: 0 32px;
  font-weight: 700;
  font-size: 38px;
}
</style>

<script>
const BigNumber = require("bignumber.js");
import NftCard from "../components/NftCard.vue";
import shared from "../shared";

export default {
  data: function() {
    return {
      name: "",
      symbol: "",
      nfts: [],
      contractBalance: "0",
      my10GransBalance: "0",
      myBalance: "0",
      storeContract: null,
      isStoreCreator: false,
      storeCreator: null,
      storeCreatorName: "",
      finishedLoading: false,
      ethPrice: 0,
      tokenPrice: 0,
      nextPageAvailable: false,
    };
  },
  components: {
    NftCard,
  },
  metaInfo() {
    if (this.name && this.storeCreatorName) {
      return {
        meta: [{ description: `nft collection - ${this.name}` }, { author: this.storeCreatorName }],
      };
    }

    return {};
  },
  async created() {
    try {
      shared.getPrice(this.$apiBase).then((price) => {
        this.ethPrice = price.ubiqUsdRatio;
        this.tokenPrice = price.ubiqUsdRatio / price.ubiqGransRatio;
      });

      const dbStore = await shared.fetchJson(`${this.$apiBase}/api/store/${this.$route.params.collection}`);
      if (dbStore == null) {
        console.error("Store not found.");
        return;
      }

      this.name = dbStore.name;
      document.title = this.name;
      this.symbol = dbStore.symbol;
      this.storeCreator = dbStore.creator;
      const storeCreatorUser = await shared.fetchJson(`${this.$apiBase}/api/user/${this.storeCreator}`);

      if (this.isStoreCreator) {
        this.storeCreatorName = "You";
      } else if (storeCreatorUser) {
        this.storeCreatorName = storeCreatorUser.username;
      } else {
        this.storeCreatorName = this.storeCreator;
      }

      if (this.$store.getters.isUserAuthenticated) {
        const storeAbi = await this.$storeAbiPromise;
        this.storeContract = new this.$root.web3.eth.Contract(storeAbi, this.$route.params.collection);
        this.contractBalance = this.$root.web3.utils.fromWei(await this.$root.web3.eth.getBalance(this.$route.params.collection), "ether");

        this.isStoreCreator = this.user.id == this.storeCreator;
        await this.getBalances();
      }
    } finally {
      this.finishedLoading = true;
    }
  },
  computed: {
    hasBalance() {
      return this.myBalance != "0" || this.my10GransBalance != "0";
    },
    user() {
      return this.$store.state.user;
    },
    currentPage: {
      get() {
        if (this.$route.params.page) {
          return Number(this.$route.params.page);
        } else {
          return 1;
        }
      },
      set(val) {
        if (!this.$route.params.collection) return;

        if (val > 1) {
          this.$router.push(`/collection/${this.$route.params.collection}/page/${val}`);
        } else {
          this.$router.push(`/collection/${this.$route.params.collection}`);
        }
      },
    },
  },
  asyncComputed: {
    nfts: {
      async get() {
        if (this.$route.params.collection) {
          const adultClause = this.$store.state.showAdult ? "adult=true" : "";
          const pageClause = `page=${this.currentPage}`;
          const storeClause = `store=${this.$route.params.collection}`;

          let res = [];
          try {
            this.finishedLoading = false;
            res = await shared.fetchJson(`${this.$apiBase}/api/nfts/latest?${adultClause}&${pageClause}&${storeClause}&showunverified=true`);
          } finally {
            this.finishedLoading = true;
          }

          // asynchronously fire off a lookahead query to see if there is another page available
          (async () => {
            const nextPageClause = `page=${this.currentPage + 1}`;
            const next = await shared.fetchJson(`${this.$apiBase}/api/nfts/latest?${adultClause}&${nextPageClause}&${storeClause}&showunverified=true`);
            this.nextPageAvailable = next.length > 0;
          })();

          return res;
        }
        return [];
      },
      default: [],
    },
  },
  methods: {
    async asyncFilter(arr, predicate) {
      const results = await Promise.all(arr.map(predicate));
      return arr.filter((_v, index) => results[index]);
    },
    async getBalances() {
      try {
        var wei = await this.storeContract.methods.ownerEthBalances(this.user.id).call();
      } catch (e1) {
        console.error("failed to get eth balance", e1);
        return;
      }
      this.myBalance = this.$root.web3.utils.fromWei(wei, "ether");

      try {
        var tenGransWei = await this.storeContract.methods.ownerTokenBalances(this.user.id).call();
      } catch (e2) {
        console.error("failed to get token balance");
        return;
      }
      this.my10GransBalance = new BigNumber(tenGransWei).div(new BigNumber(10).pow(this.$tokenDecimals));
    },
    async withdraw() {
      await this.getBalances();
      if (this.hasBalance()) {
        //don't wait.
        try {
          this.storeContract.methods.withdraw().send({ from: this.user.id });
        } catch (e) {
          console.error("Failed to withdraw", e);
          return;
        }

        this.myBalance = "0";
        this.my10GransBalance = "0";

        document.getElementById("withdraw-notification").classList.remove("is-hidden");
      }
    },
    deleteNotif: function() {
      const notification = document.getElementById("withdraw-notification");
      notification.parentNode.removeChild(notification);
    },
  },
};
</script>
