<template>
  <div class="container homepage">
    <p class="error">{{ error }}</p>

    <div v-if="showSiteMessage" class="site-message">
      <div class="dot" />
      <p>Notice: if you're having trouble minting, please join our <a href="https://discord.gg/2vq4WcD">Discord</a></p>
      <CloseModal alt="close message" class="close-message" @click="showSiteMessage = false" />
    </div>

    <div class="homepage-top" v-if="nftsFinishedLoading && this.nfts.length > 0">
      <FeaturedCard :nft="this.featuredNft" />
    </div>
    <div class="homepage-top" v-else-if="backendFailure">
      <h1 class="error">Token.gallery backend down.<br />Please try later</h1>
    </div>
    <div class="homepage-top" v-else-if="!nftsFinishedLoading">
      <h1>Loading...</h1>
    </div>
    <div class="homepage-top" v-else>
      <h1>Nothing to show</h1>
      <h3><router-link to="/" class="button button-round-accent">Homepage</router-link></h3>
    </div>

    <div class="filter-switches">
      <div class="left">
        <button class="button-round-white">Newest Mints</button>
        <!--
          <button class="button-round-darkgray">Most Activity</button>
          <button class="button-round-darkgray">Random</button>
        -->
      </div>
      <div class="right">
        <div class="switch">
          <input class="switch__input" type="checkbox" id="verified-only" name="verified-only" v-model="showVerified" />
          <label class="switch__label" for="verified-only">Verified Only</label>
        </div>
        <div class="switch">
          <input class="switch__input" type="checkbox" id="for-sale" name="for-sale" v-model="latestForSale" />
          <label class="switch__label" for="for-sale">For Sale Only</label>
        </div>
      </div>
    </div>

    <div v-if="nftsFinishedLoading && this.nfts.length > 0" id="main-grid" class="card-grid">
      <NftCard v-for="(nft, index) of nfts.slice(1)" :key="index" :nft="nft" />
    </div>

    <div class="paging">
      <router-link class="button button-square-accent" :to="prevPageLink || '#'" :class="{ disabled: !prevPageLink }">← Prev Page </router-link>
      <span class="current-page">{{ currentPage }}</span>
      <router-link class="button button-square-accent" :to="nextPageLink || '#'" :class="{ disabled: !nextPageLink }">Next Page →</router-link>
    </div>
  </div>
</template>

<style scoped>
.container.homepage {
  margin-top: 0;
}

.newest-nfts {
  margin-top: 80px;
  text-align: center;
}

.latest-options {
  margin-bottom: 32px;
  text-align: center;
}

.latest-options p {
  line-height: 24px;
}

h1 {
  text-align: center;
}

h3 {
  text-align: center;
}

.site-message {
  position: absolute;
  right: 0;

  max-width: max-content;
  margin-right: 24px;
  padding: 14px 21px;
  margin-top: 8px;
  border-radius: 100px;

  display: flex;
  align-items: center;

  background-color: var(--purple-background);
  color: var(--offwhite);
  font-size: 13px;
  line-height: 120%;
  letter-spacing: 0.75px;
  font-weight: 600;
  text-transform: uppercase;
}

.site-message .dot {
  background-color: #f4b740;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  border: 1px solid #14142b;
  margin-right: 8px;
}

.site-message h2 {
  text-align: center;
  font-size: 32px;
}

.site-message a {
  font-weight: 700;
  color: var(--accent);
}

.site-message .close-message {
  position: absolute;
  right: -2px;
  top: -2px;
  cursor: pointer;
}

.homepage-top {
  padding-top: 150px;
}

.paging {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 64px;
  text-align: center;
}

.current-page {
  margin: 0 32px;
  font-weight: 700;
  font-size: 38px;
}

.filter-switches {
  position: relative;
  z-index: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 150px;
  margin-bottom: 40px;
}

.filter-switches .left,
.filter-switches .right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
}

.filter-switches button {
  font-weight: 400;
}

.switch:first-child {
  margin-right: 24px;
}

.switch {
  display: inline-block;
  position: relative;
  margin: 0 0 10px;
  font-size: 16px;
  line-height: 24px;
}

.switch__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 20px;
  opacity: 0;
  z-index: 0;
}
.switch__label {
  display: block;
  padding: 0 0 0 44px;
  cursor: pointer;
  margin-left: 12px;
  font-weight: 600;
}
.switch__label:before {
  content: "";
  position: absolute;
  left: 0;
  width: 48px;
  height: 24px;
  background-color: var(--gray);
  border-radius: 40px;
  z-index: 1;
  transition: background-color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
.switch__label:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  background-color: white;
  border-radius: 100%;
  z-index: 2;
  transition: all 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: left, background-color;
}
.switch__input:checked + .switch__label:before {
  background-color: var(--accent);
}

.switch__input:checked + .switch__label:after {
  left: 24px;
  background-color: white;
}
</style>

<script>
import NftCard from "../components/NftCard.vue";
import FeaturedCard from "../components/FeaturedCard.vue";
import shared from "../shared";
import CloseModal from "@/assets/images/close-modal.svg";

export default {
  props: ["page"],
  components: {
    NftCard,
    FeaturedCard,
    CloseModal,
  },
  data: function() {
    return {
      nfts: [],
      contracts: {},
      error: "",
      backendFailure: false,
      nftsFinishedLoading: false,
    };
  },
  metaInfo() {
    return {
      meta: [{ description: "nfts and digital asset accessories" }],
    };
  },
  computed: {
    showVerified: {
      get() {
        return !this.showUnverified;
      },
      set(val) {
        this.showUnverified = !val;
      },
    },
    showUnverified: {
      get() {
        return this.$store.state.showUnverified;
      },
      set(val) {
        this.$store.commit("updateShowUnverified", val);
      },
    },
    latestForSale: {
      get() {
        return this.$store.state.latestForSale;
      },
      set(val) {
        this.$store.commit("updateLatestForSale", val);
      },
    },
    latestCurrency: {
      get() {
        return this.$store.state.latestCurrency;
      },
      set(val) {
        if (["any", "ubiq", "grans"].includes(val)) {
          this.$store.commit("updateLatestCurrency", val);
        }
      },
    },
    showSiteMessage: {
      get() {
        return this.$store.state.showSiteMessage;
      },
      set(val) {
        this.$store.commit("updateShowSiteMessage", val);
      },
    },
    featuredNft() {
      /*
[9:10 AM] Moon: need to find a way to make what's on the front page fair though otherwise it will just be whomever last entered stuff.
[9:11 AM] rwx: That is fair
[9:11 AM] rwx: I’m being 100% serious
[9:11 AM] rwx: The featured banner rotation is the solve for that
[9:11 AM] rwx: Actual CHRONOLOGICAL DISCOVERY MAKES SENSE
[9:12 AM] rwx: that’a my opinion
      */
      if (this.nfts.length > 0) {
        return this.nfts[0];
      }

      return null;
    },
    currentPage() {
      if (this.$route.params.page) {
        return Number(this.$route.params.page);
      } else {
        return 1;
      }
    },
    prevPageLink() {
      if (this.currentPage > 2) {
        return `/page/${this.currentPage - 1}`;
      } else if (this.currentPage == 2) {
        return "/";
      } else {
        return null;
      }
    },
    nextPageLink() {
      // this code is a bit lazy; ideally, we should fetch ahead and see if there is actually another page of NFTs to load
      // but we don't. this will show if any NFTs are present on current page.
      if (this.nfts.length > 0) {
        return `/page/${this.currentPage + 1}`;
      } else {
        return null;
      }
    },
  },
  asyncComputed: {
    nfts: {
      async get() {
        try {
          const adultClause = this.$store.state.showAdult ? "adult=true" : "";
          const pageClause = `page=${this.currentPage}`;
          const forSaleClause = this.latestForSale ? `forsale=${this.latestCurrency}` : "";
          const showUnverifiedClause = this.showUnverified ? "showunverified=true" : "showunverified=false";

          const res = await shared.fetchJson(`${this.$apiBase}/api/nfts/latest?${adultClause}&${pageClause}&${forSaleClause}&${showUnverifiedClause}`);
          this.nftsFinishedLoading = true;
          return res;
        } catch (e) {
          console.error(e);
          this.backendFailure = true;
          return [];
        }
      },
      default: [],
    },
  },
};
</script>
