<template>
  <div class="container">
    <div v-if="mintingState == 'waiting-for-upload'">
      <h1>Waiting for NFTs to upload... {{ uploadProgress }}</h1>
      <h2>Your NFT metadata is being uploaded to IPFS.</h2>
    </div>

    <div v-if="mintingState == 'waiting-for-signature'">
      <h1>Waiting for your signature...</h1>
      <h2>You should see a prompt from your app / extension to sign for this transaction.</h2>
    </div>

    <div v-if="mintingState == 'waiting-for-confirmation'">
      <h1>Your NFT is being minted.</h1>
      <h2 v-if="confirmedOnce"><span class="success">Received one blockchain confirmation.</span> Waiting for one more...</h2>
      <h2 v-else><span class="error">Do not close this window.</span> This should take between 10-120 seconds.</h2>
      <img class="loading" src="/hourglass.gif" />
    </div>

    <div v-if="mintingState == 'error'">
      <h1 class="error">{{ errorMinting }}</h1>
      <h1 class="sub-error">{{ errorMinting_secondary }}</h1>
      <h2>Please contact support, or retry your transaction</h2>
      <button class="button button-round-accent" id="retry-button" @click="retryMint">Retry</button>
    </div>

    <div v-if="mintingState == 'complete'">
      <h1>Mint complete!</h1>
      <h2>Taking you to your collection page in 2 seconds.</h2>
    </div>
  </div>
</template>

<style scoped>
.container {
  text-align: center;
}

h1 {
  color: var(--green-success);
  font-size: 48px;
  line-height: 58px;
  font-weight: bold;
  margin-bottom: 24px;
}

h1.sub-error {
  color: var(--red);
  font-size: 24px;
  line-height: 34px;
  font-weight: bold;
  margin-bottom: 24px;
}

h2 {
  color: var(--offwhite);
  font-size: 24px;
  line-height: 34px;
  font-weight: normal;
  margin-bottom: 24px;
}

button {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  padding: 16px 24px;
}

.success {
  color: var(--green-success);
}
</style>

<script>
import shared from "@/shared";
const BigNumber = require("bignumber.js");
BigNumber.set({ DECIMAL_PLACES: 18, ROUNDING_MODE: BigNumber.ROUND_FLOOR });

export default {
  data() {
    return {
      state: "waiting-for-upload",
      confirmedOnce: false,
      uploadProgress: "",
      errorMinting: "",
      errorMinting_secondary: "",
    };
  },
  async created() {
    const storeAbi = await this.$storeAbiPromise;
    this.storeContract = new this.$root.web3.eth.Contract(storeAbi, this.form.collection.address);

    this.mint();
  },
  computed: {
    mintingState: {
      get() {
        return this.state;
      },
      set(value) {
        this.state = value;
      },
    },
    form() {
      return this.$store.state.simpleSetForms[0];
    },
    apiToken() {
      return this.$store.state.user.token;
    },
    user() {
      return this.$store.state.user;
    },
    isForSale() {
      if (this.form.prices.ethForSale) {
        return true;
      } else if (this.form.prices.tokenForSale) {
        return true;
      } else {
        return false;
      }
    },
    count() {
      if (this.form.count == 0) return 1;
      return this.form.count;
    },
  },
  methods: {
    async retryMint() {
      this.mint();
    },
    async mint() {
      // create metadata
      console.log("form: " + JSON.stringify(this.form));
      console.debug("Uploading metadata...");
      this.mintingState = "waiting-for-upload";

      const metadatas = this.makeMetadatas();
      const imageBlob = await this.getImageBlob();

      // upload metadata
      const ipfsHashes = await this.putMetadataIpfs_sameImage(
        this.storeContract._address,
        metadatas,
        imageBlob,
        this.user.id,
        this.apiToken,
        `${this.$apiBase}/api/nft/upload`,
        this.$root.web3.utils
      ).catch((error) => {
        this.mintingState = "error";
        this.errorMinting = "Error uploading NFT metadata";
        this.errorMinting_secondary = error.message;
        throw Error(`mint(); received error: ${error.message}`);
      });

      console.debug("Upload complete. received IPFS hashes: ", ipfsHashes);
      if (ipfsHashes.length !== metadatas.length) {
        // something went terribly wrong
        this.mintingState = "error";
        this.errorMinting = "Metadata count mismatch";
      }

      // Mint NFT
      this.mintingState = "waiting-for-signature";

      // Calculate prices
      let ethForSale = 1; // 1 means not for sale, 0 means for sale
      let tokenForSale = 1;
      let ethPrice = 0;
      let tokenPrice = 0;

      try {
        if (this.form.prices.ethForSale) {
          ethForSale = 0;
          ethPrice = new BigNumber(this.form.prices.ethPrice).times("1e18").toString(10);
        } else if (this.form.prices.tokenForSale) {
          tokenForSale = 0;
          tokenPrice = new BigNumber(this.form.prices.tokenPrice).times("1e18").toString(10);
        }
      } catch (error) {
        console.error("error calculating prices; ", error);
        this.mintingState = "error";
        this.errorSettingPrice = "Error calculating prices";
        this.errorSettingPrice_secondary = error.message;
        return;
      }

      // Construct NFT object
      let nfts = [];
      for (let i = 0; i < ipfsHashes.length; i++) {
        nfts.push({
          owner: this.user.id,
          metadataIpfsHash: ipfsHashes[i].substring(7),
          price: ethPrice,
          forSale: ethForSale,
          tokenPrice: tokenPrice,
          tokenForSale: tokenForSale,
          dataLocked: true,
        });
      }

      console.log("nfts: ");
      console.log(JSON.stringify(nfts));

      this.$gtag.event("mint", { count: this.count });

      await this.storeContract.methods
        // 'true' here determines whether they all get the same meta id, and therefore are part of a set
        .mintNonFungible(true, nfts)
        .send({ from: this.user.id })
        .on("transactionHash", () => {
          this.mintingState = "waiting-for-confirmation";
          this.confirmedOnce = false;
        })
        .on("confirmation", (confirmationNumber) => {
          if (confirmationNumber == 0) {
            console.debug(`Received ${confirmationNumber} blockchain confirmations`);
            this.mintingState = "waiting-for-confirmation";
            this.confirmedOnce = true;
          } else if (confirmationNumber == 1) {
            console.debug(`Received ${confirmationNumber} blockchain confirmations`);
            // Done! now redirect to collection page.
            // (wait to prevent this.mintingState race condition)
            setTimeout(() => this.handleComplete(), 250);
          }
        })
        .catch((error) => {
          this.mintingState = "error";
          const msg = shared.handleTransactionError(error);
          this.errorMinting = msg.errorMessage;
          this.errorMinting_secondary = msg.errorMessageSecondary;
        });
    },
    async handleComplete() {
      this.mintingState = "complete";

      setTimeout(() => {
        this.$router.push("/collection/" + this.form.collection.address);
      }, 2000);
    },
    async putMetadataIpfs_sameImage(store, metadatas, imageFile) {
      // This function handles the upload of the same image one or more times in a set.
      // When we implement batch upload of different images, make a new function like 'putMetadataIpfs_diffImages'

      let ret = []; // return array of IPFS hashes representing metadatas

      if (metadatas.length > 1) {
        this.uploadProgress = `(uploading image)`;
      }

      // Upload first metadata differently from the rest
      const initialFormData = new FormData();
      initialFormData.append("storeAddress", store);
      initialFormData.append("file", imageFile);
      const initialMetadata = JSON.stringify(metadatas[0]); // Stringified because order in json can't be guaranteed and we need to hash it.
      initialFormData.append("metadata", initialMetadata);

      const initialHashString = `${store} ${initialMetadata}`;
      const initialResponse = await shared.uploadForm(
        `${this.$apiBase}/api/nft/upload`,
        initialFormData,
        "POST",
        initialHashString,
        this.user.id,
        this.apiToken,
        this.$root.web3.utils
      );
      ret.push(initialResponse.metadata);

      for (let i = 1; i < metadatas.length; i++) {
        this.uploadProgress = `(uploading metadata ${i + 1}/${metadatas.length})`;

        // pull image/preview hashes from response and use in subsequent metadatas
        metadatas[i].image = initialResponse.image;
        if (initialResponse.preview) metadatas[i].preview = initialResponse.preview;

        // upload metadata
        const formData = new FormData();
        formData.append("storeAddress", store);
        const metadata = JSON.stringify(metadatas[i]); // Stringified because order in json can't be guaranteed and we need to hash it.
        formData.append("metadata", metadata);

        const hashString = `${store} ${metadata}`;
        const response = await shared.uploadForm(`${this.$apiBase}/api/nft/upload`, formData, "POST", hashString, this.user.id, this.apiToken, this.$root.web3.utils);
        ret.push(response.metadata);
      }

      return ret;
    },
    makeMetadatas() {
      const metadatas = [];

      for (let i = 0; i < this.count; ++i) {
        let metadata = {
          name: this.form.name,
          description: this.form.description,
          properties: {
            adult: this.form.adult,
          },
        };

        if (this.form.tags && this.form.tags.length > 0) {
          metadata.properties.tags = this.form.tags;
        }

        if (this.count > 1) {
          metadata.properties.set = {
            name: this.form.name,
            number: i + 1,
            of: this.count,
          };
        }

        for (const r of this.form.extra) {
          metadata.properties[r.name] = r.value;
        }

        metadatas.push(metadata);
      }

      return metadatas;
    },
    async getImageBlob() {
      const image = await this.$getItem(`image/${this.form.id}`);

      if (image) {
        return image;
      } else {
        console.error("Failed to get image!");
        throw new Error("Unable to get image");
      }
    },
  },
};
</script>
