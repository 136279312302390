<template>
  <span v-if="isEllipsized()" class="ellipsized" v-bind:title="content"
    ><span class="ellipsized-content">{{ ellipsizedContent }}</span
    ><span class="ellipsis">…</span></span
  >
  <span v-else>{{ content }}</span>
</template>

<style scoped></style>

<script>
export default {
  data: function() {
    return {};
  },
  computed: {
    ellipsizedContent: function() {
      if (this.isEllipsized()) {
        return this.content.substring(0, this.maxLength - 3);
      } else {
        return this.content;
      }
    },
  },
  methods: {
    isEllipsized: function() {
      return this.content.length > this.maxLength;
    },
  },
  props: {
    maxLength: {
      type: Number,
      default: 14,
    },
    content: String, // regular string NOT html
  },
  async created() {},
};
</script>
