<template>
  <div class="container">
    <CreateNftHeader h1="Let's get started." h2="Do you want to sell this NFT one time or multiple times?" :step-current="1" :step-max="5" />

    <h2 class="subheading">Choose your creation method.</h2>

    <section class="creation-method">
      <div class="methods">
        <router-link to="/createnft/upload" @click.native="setShowCountFalse" class="single">
          <!--        <router-link :to="{ name: '/createnft/upload', params: {creationType: 'single' }}" class="single"> -->
          <Single />
          <div class="card-text">
            <h3>Create a single</h3>
            <p>Sell a "one of a kind" NFT</p>
          </div>
        </router-link>
        <router-link to="/createnft/upload" @click.native="setShowCountTrue" class="multiple">
          <Multiple />
          <div class="card-text">
            <h3>Create multiples</h3>
            <p>Sell multiple copies of one NFT</p>
          </div>
        </router-link>
      </div>
    </section>
  </div>
</template>

<style scoped>
.methods {
  display: flex;
}

.methods > * {
  margin-right: 32px;
  background-color: var(--card-background);
  border-radius: 0px 0px 8px 8px;
  text-decoration: none;
}

.methods .card-text {
  display: flex;
  flex-direction: column;
}

.methods h3 {
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 16px;
}

.methods p {
  text-align: center;
  margin-bottom: 32px;
}
</style>

<script>
import CreateNftHeader from "@/components/CreateNftHeader.vue";
import Single from "@/assets/images/single.svg";
import Multiple from "@/assets/images/multiple.svg";

export default {
  components: {
    CreateNftHeader,
    Single,
    Multiple,
  },
  created() {},
  methods: {
    setShowCountTrue() {
      this.$store.commit("setShowCount", true);
    },
    setShowCountFalse() {
      this.$store.commit("setShowCount", false);
    },
  },
};
</script>
