<template>
  <div class="container">
    <CreateNftHeader h1="Put your NFT in a collection." h2="Every NFT needs to live inside of a collection. Where will yours live?" :step-current="5" :step-max="5" />
    <h2 class="subheading">Create a collection, or choose an existing one.</h2>

    <div id="collection-grid" class="card-grid" v-if="collections.length > 0">
      <!--<div v-for="c in collections" :key="c.address" @click="selectCollection(i)" class="collection">-->
      <div v-for="c in collections" :key="c.address" class="collection">
        <CollectionCard class="hoverable" :symbol="c.symbol" :name="c.name" :address="c.address" :clickAction="selectCollection" :selectedCollection="selected" />
      </div>
    </div>
    <div v-else>
      <h3>Loading...</h3>
    </div>

    <router-link to="/createnft/confirm" class="button button-round-accent disabled" id="continue-button">Continue</router-link>

    <div v-if="createCollectionState" id="overlay" />
    <aside v-if="createCollectionState" id="create-collection">
      <form class="create-collection-inner" v-if="createCollectionState == 'create-store-form'" id="create-store-form">
        <div class="heading-container">
          <WhiteXIcon class="close-collection-icon" alt="Close collection form" @click="closeCreateCollectionPane" />
          <h2>Create a collection</h2>
        </div>

        <h3>Required - you won’t be able to edit the name or symbol after creation.</h3>
        <h4>Each of your collections must have a unique name and symbol.</h4>
        <input type="text" id="input-name" name="name" v-model="name" placeholder="Collection name" maxlength="40" />
        <div class="field-info">
          <span v-if="displayNameError" class="field-error">{{ nameError }}</span>
          <span class="field-maxlen">{{ name.length }}/40</span>
        </div>

        <input type="text" id="input-symbol" name="symbol" v-model="symbol" placeholder="Symbol - EX. GIGL. FOX, ANIME" maxlength="10" pattern="[a-zA-Z0-9]*" />
        <div class="field-info">
          <span v-if="displaySymbolError" class="field-error">{{ symbolError }}</span>
          <span class="field-maxlen">{{ symbol.length }}/10</span>
        </div>

        <p class="fee-message">Creating a collection has a <strong>one-time network fee.</strong> <br />Afterwards, you can put any NFTs into it.</p>

        <button type="button" class="button button-round-accent" id="create-collection-button" :class="{ disabled: formError }" @click="createCollection">Create collection</button>
      </form>

      <section class="waiting create-collection-inner" v-if="createCollectionState == 'waiting-for-signature'" id="waiting-for-signature">
        <h2>Waiting for your signature...</h2>
        <p>Check your wallet app of choice</p>
        <button class="button button-round-accent" id="retry-button" @click="openCreateCollectionPane">Retry</button>
      </section>

      <section class="waiting create-collection-inner" v-if="createCollectionState == 'waiting-for-confirmation'" id="waiting-for-confirmation">
        <h2 id="creating-message">Creating your collection on the blockchain...</h2>
        <p>This should take between 10-120 seconds</p>
        <img class="loading" src="/hourglass.gif" />
      </section>

      <section class="waiting create-collection-inner" v-if="createCollectionState == 'error'" id="transaction-error">
        <h2 id="creating-message" class="error">{{ error }}</h2>
        <h2 class="sub-error error">{{ error_secondary }}</h2>
        <p>Please contact support, or retry your transaction</p>
        <button class="button button-round-accent" id="retry-button" @click="openCreateCollectionPane">Retry</button>
      </section>
    </aside>
  </div>
</template>

<style scoped>
#collection-grid.card-grid {
  justify-content: left;
}

#continue-button {
  margin-top: 40px;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
}

#create-collection {
  position: fixed;
  width: 42%;
  min-width: 815px;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background-color: #011313;
}

.create-collection-inner {
  display: flex;
  flex-direction: column;
  margin: 0 10%;
}

#create-collection .heading-container {
  padding-top: 32px;
}

#create-collection .heading-container h2 {
  font-size: 32px;
  line-height: 38px;
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 0;
}

.close-collection-icon {
  display: block;
  margin-left: auto;
  cursor: pointer;
}

#create-collection h3 {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  margin-top: 32px;
}

#create-collection h4 {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
}

#create-collection .fee-message {
  color: var(--green-success);
  font-size: 20px;
  line-height: 26px;
}

#create-collection form button {
  margin-top: 32px;
  margin-left: 0;
  margin-right: 0;
}

.field-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 24px;
}

.field-error {
  color: var(--red);
  font-size: 14px;
}

.field-maxlen {
  flex-grow: 1;
  margin-left: 16px;
  text-align: right;
  color: var(--card-foreground);
  white-space: nowrap;
}

#input-symbol {
  text-transform: uppercase;
}

.waiting {
  height: 100%;
  justify-content: center;
  text-align: center;
}

.waiting h2 {
  color: var(--green-success);
  margin: 0 auto;
}

.waiting p {
  color: var(--offwhite);
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  margin-top: 16px;
  margin-bottom: 16px;
}

#retry-button {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}

.sub-error {
  margin-top: 16px !important;
  font-size: 0.8em;
}
</style>

<script>
import shared from "@/shared";
import CreateNftHeader from "@/components/CreateNftHeader.vue";
import CollectionCard from "@/components/CollectionCard.vue";
import WhiteXIcon from "@/assets/images/white-x.svg";

export default {
  components: {
    CreateNftHeader,
    CollectionCard,
    WhiteXIcon,
  },
  data: function() {
    return {
      collections: [],
      selected: null,
      name: "",
      symbol: "",
      factory: null,
      error: "",
      createCollectionState: "",
      displayNameError: false,
      displaySymbolError: false,
    };
  },
  async created() {
    // It takes a moment for the collections to update on the backend... let's make two attempts at refreshing
    setTimeout(() => {
      this.refreshCollections();
    }, 500);

    setTimeout(() => {
      this.refreshCollections();
    }, 2000);
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    nameError() {
      console.log("nameError");
      if (this.name == "") {
        return "Name cannot be empty";
      }

      const existingCollections = this.collections.find((c) => c.name == this.name);
      if (existingCollections) {
        return "You already have a collection with this name";
      }

      return null;
    },
    symbolError() {
      if (this.symbol == "") {
        return "Symbol cannot be empty";
      }

      const existingCollections = this.collections.find((c) => c.symbol == this.symbol.toUpperCase());
      if (existingCollections) {
        return "You already have a collection with this symbol";
      }

      return null;
    },
    formError() {
      return (this.displayNameError && this.nameError) || (this.displaySymbolError && this.symbolError);
    },
  },
  methods: {
    selectCollection(address) {
      console.log("selectCollection called with " + address);
      this.selected = address;

      // if clicking 'create new collection...'
      if (address == "_new_") {
        this.openCreateCollectionPane();
        document.getElementById("continue-button").classList.add("disabled");
      } else {
        document.getElementById("continue-button").classList.remove("disabled");

        // Set the desired collection on the form
        const selectedCollectionObj = this.collections.find((c) => c.address == address);
        this.$store.commit("updateSimpleSetFormField", { index: 0, field: "collection", value: selectedCollectionObj });
      }
    },
    openCreateCollectionPane() {
      this.name = "";
      this.symbol = "";
      this.createCollectionState = "create-store-form";
    },
    closeCreateCollectionPane() {
      this.createCollectionState = "";
    },
    async refreshCollections() {
      const apiFetch = await shared.fetchJson(`${this.$apiBase}/api/stores/${this.user.id}`);

      const createNew = {
        address: "_new_",
        name: "Create new collection",
        symbol: "_new_",
        creator: "",
      };

      this.collections = [createNew].concat(apiFetch);
    },
    async createCollection() {
      console.debug("Validating collection details...");

      const notValid = this.nameError || this.symbolError;

      if (notValid) {
        this.displayNameError = true;
        this.displaySymbolError = true;

        return;
      }

      console.debug("Creating a new collection");
      this.createCollectionState = "waiting-for-signature";

      this.symbol = this.symbol.toUpperCase();
      const factoryAbi = await this.$factoryAbiPromise;
      this.factory = new this.$root.web3.eth.Contract(factoryAbi, this.$factoryAddress);

      try {
        // note that using newstore automatically appends the store contract address to metadata uri.
        window.newStoreTransaction = await this.factory.methods
          .newStore(this.name, this.symbol)
          .send({ from: this.user.id })
          .on("transactionHash", (hash) => {
            console.debug(`Setting transaction: ${hash}`);
            window.transaction = hash;

            this.createCollectionState = "waiting-for-confirmation";
          })
          .on("receipt", (receipt) => {
            console.debug("Received receipt of blockchain confirmation: " + JSON.stringify(receipt));
          })
          .on("confirmation", (confirmationNumber) => {
            if (confirmationNumber == 0) {
              console.debug(`Received ${confirmationNumber} blockchain confirmations`);
              document.getElementById("creating-message").innerText = "Received one blockchain confirmation. Waiting for one more...";
            } else if (confirmationNumber == 1) {
              console.debug(`Received ${confirmationNumber} blockchain confirmations`);
              this.createCollectionState = ""; // done
              return;
            }
          })
          .catch((error) => {
            this.createCollectionState = "error";
            const msg = shared.handleTransactionError(error);
            this.error = msg.errorMessage;
            this.error_secondary = msg.errorMessageSecondary;
          });
      } finally {
        this.refreshCollections();
      }
    },
  },
};
</script>
