<template>
  <div>
    <p v-if="activity.name == 'TransferSingle'">
      <span v-if="activity.from == burned" class="green">
        Minted
      </span>
      <span v-else-if="activity.to == burned" class="red">
        <span class="gray">{{ edition }}</span> burned by
        <UserLink :name="activity.fromUser.username" :address="activity.fromUser.id" :verified="activity.fromUser.verified" :accent="true" />
      </span>
      <span v-else>
        <span class="gray">{{ edition }}</span> Transferred from
        <UserLink :name="activity.fromUser.username" :address="activity.fromUser.id" :verified="activity.fromUser.verified" :accent="true" /> to
        <UserLink :name="activity.toUser.username" :address="activity.toUser.id" :verified="activity.toUser.verified" :accent="true" />
      </span>
    </p>

    <p v-else-if="activity.name == 'BuySingleNft'">
      <span>
        <span class="gray">{{ edition }}</span> purchased for <Price :price="activity.price" symbol="UBQ" customFreeMessage="0.000 UBQ" /> by
        <UserLink :name="activity.toUser.username" :address="activity.toUser.id" :verified="activity.toUser.verified" :accent="true" />
      </span>
    </p>

    <p v-else-if="activity.name == 'TokenBuySingleNft'">
      <span>
        <span class="gray">{{ edition }}</span> purchased for <Price :price="activity.tokenPrice" symbol="GRANS" customFreeMessage="0.000 GRANS" /> by
        <UserLink :name="activity.toUser.username" :address="activity.toUser.id" :verified="activity.toUser.verified" :accent="true" />
      </span>
    </p>

    <p v-else-if="activity.name == 'PriceChange'">
      <span v-if="activity.forSale && activity.tokenForSale">
        <span class="gray">{{ edition }}</span> listed for sale by
        <UserLink :name="activity.ownerUser.username" :address="activity.ownerUser.id" :verified="activity.ownerUser.verified" :accent="true" /> for
        <Price :price="activity.price" symbol="UBQ" customFreeMessage="0.000 UBQ" /> and <Price :price="activity.tokenPrice" symbol="GRANS" customFreeMessage="0.000 GRANS" />
      </span>
      <span v-else-if="activity.forSale">
        <span class="gray">{{ edition }}</span> listed for sale by
        <UserLink :name="activity.ownerUser.username" :address="activity.ownerUser.id" :verified="activity.ownerUser.verified" :accent="true" /> for
        <Price :price="activity.price" symbol="UBQ" customFreeMessage="0.000 UBQ" />
      </span>
      <span v-else-if="activity.tokenForSale">
        <span class="gray">{{ edition }}</span> listed for sale by
        <UserLink :name="activity.ownerUser.username" :address="activity.ownerUser.id" :verified="activity.ownerUser.verified" :accent="true" /> for
        <Price :price="activity.tokenPrice" symbol="GRANS" customFreeMessage="0.000 GRANS" />
      </span>
      <span v-else-if="activity.ownerUser.id != burned">
        <span class="gray">{{ edition }}</span> sale cancelled by
        <UserLink :name="activity.ownerUser.username" :address="activity.ownerUser.id" :verified="activity.ownerUser.verified" :accent="true" />
      </span>
    </p>

    <p v-else-if="activity.name == 'CreatorTransferred'" class="red">
      <span>Collection ownership transferred</span>
    </p>
  </div>
</template>

<style scoped>
.green {
  color: var(--green-success);
}

.red {
  color: var(--red);
}

.gray {
  color: var(--offwhite);
}

.red .gray {
  /* red overwrites gray */
  color: var(--red);
}
</style>

<script>
import UserLink from "@/components/UserLink.vue";
import Price from "@/components/Price.vue";

export default {
  components: {
    UserLink,
    Price,
  },
  data() {
    return {
      burned: "0x0000000000000000000000000000000000000000",
    };
  },
  props: ["activity", "totalCount"],
  computed: {
    edition() {
      if (this.totalCount > 1) {
        return `Edition ${this.activity.metaId}/${this.totalCount}`;
      }
      return null;
    },
  },
};
</script>
