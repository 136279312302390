<template>
  <div class="nft">
    <div v-if="modal" id="overlay" :class="{ 'buy-modal': modal == 'buy' }" />
    <div v-if="modal" class="nftModalWrapper">
      <NftActionModal
        v-if="['buy', 'setPrice', 'removeFromSale', 'transfer', 'burn', 'share'].includes(modal)"
        class="nftModal"
        :action="modal"
        :collectionId="collectionId"
        :nftSet="nftSet"
        :nftsByPrice="nftsByPrice"
        :cancelCallbackFn="clearModal"
        :successCallbackFn="transactionComplete"
        @connect-wallet="$emit('connect-wallet')"
      />
    </div>

    <section class="top-half">
      <article class="image">
        <img v-if="nftSet.extra.adult && !showAdult" class="adult-hidden" :src="fetchImage(nftSet.image)" />
        <img v-else :src="fetchImage(nftSet.image)" @click="showImage" />
        <p class="adult-warning-message" v-if="nftSet.extra.adult && !showAdult">
          Image is marked as adult content. <br />Log in and go to your <router-link to="/settings">settings page</router-link> to enable adult content.
        </p>
      </article>

      <article class="primary-details">
        <div class="primary-details-inner">
          <div class="primary-details-metadata">
            <header>
              <div class="icons-container">
                <div class="favorites-container">
                  <HeartFilled v-if="favoriteStatus" alt="Favorites" id="favorite" @click="setFavorite" class="hoverable" />
                  <Heart v-else alt="Favorites" id="favorite" @click="setFavorite" class="hoverable" />
                  <span class="num">{{ favoritesCount || " " }}</span>
                </div>
                <div class="comments-container">
                  <Comment alt="Comments" id="comment" />
                  <span class="num">{{ commentsCount || " " }}</span>
                </div>
              </div>

              <div class="menu-container">
                <MenuIcon class="menu-icon hoverable" alt="Options menu" @click="showMenu = true" />
                <aside v-if="showMenu" class="menu">
                  <BlackXIcon class="close-icon" alt="Close menu" @click="showMenu = false" />
                  <p @click="showModal('setPrice')" :class="{ disabled: !userOwnsNft }" class="hoverable purple">
                    Set price
                  </p>
                  <p @click="showModal('transfer')" :class="{ disabled: !userOwnsNft }" class="hoverable green">
                    Transfer
                  </p>
                  <p @click="showModal('share')" class="hoverable">Share</p>
                  <p @click="showModal('removeFromSale')" :class="{ disabled: !userOwnsNftForSale }" class="hoverable">
                    Remove from sale
                  </p>
                  <p @click="showModal('burn')" class="hoverable red" :class="{ disabled: !userOwnsNft }">
                    Burn
                  </p>
                  <!--<p @click="showModal('report')" class="hoverable red">Report</p>-->
                  <p v-if="amAdmin" @click="hideNft" class="red hoverable">
                    Admin hide NFT
                  </p>
                  <p v-if="amAdmin" @click="unhideNft" class="red hoverable">
                    Admin restore NFT
                  </p>
                </aside>
              </div>
            </header>

            <h1>{{ nftSet.name }}</h1>

            <section v-if="!expandDesc && nftSet.description.length > descCutoffLength">
              <p class="desc"><Ellipsis :maxLength="descCutoffLength" :content="nftSet.description" /></p>
              <span class="desc-expand-button" @click="expandDesc = true">Read more</span>
            </section>
            <section v-else>
              <p class="desc">{{ nftSet.description }}</p>
            </section>

            <section class="flex">
              <div class="artist">
                <p class="title">Artist</p>
                <UserLink :name="creatorName" :address="creatorAddress" :verified="verified" />
              </div>
              <div>
                <p class="title">Collection</p>
                <router-link :to="{ name: 'Collection', params: { collection: collectionId } }">{{ collection.name }} ({{ collection.symbol }})</router-link>
              </div>
            </section>

            <section>
              <p class="title">Editions</p>
              <div class="quantity-wrapper">
                <LayersIcon alt="Quantity" />
                <span class="qty">x{{ totalCount }}</span>
              </div>
            </section>
          </div>

          <div class="primary-details-price">
            <p class="title">Price</p>
            <div class="price-crypto">
              <div v-if="bestPrice">
                <Price :price="bestPrice.priceCrypto" :symbol="bestPrice.symbol" />
                <p class="price-usd">${{ (bestPrice.priceUsd / 1e18).toFixed(2) }}</p>
              </div>
              <div v-else>
                <p>Not for sale</p>
              </div>
            </div>
            <button @click="showModal('buy')" type="button" :class="{ disabled: !bestPrice }" class="buy-button button-square-accent">Buy Now</button>
          </div>
        </div>
      </article>
    </section>

    <section class="bottom-half-wrapper">
      <div class="bottom-half">
        <div class="bottom-half-inner">
          <nav>
            <button :class="{ 'active-tab': activeTab == 'comments' }" @click="activeTab = 'comments'">Comments</button>
            <button :class="{ 'active-tab': activeTab == 'details' }" @click="activeTab = 'details'">Details</button>
            <button :class="{ 'active-tab': activeTab == 'activity' }" @click="activeTab = 'activity'">Activity</button>
            <button :class="{ 'active-tab': activeTab == 'owners' }" @click="activeTab = 'owners'">Editions</button>
          </nav>

          <article class="tab" id="comments-tab" :class="{ hidden: activeTab !== 'comments' }">
            <div v-if="comments.length > 0" class="comments-container">
              <div class="comment" v-for="c in comments" :key="c.id" :id="`comment-${c.id}`">
                <header>
                  <UserLink class="commenter" :name="c.username" :address="c.author" :verified="c.verified" :accent="false" />
                  <div v-if="amAdmin" class="menu-container">
                    <MenuIcon class="menu-icon hoverable" alt="Options menu" @click="showCommentMenuId = c.id" />
                    <aside v-if="showCommentMenuId == c.id" class="menu">
                      <BlackXIcon class="close-icon" alt="Close menu" @click="showCommentMenuId = ''" />
                      <p @click="deleteComment(c.id)" class="hoverable red">
                        Delete
                      </p>
                      <!--<p @click="promoteComment(c.id)" class="hoverable blue">
                        Promote
                      </p>-->
                    </aside>
                  </div>
                </header>
                <p>{{ c.content }}</p>
              </div>

              <p v-if="moreCommentsAvailable" @click="moreComments" class="comment view-more-comments">View more comments</p>
            </div>

            <div v-else class="comments-container">
              <div class="comment">
                <p class="no-comments">No comments yet</p>
              </div>
            </div>

            <form @submit.prevent="" class="comment-box">
              <input
                id="input-comment"
                name="commentField"
                type="text"
                v-model="commentField"
                placeholder="Say something nice..."
                maxlength="280"
                @keyup.enter="postComment"
                :disabled="!userAuthenticated"
              />
              <div class="field-info">
                <span v-if="!userAuthenticated" class="field-warn">You must connect your wallet before posting comments.</span>
                <span v-else class="field-error">{{ commentSubmitError }}</span>
                <span class="field-maxlen">{{ commentField.length }}/280</span>
              </div>
              <button @click="postComment" type="button" class="button-square-accent" :class="{ disabled: !userAuthenticated }">POST</button>
            </form>
          </article>

          <article class="tab" id="details-tab" :class="{ hidden: activeTab !== 'details' }">
            <div class="entry">
              <p class="title" v-if="Object.keys(owners).length <= 1">Owner</p>
              <p class="title" v-else>Owners</p>
              <div class="owners">
                <UserLink v-for="(user, address) in owners" :key="address" :name="user.username" :address="address" :verified="user.verified" :accent="false" />
              </div>
            </div>
            <div class="entry">
              <p class="title">Artist</p>
              <UserLink :name="creatorName" :address="creatorAddress" :verified="verified" :accent="false" />
            </div>
            <div class="entry">
              <p class="title">Collection</p>
              <p class="collection-value">
                <router-link :to="{ name: 'Collection', params: { collection: collectionId } }">{{ collection.name }} ({{ collection.symbol }})</router-link>
              </p>
            </div>
            <div class="entry">
              <p class="title">Quantity</p>
              <p>{{ totalCount }}</p>
            </div>
            <div v-if="this.nftSet.extra.adult" class="entry">
              <p class="title">Adult Content</p>
              <p>Yes</p>
            </div>
            <div class="entry" v-if="Object.keys(extraProperties).length > 0">
              <p class="title">Custom properties</p>
              <p v-for="(value, name) in extraProperties" :key="name">{{ name }}: {{ value }}</p>
            </div>
            <div class="entry">
              <p class="title">Contract Address</p>
              <p>{{ collectionId }} <CopyIcon class="copy-icon hoverable" alt="Copy the contract address to the clipboard" @click="copyToClipboard(collectionId)" /></p>
            </div>
            <div class="entry" v-if="userNfts.length > 0">
              <p class="title">My NFT IDs</p>
              <div class="my-nft-ids">
                <ul>
                  <li v-for="(n, idx) in userNfts" :key="n.id">
                    <span>{{ idx + 1 }} <CopyIcon class="copy-icon hoverable" alt="Copy the NFT ID to the clipboard" @click="copyToClipboard(n.id)"/></span>
                  </li>
                </ul>
              </div>
            </div>
          </article>

          <article class="tab" id="activity-tab" :class="{ hidden: activeTab !== 'activity' }">
            <div v-for="(activitiesPerBlock, blockNum) in activities" :key="blockNum" class="entry">
              <p class="title">
                <abbr :title="'Block ' + blockNum + ' @ ' + absoluteTime(activitiesPerBlock[0].timestamp)">{{ relativeTime(activitiesPerBlock[0].timestamp) }}</abbr>
              </p>
              <NftActivity v-for="(entry, idx) in activitiesPerBlock" :key="idx" :activity="entry" :totalCount="totalCount" class="timeline-activity-text" />
            </div>
          </article>

          <article class="tab" id="owners-tab" :class="{ hidden: activeTab !== 'owners' }">
            <div v-for="nft in nftSet.nfts" :key="nft.id" class="entry">
              <p class="title">Edition {{ nft.number }} of {{ totalCount }}</p>
              <div class="owners-tab-entry">
                <span class="owners-tab-owner" v-if="nft.owner != burned">
                  <span class="owners-tab-owner-pre">Owned by </span>
                  <UserLink v-if="owners[nft.owner]" :name="owners[nft.owner].username" :address="nft.owner" :verified="owners[nft.owner].verified" :accent="true" />
                </span>
                <span class="owners-tab-owner red" v-else>
                  Burned
                </span>

                <span class="owners-tab-price" v-if="nft.forSale == true">
                  For sale — <Price :price="nft.price" symbol="UBQ" /> (${{ roundTo2Decimals((nft.price / 1e18) * ethPrice) }})
                </span>
                <span class="owners-tab-price" v-else-if="nft.tokenForSale">
                  For sale — <Price :price="nft.tokenPrice" symbol="GRANS" /> (${{ roundTo2Decimals((nft.tokenPrice / 1e18) * tokenPrice) }})
                </span>
                <span class="owners-tab-price" v-else>
                  Not for sale
                </span>
              </div>
            </div>
          </article>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.nftModalWrapper {
  z-index: 16;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  border-radius: var(--modal-border-radius);

  background: var(--alt-background);
  color: var(--white);

  text-align: center;
}

.nftModal {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  width: 492px;
  padding: 24px;
  border-radius: var(--modal-border-radius);
}

.nftModal h1 {
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
}

.nftModal p {
  font-size: 16px;
  line-height: 22.4px;
  font-weight: 400;
}

.top-half {
  display: flex;
  flex-direction: row;
  min-height: 80vh;
}

.top-half .image {
  display: flex;
  justify-content: center;
  margin: auto;

  width: 70%;
  padding-top: var(--distance-from-nav);
  padding-bottom: 100px;
}

.top-half .image img {
  cursor: pointer;
  max-width: 75%;
  max-height: 60vh;
}

.top-half .image img.adult-hidden {
  filter: blur(2em);
  cursor: initial;
}

.adult-warning-message {
  position: absolute;
  top: 40%;
  margin: 1em 10em;
  padding: 1em;
  background: var(--black);
  text-align: center;
  border-radius: 8px;
}

.adult-warning-message a {
  font-weight: 600;
  text-decoration: underline;
  text-align: center;
}

.top-half .primary-details {
  padding-top: var(--distance-from-nav);
  background-color: var(--alt-background);
  width: 30%;
}

.top-half section.flex {
  display: flex;
  flex-direction: row;
}

.top-half section.flex > .artist {
  margin-right: 64px;
}

.primary-details-metadata {
  max-width: 551px;
  box-sizing: border-box;
}

.primary-details-inner > * {
  padding-left: 40px;
  padding-right: 40px;
}

.primary-details-inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: scale(1); /* hack to get .menu's position:fixed to treat this element as the parent */
}

.primary-details .icons-container {
  display: flex;
  align-items: center;
}

.primary-details .favorites-container {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.primary-details .comments-container {
  display: flex;
  align-items: center;
  margin-left: 12px;
}

#favorite {
  cursor: pointer;
}

.primary-details .num {
  color: var(--gray-label);
  margin-left: 8px;
}

.menu-container {
  margin-left: 16px;
}

.menu-icon {
  cursor: pointer;
  min-width: max-content;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  border-radius: 8px;
  min-width: 90px;

  position: fixed;
  right: 0;
  top: 0;

  background-color: var(--white);
  color: var(--card-background);
  size: 16px;
  line-height: 16px;
  font-weight: 600;
}

.menu p {
  cursor: pointer;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.menu .purple {
  color: var(--accent);
}

.menu .green {
  color: var(--green);
}

.menu .disabled {
  color: var(--offwhite) !important;
  cursor: not-allowed;
  pointer-events: none;
}

.primary-details h1 {
  font-size: 32px;
  line-height: 42px;
  font-weight: 700;
  margin-top: 24px;
  margin-bottom: 32px;
}

.primary-details section {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.primary-details .quantity-wrapper {
  display: flex;
  justify-content: left;
  align-items: center;
}

.primary-details .quantity-wrapper svg {
  margin-right: 8px;
}

.primary-details .quantity-wrapper .qty {
  color: var(--gray-label);
  font-weight: 600;
}

.primary-details .desc {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: var(--offwhite);
  white-space: pre-wrap;
}

.primary-details .desc-expand-button {
  cursor: pointer;
  color: var(--accent);
}

.primary-details-price {
  border-top: var(--border-soft);
  padding-top: 32px;
  margin-top: 32px;
}

.primary-details .price-crypto {
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 32px;
}

.primary-details .price-usd {
  color: var(--offwhite);
}

.primary-details .buy-button {
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 48px;
  text-transform: uppercase;
}

.title {
  color: var(--gray-label);
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.bottom-half-wrapper {
  /* hack to make the background on the top-right of .bottom-half look the same as the side panel */
  background: linear-gradient(to right, #000808, var(--alt-background));
}

.bottom-half {
  background-color: var(--alt-background-darker);
  border-radius: 32px 32px 0px 0px;
}

.bottom-half-inner {
  max-width: 834px;
  margin: auto;
  padding: 0 40px;
}

.bottom-half nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  border: none;
  margin-bottom: 0;
}

.bottom-half nav button {
  background-color: var(--gray);
  color: var(--white);
  font-weight: 400;
}

.bottom-half nav button.active-tab {
  background-color: var(--white);
  color: var(--black);
}

.bottom-half .tab {
  padding-top: 28px;
  padding-bottom: 40px;
  max-width: 730px;
  margin: 0 auto;
}

.bottom-half .tab.hidden {
  display: none !important;
}

.bottom-half .entry {
  margin-bottom: 32px;
}

.collection-value {
  line-height: 23px; /* matches UserLink height */
}

.owners {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -8px;
}

.owners .nft-user {
  margin-right: 32px;
  margin-bottom: 8px;
}

.timeline-activity-text {
  text-transform: uppercase;
  font-weight: 600;
  padding-top: 2px;
  padding-bottom: 2px;
}

#activity-tab {
  display: flex;
  flex-direction: column-reverse;
}

#owners-tab {
  font-weight: 600;
  text-transform: uppercase;
}

.owners-tab-entry {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: normal;
}

.owners-tab-owner {
  display: flex;
  margin-right: 8px;
  overflow-x: hidden;
}

.owners-tab-owner-pre {
  white-space: pre;
  line-height: 23px;
}

.owners-tab-owner,
.owners-tab-price {
  white-space: nowrap;
}

.red {
  color: var(--red);
}

.close-icon {
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
}

.comment {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}

.comment .commenter {
  font-weight: 700;
}

.comment p {
  margin-top: 6px;
  line-height: 24px;
}

.view-more-comments {
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--accent);
  margin-bottom: 40px;
}

.comment-box #input-comment {
  width: 100%;
  min-height: initial;
  height: 84px;
}

.comment-box button {
  margin: 8px 0;
  padding: 16px 32px;
}

.field-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 24px;
}

.field-maxlen {
  flex-grow: 1;
  margin-left: 16px;
  text-align: right;
  color: var(--card-foreground);
  white-space: nowrap;
}

.field-error {
  color: var(--red);
  font-size: 14px;
}

.field-warn {
  color: var(--offwhite);
  font-size: 14px;
}

.no-comments {
  font-style: italic;
  color: var(--offwhite);
}
</style>

<script>
import shared from "@/shared";
import * as basicLightbox from "basiclightbox";

import Ellipsis from "@/components/Ellipsis.vue";
import UserLink from "@/components/UserLink.vue";
import NftActivity from "@/components/NftActivity.vue";
import Price from "@/components/Price.vue";
import MenuIcon from "@/assets/images/menu.svg";
import CopyIcon from "@/assets/images/copy.svg";
import NftActionModal from "@/components/NftActionModal.vue";
import BlackXIcon from "@/assets/images/black-x.svg";
import LayersIcon from "@/assets/images/layers.svg";
import Heart from "@/assets/images/heart.svg";
import HeartFilled from "@/assets/images/heart-filled.svg";
import Comment from "@/assets/images/comment.svg";

const defaultNft = {
  name: "Loading...",
  cnt: 1,
  description: "",
  image: "/question.png",
  owner: "0x0000000000000000000000000000000000000000",
  extra: {
    adult: null,
  },
  nfts: [],
};

export default {
  components: {
    Ellipsis,
    UserLink,
    NftActivity,
    Price,
    MenuIcon,
    CopyIcon,
    NftActionModal,
    BlackXIcon,
    LayersIcon,
    Heart,
    HeartFilled,
    Comment,
  },
  data() {
    return {
      reloadCounter: 0,
      burned: "0x0000000000000000000000000000000000000000",
      descCutoffLength: 172,
      expandDesc: false,
      activeTab: "comments",
      showMenu: false,
      showCommentMenuId: "",
      modal: "",
      ethPrice: 0,
      tokenPrice: 0,
      userCache: {},
      commentField: "",
      commentSubmitError: "",
      comments: [],
      commentsPage: 1,
      moreCommentsAvailable: false,
      postingCommentMutex: false,
      verified: false,
    };
  },
  metaInfo() {
    if (this.nftSet) {
      return {
        meta: [{ description: `nft – ${this.nftSet.name} – ${this.nftSet.description}` }, { author: this.creatorName }],
      };
    }

    return {};
  },
  async created() {
    shared.getPrice(this.$apiBase).then((price) => {
      this.ethPrice = price.ubiqUsdRatio;
      this.tokenPrice = price.ubiqUsdRatio / price.ubiqGransRatio;
    });

    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.clearModal();
      }
    });
  },
  computed: {
    collectionId() {
      return this.$route.params.collection;
    },
    userAuthenticated() {
      return this.$store.getters.isUserAuthenticated;
    },
    user() {
      return this.$store.state.user;
    },
    amAdmin() {
      return this.user !== null && this.userAuthenticated && this.user.admin;
    },
    apiToken() {
      return this.$store.state.user.token;
    },
    showAdult: {
      get() {
        return this.$store.state.showAdult;
      },
    },
    userNfts() {
      if (this.userAuthenticated && this.nftSet) {
        const myNfts = this.nftSet.nfts.filter((x) => x.owner == this.user.id);
        console.log(`nfts value: ${myNfts}`);
        return myNfts;
      } else return [];
    },
    userOwnsNft() {
      // Return true if the user owns at least 1 nft from this set
      // Used to determine if menu buttons are clickable

      let found = false;
      if (this.userAuthenticated && this.nftSet) {
        if (this.nftSet.nfts.find((x) => x.owner == this.user.id)) {
          found = true;
        }
      }

      return found;
    },
    userOwnsNftForSale() {
      // similar to userOwnsNft, also ensures the nft is for sale
      // Used to allow 'remove from sale' button

      let found = false;
      if (this.userAuthenticated && this.nftSet) {
        if (this.nftSet.nfts.find((x) => x.owner == this.user.id && (x.forSale || x.tokenForSale))) {
          found = true;
        }
      }

      return found;
    },
    totalCount() {
      if (this.nftSet.cnt) {
        return this.nftSet.cnt;
      }
      return 1;
    },
    extraProperties() {
      let extraProperties = this.nftSet.extra;

      // filter out properties in shared.propertyNameBlacklist
      return Object.keys(extraProperties)
        .filter((name) => !shared.propertyNameBlacklist.includes(name))
        .reduce((obj, name) => {
          obj[name] = extraProperties[name];
          return obj;
        }, {});
    },
  },
  asyncComputed: {
    metaId: {
      async get() {
        let metaId = null;

        if (this.$route.params.nft) {
          // if we were passed an NFT ID, translate this into the metaId
          if (`${this.$route.params.nft}`.length < 30) {
            metaId = this.$route.params.nft; // actually was a metaId
          } else {
            metaId = await this.fetchMetaIdFromNftId(this.$route.params.nft);
          }

          // If we've never loaded comments before, load for the first time
          if (this.comments.length == 0) this.loadComments(metaId);
        }

        return metaId;
      },
      default: "",
    },
    nftSet: {
      async get() {
        this.reloadCounter; // increment reloadCounter to force re-compute
        if (this.metaId) {
          const resp = await shared.fetchJson(`${this.$apiBase}/api/store/${this.collectionId}/meta/${this.metaId}`);
          document.title = resp.name;
          return resp;
        } else {
          return defaultNft;
        }
      },
      default: defaultNft,
    },
    nftsByPrice: {
      async get() {
        // Sort NFTs by price (usd)
        // desired format: cheapest first, then most expensive, then not for sale.
        /*
          [
            {
              priceUsd: 20.1250,
              priceCrypto: 4000000,
              symbol: "UBQ",
              number: 7,
              nftId: "79370082988091483556062370546558190326696171840555214346936562602737667",
              ownerAddress: 0x1485eC40808Dcc87FdA84E84fFd6f134d2944b14,
              ownerName: "kian",
              ownerVerified: true,
            },
            {
              priceUsd: 37.8284,
              priceCrypto: 6000000,
              symbol: "UBQ",
              number: 4,
              nftId: "79370082988091483556062370546558190326696171840555214346936562602737664",
              ownerAddress: 0x1485eC40808Dcc87FdA84E84fFd6f134d2944b14,
              ownerName: "kian",
              ownerVerified: true,
            },
            {
              priceUsd: -1, // not for sale!
              nftId: "79370082988091483556062370546558190326696171840555214346936562602737662",
              number: 2,
            },
            {
              priceUsd: -1, // not for sale!
              nftId: "79370082988091483556062370546558190326696171840555214346936562602737663",
              number: 3,
            }
          ]
        */

        let unsorted_forSale = [];
        let unsorted_notForSale = [];
        for (let i = 0; i < this.nftSet.nfts.length; i++) {
          const seller = await this.fetchUser(this.nftSet.nfts[i].owner);

          if (this.nftSet.nfts[i].forSale || this.nftSet.nfts[i].tokenForSale) {
            // for sale
            const priceEth = this.nftSet.nfts[i].price;
            const priceToken = this.nftSet.nfts[i].tokenPrice;
            const priceUsdFromEth = this.ethPrice * priceEth;
            const priceUsdFromToken = this.tokenPrice * priceToken;

            if (this.nftSet.nfts[i].forSale && this.nftSet.nfts[i].tokenForSale) {
              if (priceUsdFromEth < priceUsdFromToken) {
                // if for sale in both grans+ubiq, pick the cheapest
                unsorted_forSale.push({
                  priceUsd: priceUsdFromEth,
                  priceCrypto: priceEth,
                  symbol: "UBQ",
                  nftId: this.nftSet.nfts[i].id,
                  number: this.nftSet.nfts[i].number,
                  ownerAddress: this.nftSet.nfts[i].owner,
                  ownerName: seller.username,
                  ownerVerified: seller.verified,
                });
              } else {
                unsorted_forSale.push({
                  priceUsd: priceUsdFromToken,
                  priceCrypto: priceToken,
                  symbol: "GRANS",
                  nftId: this.nftSet.nfts[i].id,
                  number: this.nftSet.nfts[i].number,
                  ownerAddress: this.nftSet.nfts[i].owner,
                  ownerName: seller.username,
                  ownerVerified: seller.verified,
                });
              }
            } else if (this.nftSet.nfts[i].forSale) {
              // for sale in ubiq
              unsorted_forSale.push({
                priceUsd: priceUsdFromEth,
                priceCrypto: priceEth,
                symbol: "UBQ",
                nftId: this.nftSet.nfts[i].id,
                number: this.nftSet.nfts[i].number,
                ownerAddress: this.nftSet.nfts[i].owner,
                ownerName: seller.username,
                ownerVerified: seller.verified,
              });
            } else if (this.nftSet.nfts[i].tokenForSale) {
              // for sale in grans
              unsorted_forSale.push({
                priceUsd: priceUsdFromToken,
                priceCrypto: priceToken,
                symbol: "GRANS",
                nftId: this.nftSet.nfts[i].id,
                number: this.nftSet.nfts[i].number,
                ownerAddress: this.nftSet.nfts[i].owner,
                ownerName: seller.username,
                ownerVerified: seller.verified,
              });
            }
          } else {
            // not for sale
            unsorted_notForSale.push({
              priceUsd: -1,
              number: this.nftSet.nfts[i].number,
              nftId: this.nftSet.nfts[i].id,
              ownerAddress: this.nftSet.nfts[i].owner,
              ownerName: seller.username,
              ownerVerified: seller.verified,
            });
          }
        }

        // sort it
        const comparator = (a, b) => {
          if (a.priceUsd == b.priceUsd) return a.number - b.number;
          return a.priceUsd - b.priceUsd;
        };

        const forSale = unsorted_forSale.sort(comparator);
        const notForSale = unsorted_notForSale.sort(comparator);

        return forSale.concat(notForSale);
      },
      default: [],
    },
    bestPrice: {
      async get() {
        // among all the NFTs in this.nftSet.nfts, find the best price, in terms of either grans or ubiq.
        // return { priceUsd: "1234.56", priceCrypto: "45000000000", symbol: "UBQ" }
        // or null if not for sale
        const ids = this.nftsByPrice;
        if (ids.length == 0) return null;
        if (ids[0].priceUsd == -1) return null;
        return ids[0];
      },
      default: null,
    },
    collection: {
      async get() {
        return await shared.fetchJson(`${this.$apiBase}/api/store/${this.collectionId}`);
      },
      default: {
        address: "0x0000000000000000000000000000000000000000",
        name: "",
        symbol: "",
        creator: "0x0000000000000000000000000000000000000000",
      },
    },
    creatorName: {
      async get() {
        if (this.collection) {
          if (this.collection.creator == "0x0000000000000000000000000000000000000000") return null;

          try {
            const u = await this.fetchUser(this.collection.creator);
            this.verified = u.verified;
            return u ? u.username : null;
          } catch (error) {
            console.error("Error fetching creator for collection " + this.collectionId, error);
            return null;
          }
        }
      },
      default: "Loading...",
    },
    creatorAddress() {
      if (this.collection) {
        return this.collection.creator;
      } else {
        return "0x0000000000000000000000000000000000000000";
      }
    },
    owners: {
      async get() {
        let owners = {};
        let userFetchPromises = [];

        for (let i = 0; i < this.nftSet.nfts.length; i++) {
          owners[this.nftSet.nfts[i].owner] = {};

          // Kick off async task to populate username from user.
          // this does not block the for loop (running in parallel)
          const promise = (async (addr) => {
            const user = await this.fetchUser(addr);
            owners[addr] = user;
          })(this.nftSet.nfts[i].owner);

          userFetchPromises.push(promise);
        }

        // wait for all username responses to return before continuing
        await Promise.all(userFetchPromises);

        if (owners[this.burned]) delete owners[this.burned];
        return owners;
      },
      default: {},
    },
    activities: {
      async get() {
        /*
          for each nft, get its activities
          then combine these into a single entity
          deduplicate Transfer events
        */

        if (this.nftSet.nfts.length == 0) {
          // not loaded yet
          return {};
        }

        let activitiesByMetaId = {};
        let activityFetchPromises = [];
        for (let i = 0; i < this.nftSet.nfts.length; i++) {
          // Kick off async task to fetch activities per NFT
          const promise = (async (index, nftId) => {
            const activity = await this.fetchActivity(nftId);
            activitiesByMetaId[index] = activity;
          })(this.nftSet.nfts[i].number, this.nftSet.nfts[i].id);

          activityFetchPromises.push(promise);
        }

        // wait for all activity responses to return before continuing
        await Promise.all(activityFetchPromises);

        // At this point, the data looks like:
        /*
          {
            id 2: [
              { activity: pricechange, block: #, ... },
              { activity: transfer, block: #, ... }
            ],
            id 4: [
              { activity: burn, metaId: 4, ... }
            ]
          }
        */

        // We want it to look like this- grouped by block #
        /*
          {
            block#: [
              { activity: pricechange, metaId: 2, ... },
              { activity: transfer, metaId: 2, ... }
            ],
            block# [
              { activity: burn, metaId: 4, ... }
            ]
          }
        */

        let rv = {}; // activities per block

        for (let i = 0; i < this.nftSet.nfts.length; i++) {
          const metaIdActivities = activitiesByMetaId[i + 1];
          for (let j = 0; j < metaIdActivities.length; j++) {
            let currentActivity = metaIdActivities[j];
            currentActivity.metaId = this.nftSet.nfts[i].number;

            if (!rv[currentActivity.block]) {
              rv[currentActivity.block] = [];
            }
            rv[currentActivity.block].push(currentActivity);
          }
        }

        // Look through all blocks... if multiple Transfers in the same block, deduplicate them.
        // This approach is a bit crude. ideally we should ensure they have the same destination
        for (const blockNum in rv) {
          //console.log(JSON.stringify(rv[blockNum],null,2));

          // deduplicate transfers
          const transfers = rv[blockNum].filter((x) => x.name == "TransferSingle");
          const transferEventIds = transfers.map((x) => x.id);
          //console.log("transferEventIds: " + JSON.stringify(transferEventIds));

          // also, if both Transfer and Buy in the same block, drop the transfer, as it's superfluous
          const buys = rv[blockNum].filter((x) => x.name == "BuySingleNft" || x.name == "TokenBuySingleNft");

          if (transferEventIds.length > 1 && buys.length == 0) {
            transferEventIds.pop(); // keep the first price change event
            rv[blockNum] = rv[blockNum].filter((x) => !transferEventIds.includes(x.id)); // drop extra transfers
          } else if (transferEventIds.length > 0 && buys.length > 0) {
            rv[blockNum] = rv[blockNum].filter((x) => !transferEventIds.includes(x.id)); // drop ALL transfers
          }

          const mint = rv[blockNum].filter((x) => x.activity == "Mint");
          if (mint.length == 1) {
            rv[blockNum] = rv[blockNum].filter((x) => x.activity != "Mint"); // just rm it
            rv[blockNum].push(mint[0]);
          }
        }

        return rv;
      },
    },
    favorites: {
      async get() {
        this.reloadCounter; // increment reloadCounter to force re-compute

        if (this.collectionId && this.metaId) {
          const response = await shared.fetchJson(`${this.$apiBase}/api/store/${this.collectionId}/meta/${this.metaId}/favorites`);

          return response.favorites;
        }
        return null;
      },
      default: null,
    },
    favoritesCount: {
      async get() {
        if (this.favorites) {
          return this.favorites.length || "0";
        }
        return "";
      },
      default: "",
    },
    favoriteStatus: {
      // true if user has favorited this meta NFT
      async get() {
        if (this.favorites) {
          return !!this.favorites.find((x) => x.username == this.user.username);
        }
        return false;
      },
      default: false,
    },
    commentsCount: {
      async get() {
        this.reloadCounter; // increment reloadCounter to force re-compute

        if (this.collectionId && this.metaId) {
          const response = await shared.fetchJson(`${this.$apiBase}/api/store/${this.collectionId}/meta/${this.metaId}/comments/count`);

          return response.count || "0";
        }
        return "";
      },
      default: "",
    },
  },
  methods: {
    async setFavorite() {
      if (!this.metaId) {
        return; // not ready yet
      }

      if (!this.userAuthenticated) {
        return; // user not logged in
      }

      if (!this.favoriteStatus) {
        // user has not yet favorited this NFT - add fav
        try {
          await shared.uploadForm(
            `${this.$apiBase}/api/store/${this.collectionId}/meta/${this.metaId}/favorite`,
            null,
            "PUT",
            null,
            this.user.id,
            this.apiToken,
            this.$root.web3.utils,
            false
          );
        } catch (error) {
          throw Error(`setFavorite(); received error while setting fav: ${error.message}`);
        }
      } else {
        // user has already favorited this NFT - remove fav
        try {
          await shared.uploadForm(
            `${this.$apiBase}/api/store/${this.collectionId}/meta/${this.metaId}/favorite`,
            null,
            "DELETE",
            null,
            this.user.id,
            this.apiToken,
            this.$root.web3.utils,
            false
          );
        } catch (error) {
          throw Error(`setFavorite(); received error while removing fav: ${error.message}`);
        }
      }

      // refresh favorite state
      this.reloadCounter += 1;
    },
    async postComment() {
      if (this.postingCommentMutex) {
        return; // already in progress
      }

      if (!this.metaId || this.commentField == "") {
        return; // not ready yet
      }

      try {
        this.postingCommentMutex = true;
        this.commentSubmitError = "";

        try {
          const formData = new FormData();
          formData.append("content", this.commentField);

          await shared.uploadForm(
            `${this.$apiBase}/api/store/${this.collectionId}/meta/${this.metaId}/comment`,
            formData,
            "POST",
            null,
            this.user.id,
            this.apiToken,
            this.$root.web3.utils,
            false
          );
        } catch (error) {
          this.commentSubmitError = "Error submitting comment. Try again or contact support.";
          throw Error(`setFavorite(); received error while posting comment: ${error.message}`);
        }

        // clear comment box
        this.commentField = "";

        // refresh comments
        this.comments = [];
        this.commentsPage = 1;

        // refresh comment count
        this.reloadCounter += 1;
      } finally {
        // re-enable button
        this.postingCommentMutex = false;
      }
    },
    moreComments() {
      this.commentsPage++;
      this.loadComments(this.metaId);
    },
    async loadComments(metaId) {
      const pageClause = `page=${this.commentsPage}`;
      const response = await shared.fetchJson(`${this.$apiBase}/api/store/${this.collectionId}/meta/${metaId}/comments?${pageClause}`);

      if (response.comments.length == 0) {
        // no more comments lol
        this.moreCommentsAvailable = false;
        if (this.commentsPage > 1) this.commentsPage--;
      } else if (response.comments.length > 1 && response.comments.length < 5) {
        this.moreCommentsAvailable = false;
        this.comments.push(...response.comments);
      } else {
        // length == 5
        // there's might be more comments available. let's take a peek.
        const pageClause2 = `page=${this.commentsPage + 1}`;
        const response2 = await shared.fetchJson(`${this.$apiBase}/api/store/${this.collectionId}/meta/${metaId}/comments?${pageClause2}`);
        if (response2.comments.length > 0) {
          this.moreCommentsAvailable = true;
        } else {
          this.moreCommentsAvailable = false;
        }

        this.comments.push(...response.comments);
      }
    },
    async deleteComment(commentId) {
      const confirmation = confirm("Are you sure you want to delete this comment?");
      if (!confirmation) return;

      console.log("Deleting comment " + commentId);

      try {
        await shared.uploadForm(
          `${this.$apiBase}/api/store/${this.collectionId}/meta/${this.metaId}/comment/${commentId}`,
          null,
          "DELETE",
          null,
          this.user.id,
          this.apiToken,
          this.$root.web3.utils,
          false
        );
      } catch (error) {
        throw Error(`deleteComment(); received error while removing comment: ${error.message}`);
      }

      // refresh comment count
      this.reloadCounter += 1;

      // remove comment from local state
      this.comments = this.comments.filter((c) => c.id != commentId);
    },
    async promoteComment(commentId) {
      try {
        await shared.uploadForm(
          `${this.$apiBase}/api/store/${this.collectionId}/meta/${this.metaId}/comment/${commentId}/promote`,
          null,
          "POST",
          null,
          this.user.id,
          this.apiToken,
          this.$root.web3.utils,
          false
        );
      } catch (error) {
        throw Error(`promoteComment(); received error while promoting comment: ${error.message}`);
      }

      // refresh comment count
      this.reloadCounter += 1;

      // TODO promote comment visually on page
    },
    roundTo2Decimals(value) {
      return (Math.round(value * 100) / 100).toFixed(2);
    },
    showImage(e) {
      this.lightbox = basicLightbox.create(`<img id="full-image" src="${e.target.src}"/>`);
      this.lightbox.show();
    },
    async fetchMetaIdFromNftId(nftId) {
      try {
        const resp = await shared.fetchJson(`${this.$apiBase}/api/store/${this.collectionId}/nft/${nftId}`);

        if (resp == null || !resp.metaId) {
          console.error(`Unable to find NFT for collection '${this.collectionId}' and nft ID '${nftId}'`);
          return null;
        } else {
          return resp.metaId;
        }
      } catch (error) {
        console.error(`Error fetching NFT for collection '${this.collectionId}' and nft ID '${nftId}'`, error);
        return null;
      }
    },
    async fetchUser(address) {
      if (!(address in this.userCache)) {
        const userFetched = await shared.fetchJson(`${this.$apiBase}/api/user/${address}`);
        this.userCache[address] = userFetched;
      }

      return this.userCache[address];
    },
    async fetchActivity(nftId) {
      return await shared.fetchJson(`${this.$apiBase}/api/store/${this.collectionId}/nft/${nftId}/activity`);
    },
    relativeTime(timestamp) {
      return shared.relativeTime(timestamp, true);
    },
    absoluteTime(timestamp) {
      return shared.absoluteTime(timestamp, true);
    },
    showModal(modal) {
      this.showMenu = false;
      this.modal = modal;
    },
    clearModal() {
      this.showMenu = false;
      this.modal = "";
    },
    transactionComplete() {
      setTimeout(() => {
        this.clearModal();
        this.reloadCounter += 1;
      }, 2000);
    },
    fetchImage(src) {
      let ret;
      if (src.startsWith("ipfs") || src.startsWith("Qm")) {
        ret = shared.formatIpfsLink(this.$ipfsPrefix, src);
      }

      return ret;
    },
    copyToClipboard(content) {
      navigator.clipboard.writeText(content);
    },
    async hideNft() {
      const confirmation = confirm("This will hide the NFT from the site. It can still be viewed by direct link, and will still remain on the blockchain.\n\nProceed?");
      if (!confirmation) return;

      console.log("Hiding NFT");

      try {
        await shared.uploadForm(
          `${this.$apiBase}/api/store/${this.collectionId}/meta/${this.metaId}/hide`,
          null,
          "PUT",
          null,
          this.user.id,
          this.apiToken,
          this.$root.web3.utils,
          false
        );
        alert("Successfully hidden NFT");
      } catch (error) {
        alert(`hideNft(); received error while hiding nft: ${error.message}`);
        throw Error(`hideNft(); received error while hiding nft: ${error.message}`);
      }
    },
    async unhideNft() {
      const confirmation = confirm("This will restore the NFT, allowing it to be viewed on the site.\n\nProceed?");
      if (!confirmation) return;

      console.log("Unhiding NFT");

      try {
        await shared.uploadForm(
          `${this.$apiBase}/api/store/${this.collectionId}/meta/${this.metaId}/hide`,
          null,
          "DELETE",
          null,
          this.user.id,
          this.apiToken,
          this.$root.web3.utils,
          false
        );
        alert("Successfully restored NFT");
      } catch (error) {
        alert(`unhideNft(); received error while restoring nft: ${error.message}`);
        throw Error(`unhideNft(); received error while restoring nft: ${error.message}`);
      }
    },
  },
};
</script>
